import React, { useRef, useEffect, useState, CSSProperties } from 'react'
import { Tabs, TabPane, Drawer, OlMap, OlMarker, OlInfoWindow, OlVector, OlMapTool } from 'components'
import { useIntl } from 'react-intl'
import { FormInstance } from 'antd/lib/form'
import { handleAlarm } from 'server/web/alarm'
import { webAPIResponse } from 'server/web/index.globals';
import classnames from 'classnames';
import useSetState from 'util/useSetState'
import TabBar from './tabBar'
import RealTimeTable from './realTimeTable';
import AlarmDataTable from './alarmDataTable';
import WaybillTable from './waybill'
import MediaFilesTable from './mediaFilesTable';
import PoliceDataTable from './policeDataTable';
import TipBtns from './tipBtns';
import FenceTree from './fenceTree'
import InforWindowContent from '../components/inforWindowContent'
import { monitorStore, tableStore, positionStory, userSettingStore } from 'store';
import { observer } from 'mobx-react';
import AlarmHandling from 'view/queryCenter/components/alarmHandling'
import { message, Modal } from 'antd'
import { useInterval } from 'react-use';
import PhotographModal from './modals/photographModal'
import RegionVehicleModal from './modals/regionVehicleModal';
import RollCall from './modals/rollCall'
import { FeatureProps } from 'components/ol/vector'


const tabBarStyle: CSSProperties = {
  height: '.4rem',
  margin: 0,
  paddingLeft: 16,
  color: '#222'
}

interface MyState {
  guideTop: number,
  tablesHeight: string,
  mapCursor: boolean
  btnSelectKeys: Array<number>
  visible: boolean
  hiddenIcon: number
  record: any
  alarmVisible: boolean
  PhotographModalVisible: boolean
  mapArea: webAPIResponse.MapAreaPage[]
}

const defaultTableHeight: number = 44

const MapTextStyle: CSSProperties = {
  padding: '2px 5px',
  borderRadius: '3px',
  border: '1px solid blue',
  background: 'rgba(255, 255, 255, .4)',
  boxShadow: '0 2px 6px 0 rgba(114, 124, 245, .5)',
  textAlign: 'center',
  fontSize: '13px',
  color: '#FB2424'
}

// TODO 临时用
interface PositionProps {
  /**
   * 是否外接页面 true 是；false 不是
   */
  isForeign?: true | false
}


function Content(props: PositionProps) {
  const { isForeign } = props

  const contentHTML: any = useRef()
  const guideHTML: any = useRef()
  const moveStatus: any = useRef()

  const alarmForm = useRef<FormInstance>()
  const { formatMessage: f } = useIntl()

  const [state, setState] = useSetState<MyState>({
    guideTop: 0,
    tablesHeight: defaultTableHeight + 'px',
    mapCursor: false,
    btnSelectKeys: [],
    visible: false,
    hiddenIcon: 1,
    record: {},
    alarmVisible: false,
    PhotographModalVisible: false,
    mapArea: []
  })

  const [regionalPoints, setRegionalPoints] = useState<string>('')

  useEffect(() => {
    if (!isForeign) {
      positionStory.queryAbnormalData()
      positionStory.queryDangerousData()

      positionStory.querySuperviseData()
      // 查询电子围栏缓存
      positionStory.queryFenceCache()
      setTimeout(() => { positionStory.openModal() }, 0)
    }
  }, [])

  useInterval(() => {
    positionStory.queryAbnormalData()
    // TODO 用户设置那里如果关掉了危险车辆提醒，只会让vehicleModal不渲染，但是在这里依然会继续查询危险车辆
    positionStory.queryDangerousData()
    positionStory.querySuperviseData()
  }, isForeign ? null : positionStory.realTimePollTime * 1000)

  useInterval(() => {
    positionStory.pollRealTimeTableData()
    if (!isForeign && process.env.REACT_APP_TYPE === 'concrete') {
      positionStory.pollWaybillData()
    }
  }, positionStory.realTimePollTime * 1000)

  useEffect(() => {
    if (positionStory.tableHeight) {
      setState({
        tablesHeight: '240px',
        hiddenIcon: 2
      })
    } else {
      setState({
        tablesHeight: defaultTableHeight + 'px',
        hiddenIcon: 1
      })
    }
  }, [positionStory.tableHeight])

  moveStatus.current = 0

  /**
   * 鼠标按下事件
   * @param e 
   */
  const onMouseDown = (e: any) => {
    const distY: number = e.clientY - guideHTML.current.offsetTop
    if (contentHTML.current && !moveStatus.current) {
      setState({ mapCursor: true })
      moveStatus.current = 1
      contentHTML.current.onmousemove = (e: any) => {
        onMouseMove(e, distY)
      }
      contentHTML.current.onmouseup = onMouseUp
    }
  }

  /**
   * 鼠标放开事件
   * @param e 
   */
  const onMouseUp = (e: any) => {
    const contentHeight = contentHTML.current.offsetHeight
    const clientY = e.clientY
    const surplusHeight = contentHTML.current.offsetTop
    let tablesHeight
    let hiddenIcon
    // 计算tbles的高度
    if (clientY <= surplusHeight) {
      tablesHeight = '100%'
      hiddenIcon = 3
    } else if ((clientY - surplusHeight + 40) >= contentHeight) {
      tablesHeight = defaultTableHeight + 'px'
      hiddenIcon = 1
    } else {
      const _height = 1 - ((clientY - surplusHeight - 64) / contentHeight)  // 相对于上一版代码，这里多减去的64px是header的高度。
      tablesHeight = contentHeight * _height < defaultTableHeight ? defaultTableHeight + 'px' : _height * 100 + '%'
      hiddenIcon = 2
    }

    setState({
      tablesHeight,
      guideTop: 0,
      mapCursor: false,
      hiddenIcon
    })

    // 移除鼠标事件
    if (contentHTML.current) {
      moveStatus.current = 0
      contentHTML.current.onmousemove = null
      contentHTML.current.onmouseup = null
    }
  }

  /**
   * 鼠标移动事件
   * @param e 
   * @param distY 鼠标位置
   */
  const onMouseMove = (e: any, distY: number) => {
    const contentHeight = contentHTML.current.offsetHeight
    const minY = contentHTML.current.offsetTop
    const maxY = contentHeight + minY - defaultTableHeight

    let top = e.clientY - distY

    if (e.clientY < minY || e.clientY > maxY) {
      return
    }

    setState({
      guideTop: top
    })

  }

  /** 最小化 */
  const onShrinkClick = () => {
    let tablesHeight = defaultTableHeight + 'px'
    setState({
      tablesHeight,
      hiddenIcon: 1
    })
  }

  /** 窗口化 */
  const onNarrowClick = () => {
    let tablesHeight = '200px'
    setState({
      tablesHeight,
      hiddenIcon: 2
    })
  }

  /** 最大化 */
  const onFullScreenClick = () => {
    setState({
      tablesHeight: '100%',
      hiddenIcon: 3
    })
  }

  const alarmOk = async () => {
    const formData = await alarmForm.current?.validateFields()
    const res = formData && (await handleAlarm(formData))

    if (res) {
      setState({ alarmVisible: false })
      message.success(f({ id: 'tx000024', description: '操作成功' }))
      tableStore.queryTable('AlarmDataTable')
    }
  }

  const handleIcon = (data: webAPIResponse.vehiclesInfo): string => {
    if (data.icon) {
      return data.icon
      // if (data.isAlarm) {
      //   return '/images/monitor/red.png'
      // }
      // if (data.isRun) {
      //   return '/images/monitor/green.png'
      // } else {
      //   return '/images/monitor/blue.png'
      // }
    }
    return '/images/monitor/gray.png'
  }

  const handleVehicleNo = (vehicle: webAPIResponse.vehiclesInfo) => {
    const type = userSettingStore.baseSetting.vehicleLabelType
    switch (type) {
      case '1': // 显示车牌+车队
        return vehicle.depName ? vehicle.plateNo + '-' + vehicle.depName : vehicle.plateNo;
      case '2': //显示车牌+simNo
        return vehicle.simNo ? vehicle.plateNo + '-' + vehicle.simNo : vehicle.plateNo;
      case '3': // 显示车牌+编号
        return vehicle.serialNo ? vehicle.plateNo + '-' + vehicle.serialNo : vehicle.plateNo
      default:
        return vehicle.plateNo;
    }
  }
  
  return (
    <div className='tx-position' ref={contentHTML}>
      <Drawer
        visible={state.alarmVisible}
        onClose={() => { setState({ alarmVisible: false }) }}
        onOk={alarmOk}
        width='100%'
        title={f({ id: 'tx020012', description: '报警处理' })}
      >
        <AlarmHandling record={state.record} formRef={alarmForm} />
      </Drawer>
      {/* 拍照 */}
      <Modal
        title={
          <span>
            {f({ id: 'tx070014', description: '视频' })}
            /
            {f({ id: 'tx020021', description: '拍照' })}
          </span>
        }
        width={700}
        visible={positionStory.photographModalVisible}
        onCancel={() => { positionStory.hidePhotographModal() }}
        footer={null}
        bodyStyle={{ padding: '0 10px' }}
        destroyOnClose={true}
      >
        <PhotographModal />
      </Modal>
      {/* 点名 */}
      <Modal
        title={positionStory.realTimeVehicleInfo ? positionStory.realTimeVehicleInfo.plateNo : ''}
        visible={positionStory.callModalVisible}
        onCancel={() => { positionStory.hideCallModal() }}
        footer={null}
        destroyOnClose={true}
      >
        <RollCall />
      </Modal>
      {/* 区域查车 */}
      <Modal
        title={f({ id: 'tx300003', description: '区域查车' })}
        visible={positionStory.regionalCarModalVisible}
        onCancel={() => { positionStory.hideRegionalCarModal() }}
        width={800}
        footer={null}
        destroyOnClose={true}
      >
        <RegionVehicleModal
          points={regionalPoints}
        />
      </Modal>

      <div className={classnames('tx-position-map', { 'tx-position-map-cursor': state.mapCursor })}  >
        <OlMap
          center={positionStory.mapCenter ?
            [positionStory.mapCenter?.lng, positionStory.mapCenter?.lat] : undefined
          }
          type={positionStory.mapType}
          updateSize={monitorStore.tabsKey}
        >
          {
            positionStory.vehicleSelectMarker ?
              <OlMarker
                key={positionStory.vehicleSelectMarker.vehicleId + '_select'}
                icon={{
                  src: handleIcon(positionStory.vehicleSelectMarker),
                  scale: 0.5,
                  size: [28, 28],
                }}
                label={{
                  content: (<div style={MapTextStyle}>{handleVehicleNo(positionStory.vehicleSelectMarker)}</div>),
                  offset: [-6, 5]
                }}
                position={[positionStory.vehicleSelectMarker.longitude, positionStory.vehicleSelectMarker.latitude]}
                rotation={positionStory.vehicleSelectMarker.direction}
                onClick={() => {
                  const { vehicleId } = positionStory.vehicleSelectMarker!
                  monitorStore.getVehiclesInfoAjax(vehicleId, '1')
                }}
              />
              : null
          }
          {
            positionStory.plateNoInfos.length ?
              positionStory.plateNoInfos.map((item: webAPIResponse.vehiclesInfo) => {
                return item.longitude ?
                  <OlMarker
                    zIndex={100}
                    key={item.vehicleId}
                    icon={{
                      src: handleIcon(item),
                      scale: 0.5,
                      size: [28, 28],
                    }}
                    label={{
                      content: (<div style={MapTextStyle}>{handleVehicleNo(item)}</div>),
                      offset: [-6, 5]
                    }}
                    position={[item.longitude, item.latitude]}
                    rotation={item.direction}
                    onClick={() => {
                      monitorStore.getVehiclesInfoAjax(item.vehicleId, '1')
                    }}
                  />
                  : null
              })
              : null
          }
          {/* 电子围栏 */}
          <OlVector
            features={positionStory.fenceTreeData.map((item: webAPIResponse.MapAreaPage): FeatureProps => {
              let bounds: Array<number[]> = item.points ? item.points.split(';').map(a => {
                return a.split(',').map(item => Number(item))
              }) : [[]]
              let center = [item.centerLng, item.centerLat]
              if (item.areaType === 'circle') {
                center = item.points && item.points.length < 40 ? item.points.split(',').map(item => Number(item)) : center
              }
              return {
                type: item.areaType,
                center: center,
                radius: item.radius ? Number(item.radius) : 0,
                bounds: bounds
              }
            })}
          />
          {
            positionStory.fenceTreeData ?
              positionStory.fenceTreeData.map((item: webAPIResponse.MapAreaPage) => {
                let center = [item.centerLng, item.centerLat]

                return <OlMarker
                  key={'fence_' + item.areaId}
                  offset={[2, -10]}
                  icon={{
                    size: [28, 42],
                  }}
                  label={{
                    content: (<div style={MapTextStyle}>{item.name}</div>)
                  }}
                  position={center}
                />
              })
              : null
          }

          <OlInfoWindow
            position={positionStory.realTimeVehicleInfo ?
              [positionStory.realTimeVehicleInfo.longitude, positionStory.realTimeVehicleInfo.latitude] : undefined}
            visible={positionStory.infoWindowVisible}
            offset={[0, -12]}
            autoMove={true}
          >
            <InforWindowContent
              showTool={!isForeign}
              info={positionStory.realTimeVehicleInfo}
              onClose={() => {
                positionStory.updateInfoWindowVisible(false)
              }}
            />
          </OlInfoWindow>

          <OlMapTool
            toolType={positionStory.mapTool}
            isClean={positionStory.mapTool === 'rectangle' ? true : false}
            onDrawend={(path) => {
              if (positionStory.mapTool === 'rectangle') {
                if (path) {
                  const maxPoint = path[3]
                  const minPoint = path[1]
                  // setRegionalPoints(minPoint + ';' + maxPoint)
                  setRegionalPoints(`${maxPoint[1]};${maxPoint[0]};${minPoint[1]};${minPoint[0]};`)
                  positionStory.updateMapTool('close')
                  positionStory.showRegionalCarModal()
                }
              } else if (positionStory.mapTool === 'Point') {
                positionStory.updateMapTool('close')
              }
            }}
          />
        </OlMap>

        {/* 右下角 */}
        {isForeign ? null : <TipBtns />}

        {/* <MapTip
          className='position-map-tip'
          onChange={ (name: string, position: Array<number>) => {
            console.log(name, position);
          } }
        /> */}
        {
          isForeign ? null : <FenceTree />
        }

      </div>
      
      <div className='tx-position-tables' style={{ height: state.tablesHeight, display: !isForeign ? 'block' : 'none' }}>
        <div className='tx-position-guide' ref={guideHTML} onMouseDown={onMouseDown} style={{ top: state.guideTop }}></div>
        <div className='tables-content'>
          <Tabs
            tabBarStyle={tabBarStyle}
            defaultActiveKey='1'
            tabBarGutter={16}
            tabBarExtraContent={<TabBar
              hiddenIcon={state.hiddenIcon}
              onNarrowClick={onNarrowClick}
              onShrinkClick={onShrinkClick}
              onFullScreenClick={onFullScreenClick}
            />}
          >
            <TabPane key='1' tab={f({ id: 'tx000116', description: '实时数据' })}>
              <RealTimeTable />
            </TabPane>

            {
              process.env.REACT_APP_TYPE === 'concrete' ?
                <TabPane key='5' tab={f({ id: 'tx000159', description: '任务运单数据' })}>
                  <WaybillTable />
                </TabPane>
                : null
            }

            <TabPane key='2' tab={f({ id: 'tx020025', description: '报警数据' })}>
              <AlarmDataTable
                handleClick={(record: any) => {
                  setState({
                    record,
                    alarmVisible: true
                  })
                }}
              />
            </TabPane>
            <TabPane key='3' tab={f({ id: 'tx020026', description: '媒体文件' })}>
              <MediaFilesTable />
            </TabPane>
            {
              process.env.REACT_APP_TYPE !== 'concrete' ?
                <TabPane key='4' tab={f({ id: 'tx040004', description: '报警督办' })}>
                  <PoliceDataTable />
                </TabPane>
                : null
            }

          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default observer(Content)