import React, { useState } from 'react'
import Chunk from './components/chunk'
import { Echarts } from 'components'
import { findRealTimePositionBy } from 'server/web/screen'
import { useInterval } from 'react-use';
import { useIntl } from 'react-intl';

interface seriesData {
  name: string
  value: Array<number>
  path?: [number, number]
  plateNo?: string
}

const defaultColors: string[] = ['#00FF00', '#30CD34', '#FAFF0A', '#FF4104', '#E70304']

function Map() {
  const [series, setSeries] = useState<Array<seriesData>>([])

  const { formatMessage: f } = useIntl()

  useInterval(() => {
    queryData()
  }, 10000)

  const queryData = async () => {
    const result = await findRealTimePositionBy()
    if (result) {
      const data = result.data.map((item): seriesData => {
        return {
          name: '',
          path: [item.longitude, item.latitude],
          value: [item.longitude, item.latitude, item.score],
          plateNo: item.plateNo
        }
      })

      setSeries(data)
    }
  }

  return (
    <div className='tx-large-map'>
      <Chunk>
        <div className='large-map-content'>
          <Echarts
            onCreate={ queryData }
            bmap={ {
              roam: true,
              isViewport: true,
              viewport: series.map(item => item.path!)
            } }
            tooltip={ {
              trigger: 'item',
              formatter: function (params: any) {
                const data = params.data
                return data.plateNo + '<br>' + data.value[0] + ' , ' + data.value[1]
              }
            } }
            series={ [{
              type: 'scatter',
              coordinateSystem: 'bmap',
              data: series,
              encode: {
                value: 2
              },
              symbolSize: 12,
              showEffectOn: 'emphasis',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              label: {
                formatter: '{b}',
                position: 'right',
                show: true
              },
              itemStyle: {
                color: function (data: any) {
                  const value = data.value[2]
                  let index = 0
                  if (value >= 0 && value < 60) {
                    index = 4
                  } else if (value >= 60 && value < 70) {
                    index = 3
                  } else if (value >= 70 && value < 80) {
                    index = 2
                  } else if (value >= 80 && value < 90) {
                    index = 1
                  } else if (value >= 90) {
                    index = 0
                  }
                  return defaultColors[index]
                }
              }
            }] }
          />
          <div className='proportion'>
            <div className='proportion-item'>{ f({ id: 'tx000173', description: '超高危' }) }</div>
            <div className='proportion-item'>{ f({ id: 'tx000174', description: '高危' }) }</div>
            <div className='proportion-item'>{ f({ id: 'tx000175', description: '危险' }) }</div>
            <div className='proportion-item'>{ f({ id: 'tx000176', description: '较危' }) }</div>
            <div className='proportion-item'>{ f({ id: 'tx000177', description: '一般' }) }</div>
          </div>
        </div>
      </Chunk>
    </div>
  )
}

export default Map
