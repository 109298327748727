import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames';
import { intlStore } from 'store'
import VehicleTotal from './vehicleTotal'
import AlarmTotal from './alarmTotal'
import Deducted from './deducted';
import Map from './map';
import Video from './video';
import AlarmDistribution from './alarmDistribution';
import AlarmVehicle from './alarmVehicle';
import RealTime from './realTime';
import { useIntl } from 'react-intl';
import useSetState from 'util/useSetState'
import { useInterval } from 'react-use';
import { videoItem } from 'components/video/interface';
import { findVehicleScreenStatisBy, findVideoLinkChannelBy, queryAlarmVideo } from 'server/web/screen'
import { getAdvertisement } from 'server/web/systemfile'
import { webAPIResponse } from 'server/web/index.globals';

interface MyState extends Partial<webAPIResponse.findVehicleScreenStatisBy> {
  time: number
  /** 播放下标 */
  playVehicleId: number
  /** 播放轮询 */
  playRounds: number
  videoList: Array<videoItem>
}

/** 是否第一次请求播放 */
// let firstPlay: boolean = false

function Tx() {

  const baseUrl: string = process.env.REACT_APP_BASEURL!

  const [state, setState] = useSetState<MyState>({
    time: 0,
    playVehicleId: 0,
    playRounds: 1,
    videoList: []
  })
  const { formatMessage: f } = useIntl()
  /** 广告视频 */
  const [adList, setAdList] = useState<Array<videoItem>>([])

  /** 视频 */
  const [videoList, setVideoList] = useState<Array<videoItem>>([])
  /** 是否为第一批 */
  const [isFirst, setIsFirst] = useState(true)

  useInterval(() => {
    queryData()
  }, 10000)

  //  视频轮询
  // useInterval(() => {
  //   if (state.vehicleTops) {
  //     queryVehicleVideo(state.vehicleTops)
  //   }
  // }, state.time ? 60000 : null)

  useEffect(() => {
    queryData()
    initQueryData()
  }, [])

  // const queryVehicleVideo = useCallback(async (data: Array<{
  //   alarmNum: number
  //   plateNo: string
  //   vehicleId: string
  // }>) => {
  //   if (state.playRounds === 5) {
  //     setState({ time: 0 })
  //   } else {
  //     const vehicle = data![state.playVehicleId]
  //     if (!vehicle) {
  //       return;
  //     }
  //     setState({
  //       time: 0
  //     })
  //     const vResult = await findVideoLinkChannelBy({
  //       mediaType: '0',
  //       streamType: '0',
  //       vehicleId: vehicle.vehicleId
  //     })
  //     if (vResult && vResult.data && vResult.data.videoLinks) {
  //       setState({
  //         videoList: vResult.data.videoLinks.map(item => {
  //           return {
  //             passageway: item.passageway,
  //             url: item.url + '?t=' + (new Date()).valueOf(),
  //             sim: vResult.data.sim,
  //             vehicleNo: vResult.data.vehicleNo,
  //             sessionId: vResult.data.sessionId,
  //           }
  //         })
  //       })
  //     }

  //     if (state.playVehicleId >= data!.length) {
  //       setState({
  //         playRounds: state.playRounds + 1,
  //         playVehicleId: 0
  //       })
  //     }

  //     setState({
  //       time: 60000,
  //       playVehicleId: state.playVehicleId + 1
  //     })

  //   }
  // }, [state.playVehicleId, state.playRounds])

  const queryData = async () => {
    const result = await findVehicleScreenStatisBy()

    if (result && result.data) {
      setState({
        ...result.data
      })
      // if (result.data.vehicleTops && result.data.vehicleTops.length) {
      //   setState({
      //     time: 60000
      //   })

      //   if (!firstPlay) {
      //     queryVehicleVideo(result.data.vehicleTops)
      //     firstPlay = true
      //   }
      // }
    }
  }

  const initQueryData = async () => {
    let adVideoList: Array<videoItem> = []
    let alarmVideoList: Array<videoItem> = []
    setIsFirst(true)
    const result = await getAdvertisement()
    if (result) {
      adVideoList = result.data.map((item, index) => {
        return {
          passageway: 0,
          url: item.path,
          sim: 'ad_' + index,
          vehicleNo: '',
          sessionId: 'ad_session'
        }
      })
      setAdList(adVideoList)
    }

    const alarmResult = await queryAlarmVideo()
    if (alarmResult) {
      alarmVideoList = alarmResult.data.map((item, index) => {
        return {
          passageway: 0,
          url: baseUrl + '/api/webapi/filenamager/getFileByPath?path=' + item.filePath + '&t=' + new Date().getTime(),
          sim: 'alarm_' + index,
          vehicleNo: item.plateNo,
          sessionId: 'alarm_session'
        }
      })
    }

    handleVideoList(alarmVideoList, adVideoList)
  }


  /** 获取报警视频 */
  const queryAlarm = useCallback(async () => {
    setIsFirst(true)
    const result = await queryAlarmVideo()
    if (result) {
      const arr = result.data.map((item, index) => {
        return {
          passageway: 0,
          url: baseUrl + '/api/webapi/filenamager/getFileByPath?path=' + item.filePath + '&t=' + new Date().getTime(),
          sim: 'alarm_' + index,
          vehicleNo: item.plateNo,
          sessionId: 'alarm_session'
        }
      })

      handleVideoList(arr, adList)
    }
  }, [adList])

  /** 处理视频列表
   * @param index 第几批播放
  */
  const handleVideoList = (alarmList: Array<videoItem>, adList: Array<videoItem>) => {
    const MaxList: number = alarmList.length < 4 ? 4 : 8
    const ADMax: number = MaxList - alarmList.length

    const ADList = adList.slice(0, ADMax)
    const newVideoList = [...alarmList, ...ADList.map(item => ({ ...item, url: item.url + '?t=' + new Date().getTime() }))]

    setVideoList(newVideoList)
    setState({
      videoList: newVideoList.slice(0, 4)
    })
  }

  /** 全部视频播放完成 */
  const onAllEnded = useCallback(() => {
    if (videoList.length > 4 && isFirst) {
      setTimeout(() => {
        setState({
          videoList: videoList.splice(4, 8)
        })
      }, 1000)
      setIsFirst(false)
    } else {
      setTimeout(() => {
        queryAlarm()
      }, 1000)
    }
  }, [videoList, isFirst])

  return (
    <div className='tx-large'>
      <div>
        <div className='tx-large-left'>
          {/* 车辆总数 */ }
          <VehicleTotal
            vehicleNum={ state.vehicleNum }
            onLineVehicleNum={ state.onLineVehicleNum }
            dangerVehicleNum={ state.dangerVehicleNum }
            onLineVehicleNumRatio={ state.onLineVehicleNumRatio }
            dangerVehicleNumRatio={ state.dangerVehicleNumRatio }
          />
          {/* 报警总数 */ }
          <AlarmTotal alarmNum={ state.alarmNum } kilometersNum={ state.kilometersNum } vehicleAverageNum={ state.vehicleAverageNum } />
          {/* 车队TOP5 */ }
          <Deducted vehicleTeamTops={ state.vehicleTeamTops } vehicleTops={ state.vehicleTops } />
        </div>
        <div className='tx-large-right'>
          <div className='large-right-top'>
            <div className='large-right-top-title'>
              <div className='top-title-icon left-icon'></div>
              <div  className={ classnames('top-title-name', { 'top-title-name-en':  intlStore.lang==='en_US' }) }>{ f({ id: 'tx000121', description: '安全大数据' }) }</div>
              <div className='top-title-icon right-icon'></div>
            </div>
            <RealTime />
          </div>
          <div className='large-right-bottom'>
            <div className='large-right-bottom-left'>
              <Map />
              <Video
                className='tx-large-video'
                videoList={ state.videoList }
                onAllEnded={ onAllEnded }
                isTx={ true }
              />
            </div>
            {/* <div className='large-right-bottom-right'>
     
              <AlarmDistribution />
          
              <AlarmVehicle />
            </div> */}
          </div>
        </div>

      </div>
    </div>
  )
}

export default Tx

