import React, { CSSProperties } from 'react'
import { useIntl } from 'react-intl'
import '../index.less'

interface TitlePrtops {
  title?: string | number
  value?: string | number
  style?: CSSProperties
}

const Title = (props: TitlePrtops) => {
  const { formatMessage: f } = useIntl()

  return (
    <div className="tx-large-title" style={ props.style || {} }>
      { props.title && f({ id: props.title }) }
      { props.value }
    </div>
  )
}

export default Title
