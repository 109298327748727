import React, { FC } from 'react'
import { isEqual } from 'lodash-es'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

interface ContentTotalProps {
  title: string
  value: string | number
  icon: string
  className?: string
}

const areaEqual = (prevPrsop: ContentTotalProps, nextProps: ContentTotalProps) => {
  return isEqual(prevPrsop, nextProps)
}

const ContentTotal: FC<ContentTotalProps> = React.memo((props: ContentTotalProps) => {
  const { formatMessage: f } = useIntl()
  const { title, value } = props

  return (
    <div className={ classnames('large-content-total', props.className) }>
      <div className='icon'>
        <img src={ '/images/large_screen/' + props.icon + '.png' } alt='' />
      </div>
      <div className='content'>
        <div className='content-value'>
          { value }
        </div>
        <div className='content-title'>
          { f({ id: title }) }
        </div>
      </div>
    </div>
  )
}, areaEqual)

export default ContentTotal
