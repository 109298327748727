import React, { FC, useState, useEffect } from 'react'
import { Modal, InfoTabs } from 'components'
import { Button, Image } from 'antd'
import { InfoTabsProps } from 'components/infoTabs/interface'
import { ModalProps } from '../interface'
import { useIntl } from 'react-intl'
import { getVehicleRealTimeInfo } from 'server/web/vehicle'
import { getDriverRealTimeInfo } from 'server/web/driver'
import { getTerminalParameter, queryTerminalInfo, TerminalInfo } from 'server/web/sendCommand'
import classnames from 'classnames'
import { createDownLoadUrl } from 'util/comm'
import { useAxios, usePollingCommandStatus } from 'util/hook'
import { webAPIResponse } from 'server/web/index.globals'
import { useVehicleInfo, useDriverInfo, useTerminalInfo } from './hook'
import defaultFail from 'components/image/defaultFail'
import './index.less'

type VehicleInfoType = webAPIResponse.VehicleRealTimeInfo
type DriverRealTimeInfo = webAPIResponse.DriverRealTimeInfo

export interface Result {
  vehicleData?: VehicleInfoType
  driverData?: DriverRealTimeInfo
  terminalData?: TerminalInfo
}

const VehicleInfo: FC<ModalProps> = props => {
  const { record, className, onCancel, visible, ...extra } = props
  const { formatMessage: f } = useIntl()
  const [sendcommandLoading, setSendcommandLoading] = useState(false)
  const [statusName, setStatusName] = useState('')
  const [result, setResult] = useState<Result>({
    vehicleData: {},
    driverData: undefined,
    terminalData: {},
  })

  const [{ data: vehicleData, loading: vehicleLoading }] = useAxios({
    axios: getVehicleRealTimeInfo,
    payload: { vehicleId: record?.vehicleId },
  })

  const [{ data: driverData, loading: driverLoading }] = useAxios({
    axios: getDriverRealTimeInfo,
    payload: { vehicleId: record?.vehicleId },
  })

  const [, queryCommandId] = useAxios({
    axios: getTerminalParameter,
    payload: { vehicleId: record?.vehicleId },
    defaultStart: false,
  })

  const [{ data: commandStatus }, pollingCommandStatus] = usePollingCommandStatus()

  useEffect(() => {
    setStatusName(commandStatus?.statusName || '')
  }, [commandStatus])

  const [{ data: terminalData }, queryTerminalData] = useAxios({
    axios: queryTerminalInfo,
    defaultStart: false,
  })

  useEffect(() => {
    setResult({
      vehicleData,
      driverData,
      terminalData,
    })
  }, [vehicleData, terminalData, driverData])

  const onSend = async () => {
    setStatusName(f({ id: 'tx08_0', description: '等待服务器响应' }))
    setSendcommandLoading(true)
    try {
      const commandId = await queryCommandId()
      const { status } = await pollingCommandStatus(commandId)
      if (status === 'Success') {
        await queryTerminalData({ payload: { commandId } })
      }
    } finally {
      setSendcommandLoading(false)
    }
  }

  const vehicleInfo = useVehicleInfo()
  const driverInfo = useDriverInfo(!!driverData)
  const terminalInfo = useTerminalInfo()

  const config: InfoTabsProps['config'] = [
    {
      key: 'vehicleInfo',
      title: f({ id: 'tx010021', description: '车辆信息' }),
      infoItems: vehicleInfo,
      infoProps: {
        titleWidth: 80,
        row: 2,
      },
      afterInfo: (
        <div className="ant-modal-footer">
          <Button onClick={onCancel}>{f({ id: 'tx000053', description: '关闭' })}</Button>
        </div>
      ),
    },
    {
      key: 'driverInfo',
      title: f({ id: 'tx1900207', description: '驾驶员信息' }),
      infoItems: driverInfo,
      infoProps: {
        titleWidth: 80,
        row: 2,
      },
      beforeInfo: driverData ? (
        <div className="driver-photo">
          <Image
            width={100}
            src={createDownLoadUrl(driverData.driverPicUrl)}
            fallback={defaultFail}
          />
          <div>{f({ id: 'tx110023', description: '司机照片' })}</div>
        </div>
      ) : undefined,
      afterInfo: (
        <div className="ant-modal-footer">
          <Button onClick={onCancel}>{f({ id: 'tx000053', description: '关闭' })}</Button>
        </div>
      ),
    },
    {
      key: 'terminalInfo',
      title: f({ id: 'tx060038', description: '终端信息' }),
      infoItems: terminalInfo,
      infoProps: {
        titleWidth: 120,
        row: 2,
      },
      afterInfo: (
        <div className="ant-modal-footer">
          {statusName && <span style={{ marginRight: 8 }}>{statusName}</span>}
          <Button type="primary" onClick={onSend} loading={sendcommandLoading}>
            {f({ id: 'tx000050', description: '下发命令获取信息' })}
          </Button>
          <Button onClick={onCancel}>{f({ id: 'tx000053', description: '关闭' })}</Button>
        </div>
      ),
    },
  ]

  return (
    <Modal
      {...extra}
      visible={visible}
      className={classnames(className, 'tx-right-command-vehicle-info')}
      title={`${f({ id: 'tx010021', description: '车辆信息' })}${
        result.vehicleData?.plateNo ? ` - ${result.vehicleData.plateNo}` : ''
      }`}
      width={800}
      bodyStyle={{ padding: 0 }}
      footer={false}
      onCancel={onCancel}
    >
      <InfoTabs
        config={config}
        data={result}
        loading={vehicleLoading || driverLoading || sendcommandLoading}
      />
    </Modal>
  )
}

export default VehicleInfo
