import React, { useCallback, useEffect, useState } from 'react'
import Broadcast from 'components/videoRTC/broadcast'
import { bateComTimeAjax, closeComVideo, closeComAllVideo } from 'server/video'
import { observer } from 'mobx-react'
import { videoItem } from 'components/video/interface';
import { broadcastStory, monitorStore } from 'store'
import { cloneDeep, uniqBy } from 'lodash-es'
import { useIntl } from 'react-intl'
import { useInterval } from 'react-use';
import classnames from 'classnames'
import './index.less'

function Index(props: any) {

  const { formatMessage: f } = useIntl()

  const [time, setTime] = useState<number>(0)

  useInterval(() => {
    if (broadcastStory.vehicleInfo) {
      monitorStore.getVehiclesInfoAjax(broadcastStory.vehicleInfo.vehicleId, '3')
    }
  }, time ? 20000 : null)

  useEffect(() => {
    if (broadcastStory.vehicleInfo) {
      setTime(20000)
    } else {
      setTime(0)
    }
  }, [broadcastStory.vehicleInfo])

  /** 全部视频播放按钮事件 */
  const playAllVideo = (videoList: Array<videoItem | null>) => {
    const cloneVideo = cloneDeep(videoList)
    // 过滤sim卡号
    const filterSim: Array<videoItem> = uniqBy(cloneVideo, 'sim').filter(item => !!item) as Array<videoItem>
    /**
     * 增加判断，如果是单击树节点进来的，导致videoList可能全是null。
     * 如果全是null的话，就从树节点带来的数据去播放全部。
     */
    if (filterSim.length){
      filterSim.forEach(item => {
        broadcastStory.doubleClickGetVideo({
          passway: cloneVideo.filter(cv => cv && cv.sim === item.sim).map(cb => Number(cb!.passageway)),
          plateNo: item.vehicleNo,
          simNo: item.sim
        })
      })
    } else {
      broadcastStory.doubleClickGetVideo({
        passway: monitorStore.vehicleInfo.passway,
        plateNo: monitorStore.vehicleInfo.plateNo,
        simNo: monitorStore.vehicleInfo.simNo
      })
    }
  }

  /** 心跳监听 */
  const onBateTime = (sessionIds: string[]) => {
    bateComTimeAjax({ sessionIds })
  }

  /** 播放按钮点击事件 */
  const playClick = (obj: any) => {
    broadcastStory.doubleClickGetVideo({
      passway: [obj.passageway],
      plateNo: obj.vehicleNo,
      simNo: obj.simNo
    })
  }

  /** 单个视频点击销毁 */
  const destroyClick = useCallback((passageway: number, sessionId: string) => {
    closeComVideo({
      sessionId: sessionId,
      channelNums: [passageway],
      simNo: ''
    })
  }, [])

  /** 关闭所有视频 */
  const stopAllVideo = (videoList: Array<videoItem | null>) => {
    setTime(0)
    const noNullList = videoList.filter(item => !!item && item.url)
    if (noNullList.length) {
      const closeVideo: any = {}
      noNullList.forEach(item => {
        if (item?.sessionId! in closeVideo) {
          closeVideo[item?.sessionId!].push(item?.passageway)
        } else {
          closeVideo[item?.sessionId!] = [item?.passageway]
        }
      })
      closeComAllVideo(closeVideo)
      broadcastStory.cleanVideoList()
    }
  }

  return (
    <div className={ classnames('tx-broadcast-content', props.className) }>
      <Broadcast
        passageway={ broadcastStory.channelsNumber }
        videoList={ broadcastStory.videoList }
        playAllVideo={ playAllVideo }
        stopAllVideo={ stopAllVideo }
        autoCloseTime={ broadcastStory.autoCloseTime }
        onPassagewayChange={ (value) => {
          broadcastStory.updateChannelsNumber(value)
        } }
        onBateTime={ onBateTime }
        destroyClick={ destroyClick }
        playClick={ playClick }
        mediaType='flv'
        toolTitleIntls={ {
          channels: f({ id: 'tx070021', description: '通道' }),
          allStop: f({ id: 'tx070020', description: '全部停止' }),
          allPlay: f({ id: 'tx070019', description: '全部播放' }),
          fullscreen: f({ id: 'tx000035', description: '全屏' }),
          exitFullscreen: f({ id: 'tx000036', description: '退出全屏' }),
          resetAll: f({ id: 'tx000008', description: '重置' })
        } }
      />
    </div>
  )
}

export default observer(Index)
