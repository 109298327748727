import React from 'react'
import Chunk from './components/chunk'
import Title from './components/title';
import ContentTotal from './components/contentTotal'
import { useIntl } from 'react-intl'

interface AlarmTotalProps {
  alarmNum?: number
  kilometersNum?: number
  vehicleAverageNum?: number
}

function AlarmTotal(props: AlarmTotalProps) {

  const { formatMessage: f } = useIntl()

  return (
    <div className='tx-large-alarm-total'>
      <Chunk>
        <div className='large-alarm-total-content'>
          <div className='top-title'>
            <div className='top-title-name'>{ f({ id: 'tx020028', description: '车辆总数' }) } </div>
            <Title value={ props.alarmNum || 0 } />
          </div>
          <div className='arge-alarm-total-data'>
            <ContentTotal className='content-total-item' title='tx020031' value={ props.vehicleAverageNum || 0 } icon='icon3' />
            <ContentTotal className='content-total-item' title='tx020032' value={ props.kilometersNum || 0 } icon='icon4' />
          </div>
        </div>

      </Chunk>
    </div>
  )
}

export default AlarmTotal
