import React, { createContext, useReducer, Context } from 'react'
import Reducers from './reducer'
import Content from './content'
import './index.less'



export const PositionContext: Context<any> = createContext('provider')

// TODO 临时用
interface PositionProps {
  isForeign?: boolean
}

function Index(props: PositionProps) {

  const [uState, dispatch] = useReducer(Reducers, { tips: [] })

  return (
    <PositionContext.Provider value={ { uState, dispatch } }>
      <Content isForeign={ props.isForeign } />
    </PositionContext.Provider>
  )
}

export default Index
