import React, { useEffect, useState } from 'react'
import Trajectory from 'view/monitor/trajectory';
import { urlSearchParams, setLocal } from 'util/comm';
import { queryVehicleInfoByPlateNo } from 'server/web/vehicle';
import { monitorStore } from 'store';
import { useIntl } from 'react-intl';
import './index.less'
import { message } from 'antd';

interface PositionPageParams {
  token: string
  plateNo: string
  startTime: string
  endTime: string
  TerminalType: string
}

function TrajectoryPage() {

  const [TerminalType, setTerminalType] = useState('')
  const { formatMessage: f } = useIntl()
  useEffect(() => {
    const params = urlSearchParams<PositionPageParams>(decodeURIComponent((window).location.search))
    if (params.token) {
      setLocal('tx_token', params.token)
    }
    setTerminalType(params.TerminalType)
    queryVehicleDetailByPlateNo(params)
  }, [])

  const queryVehicleDetailByPlateNo = async (params: PositionPageParams) => {

    // 通过车牌号 获取车辆ID
    const result = await queryVehicleInfoByPlateNo({ plateNo: params.plateNo })
    if (result) {
      if (result.data.vehicleId) {
        const vehicleInfo = result.data
        monitorStore.updateVechileInfo({
          plateNo: vehicleInfo.plateNo!,
          simNo: vehicleInfo.simNo!,
          passway: [],
          videoChannelDesc: [],
          vehicleId: vehicleInfo.vehicleId,
          begTime: params.startTime,
          endTime: params.endTime,
        })
        // monitorTreeStore.checkVehicle([result.data.vehicleId], 2)
      } else {
        message.warning(f({ id: 'tx0830001', description: '车辆不存在' }))
      }
    }
  }

  return (
    <div className='trajectoryPage'>
      <Trajectory
        isForeign={ true }
        TerminalType={ TerminalType }
      />
    </div>
  )
}

export default TrajectoryPage
