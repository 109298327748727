import React, { useState } from 'react'
import { Table } from 'components'
import { ColumnsType } from 'components/table/interface'
import { positionStory } from 'store';
import { webAPIResponse } from 'server/web/index.globals';
import Lnglats from 'util/lnglats'

interface RealTimeTableProps { }

function RealTimeTable(_props: RealTimeTableProps) {
  const [tableName] = useState('RealTimeTable')
  const [columns] = useState<ColumnsType>([
    {
      intlTitle: 'tx010001',
      dataIndex: 'plateNo',
      description: '车牌号',
      width: 120
    },
    {
      intlTitle: 'tx010002',
      dataIndex: 'depName',
      description: '所属车组',
      width: 140
    },
    {
      intlTitle: 'tx060001',
      dataIndex: 'simNo',
      description: 'SIM卡号',
      width: 140
    },
    {
      intlTitle: 'tx010003',
      dataIndex: 'plateColor',
      description: '颜色',
      width: 100
    },
    {
      intlTitle: 'tx060002',
      dataIndex: 'onlineDesc',
      description: '在线',
      width: 100
    },
    {
      intlTitle: 'tx010059',
      dataIndex: 'turnStatusDesc',
      description: '在线',
      width: 100
    },
    {
      intlTitle: 'tx060004',
      dataIndex: 'sendTime',
      description: 'GPS定位时间',
      width: 160
    },
    {
      intlTitle: 'tx010007',
      dataIndex: 'longitude',
      description: '经度',
      width: 140
    },
    {
      intlTitle: 'tx010008',
      dataIndex: 'latitude',
      description: '纬度',
      width: 140
    },
    {
      intlTitle: 'tx060036',
      dataIndex: '_',
      description: '定位地址',
      width: 200,
      render: (_value, record: any) => {
        return <Lnglats lnglat={[Number(record?.longitude), Number(record?.latitude)]} />
      }
    },
    {
      intlTitle: 'tx010101',
      dataIndex: 'statusDesc',
      description: '状态',
      width: 140
    },
    {
      intlTitle: 'tx010004',
      dataIndex: 'velocity',
      description: '速度',
      width: 100
    },
    {
      intlTitle: 'tx060003',
      dataIndex: 'recordVelocity',
      description: '脉冲速度',
      width: 100
    },
    {
      intlTitle: 'tx010006',
      dataIndex: 'directionDesc',
      description: '方向',
      width: 120
    },
    {
      intlTitle: 'tx060006',
      dataIndex: 'todayMileage',
      description: '当日里程',
      width: 100
    },
    {
      intlTitle: 'tx060007',
      dataIndex: 'mileage',
      description: '累积里程',
      width: 100
    },
    {
      intlTitle: 'tx010005',
      dataIndex: 'gas',
      description: '油量',
      width: 100
    },
    {
      intlTitle: 'tx060008',
      dataIndex: 'valid',
      description: '定位',
      width: 90
    },
    {
      intlTitle: 'tx060009',
      dataIndex: 'altitude',
      description: '海拔',
      width: 100
    },
    {
      intlTitle: 'tx060010',
      dataIndex: 'signalStrength',
      description: '信号强度',
      width: 140
    },
   
  ])

  return <Table
    tableName={ tableName }
    columns={ columns }
    size="small"
    buttonGroup={ false }
    dataSource={ positionStory.plateNoInfos }
    pagination={ false }
    onRow={ (record: webAPIResponse.vehiclesInfo) => {
      return {
        onClick: event => {
          event.stopPropagation()
          positionStory.updateRealTimeVehicleInfo(record)
          positionStory.updateMapCenter(record)
        },
      };
    } }
  />
}

export default RealTimeTable
