/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-09-23 10:47:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-20 15:39:20
 */
import api from './index'
import axios from '../axios'

const MODULE = '/filenamager'

export interface UploadFileRes {
  contentType: string
  fileFormat: string
  fileName: string
  fileUrl: string
  id: string
  path: string
  size: number
  source: string
}

export interface DownloadFileByFtpPath {
  /** dir */
  path?: string
}

/** 下载文件 */
export const downloadFileByPath = (payload: string) =>
  axios.get<any>(`${api}${MODULE}/getFileByPath?path=${payload}`)

/** 文件上传 */
export const uploadFileUrl = `${api}${MODULE}/uploadFile`
export const uploadFile = (payload: any) => axios.post<UploadFileRes>(uploadFileUrl, payload)

/** 下载文件夹,来源：FTP服务器 */
export const uploadFileFtpUrl = `${api}${MODULE}/getFtpFileByPath`
// export const downloadFileByFtpPath = (params: DownloadFileByFtpPath) =>
//   axios.get(uploadFileFtpUrl, {
//     params,
//     headers: { 'Content-Disposition': 'attachment' },
//   })
export const downloadFileByFtpPath = (params: DownloadFileByFtpPath) =>
  axios.post(uploadFileFtpUrl, params)
