/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-12-28 19:04:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-28 19:12:05
 */
import api from './index'
import axios from '../axios'

const MODULE = '/jt809'

export interface SuperviseProps {
  /** 命令id */
  cmdId: number
  /** 查岗问题*/
  content: string
  /** 创建时间 */
  createDate: number
  /** 查岗对象Id */
  objId: string
  /** 车牌颜色名字 */
  plateColorName: string
  /** 车牌号 */
  plateNo: string
  /** 平台id*/
  platformId: string
  /** 查岗对象类型 */
  postQueryTypeDescr: string
  /** */
  sn: number
  /** 状态 */
  status: string
  /** 更新时间*/
  updateDate: string
  /** 是否回复 */
  flag: number
  /** 信息id */
  infoId: string
}

/** 查询809查岗信息*/
export const querySupervise = (data: any) => axios.post<SuperviseProps[]>(`${api}${MODULE}/sendCommand/query/supervise`, data)

/** 查岗应答*/
export const replySupervise = (data: any) => axios.post(`${api}${MODULE}/sendCommand/send/supervise/reply`, data)
