import React, { useEffect, useState, useRef } from 'react'
import TiemLineItems from './timeLineItems'
import { TiemLineItemProps } from './tiemLineItem'
import classnames from 'classnames'
import { useMouse } from 'react-use'
import './index.less'

export interface TimeLineProps {
  data: TiemLineItemProps[] | undefined
  onClick?: (startTime: number, endTime: number, channelId: number) => void
}

function TineLine(props: TimeLineProps) {

  const defaultLeft: number = 80
  const lineContentRef: any = useRef()

  const [timeLines, setTimeLines] = useState<any>([])
  const [left, setLeft] = useState<number>(defaultLeft)
  const [top, setTop] = useState<number>(20)
  const [timeNumberLeft, setTimeNumberLeft] = useState<number>(0)
  const [time, setTime] = useState<string>('00:00')
  const { elX, elY } = useMouse(lineContentRef)

  useEffect(() => {

    if (props.data) {
      setTimeLines(props.data)
    }
  }, [props.data])

  const onMouseMove = () => {
    const lineContent = lineContentRef.current
    const offsetWidth = lineContent.offsetWidth
    const offsetHeight = lineContent.offsetHeight
    // 右侧边界值
    const rightBoundary = offsetWidth - 35
    const left = elX
    let top = elY - 13

    const minTop = 0
    const maxTop = offsetHeight - 66

    let time = 0

    if (left < defaultLeft) {
      setLeft(defaultLeft)
    } else if (left > rightBoundary) {
      setLeft(rightBoundary)
      time = rightBoundary
    } else {
      time = left - defaultLeft
      setLeft(left)
    }

    if (top < minTop) {
      setTop(minTop)
    } else if (top > maxTop) {
      setTop(maxTop)
    } else {
      setTop(top)
    }

    let timeText = time * (24 * 60 / (rightBoundary - defaultLeft))
    let maxLeft = offsetWidth - 164

    if (timeText >= maxLeft) {
      timeText = maxLeft
    }

    if (left >= (rightBoundary - 50)) {
      setTimeNumberLeft(90)
    } else {
      setTimeNumberLeft(0)
    }
    setTime(timeFormat(timeText))
  }


  /**
   * 把时间戳转换成时间
   * @param time 时间
   * @returns mm:ss 返回分秒
   */
  const timeFormat = (time: number | undefined): string => {
    let timeStr = '00:00'
    if (time && time >= 0) {
      if (time >= 60) {
        const m = Math.floor(time / 60)
        const s = time - (m * 60)
        const mStr = m > 9 ? m : '0' + m
        const sStr = s > 9 ? Math.ceil(s) : '0' + Math.ceil(s)
        timeStr = `${mStr}:${sStr}`
      } else {
        const sStr = time > 9 ? Math.ceil(time) : '0' + Math.ceil(time)
        timeStr = `00:${sStr}`
      }
    }
    return timeStr
  }

  const onClick = (startTime: number, endTime: number, channelId: number) => {
    if (props.onClick) {
      props.onClick(startTime, endTime, channelId)
    }
  }

  return (
    <div className='tx-pb-time-line'>
      <div className='time-line-content' ref={ lineContentRef } onMouseMove={ onMouseMove }>
        <div className='tx-pb-time-number' style={ { left: left + 10 - timeNumberLeft, top: top } }>
          <div className={ classnames({ 'time-number-triangle-left': !timeNumberLeft, 'time-number-triangle-right': !!timeNumberLeft }) }>
            { time }
          </div>
        </div>
        <div className='tx-pb-line' style={ { left: left } }></div>
        <div className='time-line-content-scroll'>
          <TiemLineItems data={ timeLines } onClick={ onClick } />
        </div>
        <div className='tx-pb-line-scale'>
          <div className='time-line-title'></div>
          <div className="time-houer-items time-line-margin">
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
            <div className="time-houer"></div>
          </div>
          <div className='time-line-after-fill'></div>
        </div>
      </div>
    </div>
  )
}

export default TineLine
