import { CSSProperties } from "react";

/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-10-27 14:49:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-12 09:26:26
 */
export const MapTextStyle: CSSProperties = {
  padding: '2px 5px',
  borderRadius: '3px',
  border: '1px solid blue',
  background: 'rgba(255, 255, 255, .4)',
  boxShadow: '0 2px 6px 0 rgba(114, 124, 245, .5)',
  textAlign: 'center',
  fontSize: '13px',
  color: '#FB2424'
}