import React, { useState } from 'react'
import { Table } from 'components'
import { ColumnsType } from 'components/table/interface'
import { positionStory } from 'store'
import { useObserver } from 'mobx-react'


function Waybill() {

  const [columns] = useState<ColumnsType>([
    {
      intlTitle: 'tx010023',
      dataIndex: 'serialNo',
      description: '车辆编号',
      width: 140
    },
    {
      intlTitle: 'tx010001',
      dataIndex: 'plateNo',
      description: '车牌号',
      width: 120
    },
    // {
    //   intlTitle: 'tx010003',
    //   dataIndex: 'plateColor',
    //   description: '颜色',
    //   width: 100
    // },
    {
      intlTitle: 'tx140101',
      dataIndex: 'transNo',
      description: '送货单号',
      width: 120
    },
    {
      intlTitle: 'tx140102',
      dataIndex: 'trainBatch',
      description: '本次方量',
      width: 140
    },
    {
      intlTitle: 'tx140103',
      dataIndex: 'deliveryStatusDesc',
      description: '送货状态',
      width: 160
    },
    {
      intlTitle: 'tx140104',
      dataIndex: 'endSiteAddress',
      description: '正供工地地址',
      width: 160
    }
  ])

  return useObserver(() => <Table
    tableName='taskWaybillTable'
    pagination={false}
    buttonGroup={false}
    columns={columns}
    size="small"
    dataSource={positionStory.waybillData}
  />)
}

export default Waybill
