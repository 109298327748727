import React, { useState, useRef, memo } from 'react'
import { Input } from 'antd'
import { Modal, Table, TreeSelect } from 'components'
import { FormInstance } from 'antd/lib/form'
import { useIntl } from 'react-intl'
import { ButtonGroupType, ColumnsType, TableAxiosType } from 'components/table/interface'
import { MyFormItemProps } from 'components/form'
import { ModalProps } from './interface'
import { getDriverPage } from 'server/web/driver'
import { getCompanyList } from 'server/web/company'
import { webAPIResponse } from 'server/web/index.globals'
import { arrayToTree, handleTreeData, pleaseEnter as e, pleaseSelect as s } from 'util/comm'
import 'index.less'

const DriverName = (props: ModalProps<webAPIResponse.DriverPage>) => {
  const tableName = 'modal.driverName'
  const { onOk } = props
  const { formatMessage: f } = useIntl()
  const form = useRef<FormInstance>()
  const [selectValue, setSelectValue] = useState<webAPIResponse.DriverPage[]>([])

  const [columns] = useState<ColumnsType<webAPIResponse.DriverPage>>([
    {
      dataIndex: 'driverName',
      intlTitle: 'tx110001',
      description: '司机姓名',
    },
    {
      dataIndex: 'identityCard',
      intlTitle: 'tx110005',
      description: '身份证号',
    },
    {
      dataIndex: 'telephone',
      intlTitle: 'tx050002',
      description: '联系电话',
    },
    {
      dataIndex: 'companyName',
      intlTitle: 'tx130001',
      description: '所属企业',
    },
  ])

  const [formItems] = useState<MyFormItemProps[]>([
    {
      name: 'driverNameLike',
      dom: <Input placeholder={e({ id: 'tx110001', description: '司机姓名' })} />,
    },
    {
      name: 'companyIds',
      dom: (
        <TreeSelect
          style={{ width: '2.2rem' }}
          showSearch
          treeCheckable
          virtual={false}
          placeholder={s({ id: 'tx130000', description: '企业' })}
          treeDefaultExpandAll
          query={getCompanyList}
          payload={{ companyName: '' }}
          handleResult={data =>
            handleTreeData(arrayToTree(data, 'id', 'parentId'), { title: 'name', key: 'id' })
          }
          showCheckedStrategy="SHOW_ALL"
          maxTagCount={0}
          maxTagPlaceholder={omittedValues =>
            f(
              { id: 'tx000213', description: '共选择xxx项企业' },
              { value: omittedValues.length, value2: f({ id: 'tx130000' }) },
            )
          }
        />
      ),
    },
  ])

  const [buttonGroup] = useState<ButtonGroupType>({
    showExport: false,
    showColumnSetting: false,
  })

  const [axios] = useState<TableAxiosType>({
    query: getDriverPage,
  })

  const onTableSelect = (_value: React.ReactText[], record: webAPIResponse.DriverPage[]) => {
    setSelectValue(record)
  }

  return (
    <Modal
      width={700}
      {...props}
      bodyStyle={{ height: '55vh' }}
      onOk={() => onOk && onOk(selectValue)}
      wrapClassName="tx-modal-wrap"
      getContainer={false}
      maskStyle={{ position: 'absolute' }}
    >
      <Table
        tableName={tableName}
        columns={columns}
        rowKey="driverId"
        axios={axios}
        form={form}
        initLoading={true}
        showSerialNumberCol={false}
        searchItems={formItems}
        buttonGroup={buttonGroup}
        deleteFormField={['companyIdEcho']}
        rowSelection={{
          onChange: onTableSelect,
        }}
      />
    </Modal>
  )
}

export default memo(DriverName)
