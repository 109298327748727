import React, { useState, useEffect } from 'react'
import { Slider, Progress } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames'
import { useIntl } from 'react-intl';

interface PlayToolProps {
  onPlay?: Function
  onPause?: Function
  onStop?: Function
  onSpeedChange?: (value: number) => void
  speed?: number
}

function PlayTool(props: PlayToolProps) {

  const { onPlay, onPause, onSpeedChange, onStop, speed } = props
  const { formatMessage: f } = useIntl()
  const [status, setStatus] = useState<number>(0)
  const [value, setValue] = useState<number>(0)

  useEffect(() => {
    if (!speed) {
      if (onStop) {
        onStop()
      }
      setValue(0)
      setStatus(0)
    }
  }, [speed])

  const play = () => {
    setStatus(1)
    if (onPlay) {
      onPlay()
    }
  }

  const pause = () => {
    setStatus(2)
    if (onPause) {
      onPause()
    }
  }

  const stop = () => {
    setStatus(3)
    setValue(0)
    if (onStop) {
      onStop()
    }
  }

  const onChange = (value: number) => {
    setValue(value)
    if (onSpeedChange) {
      onSpeedChange(value)
    }
  }

  return (
    <div className='trajectory-map-tool'>
      <div className={ classnames('trajectory-map-tool-play', { 'tool-active': status === 1 }) } onClick={ play }>
        <PlayCircleOutlined />
        { f({ id: 'tx070008', description: '播放' }) }
      </div>
      <div className={ classnames('trajectory-map-tool-pause', { 'tool-active': status === 2 }) } onClick={ pause }>
        <PauseCircleOutlined />
        { f({ id: 'tx070009', description: '暂停' }) }
      </div>
      <div className='trajectory-map-tool-stop' onClick={ stop }>
        <CloseCircleOutlined />
        { f({ id: 'tx070010', description: '停止' }) }
      </div>
      <div className='trajectory-map-tool-slider'>
        <Slider
          dots
          max={ 2 }
          onChange={ onChange }
          value={ value }
          tipFormatter={ (value: number) => (<span>{ value + 1 } x</span>) }
        />
      </div>
      <div className='trajectory-map-tool-progress'>
        <div style={ { width: '.28rem', marginRight: 4 } }>
          { f({ id: 'tx070011', description: '进度' }) }
        </div>
        <div style={ { alignItems: 'center', display: 'flex', flexGrow: 1 } }>
          <Progress size="small" status="active" percent={ speed } />
        </div>
      </div>
    </div>
  )
}

export default React.memo(PlayTool)
