import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Button, Form as AntdForm } from 'antd'
import { SelectCode, Upload, Form } from 'components'
import { MyFormItemProps } from 'components/form'
import { webAPIResponse, webAPIRequest } from 'server/web/index.globals'
import { sendTakePicture, getCommandStatusForPicture } from 'server/web/sendCommand'
import { pleaseSelect as s, createDownLoadUrl } from 'util/comm'
import isOrderAgain from 'util/isOrderAgain'

interface PhotographType {
  record?: any
  result?: webAPIResponse.AlarmInfo
}

const Photograph = (props: PhotographType) => {
  const { record, result } = props
  const [form] = AntdForm.useForm()
  const formRef = useRef(form)
  const { formatMessage: f } = useIntl()
  const [statusName, setStatusName] = useState('')
  const [loading, setLoading] = useState(false)
  const [clear, setClear] = useState<Function>()
  const [isAlive, setIsAlive] = useState({ value: true })
  const [fileUrl, setFileUrl] = useState<string>()
  const [fileType, setFileType] = useState()

  const FilmModeOption = {
    /** 拍照 */
    photograph: '1',
    /** 连拍 */
    continuousPhotograph: '2',
    /** 录像 */
    video: '3',
    /** 停止 */
    stop: '4',
  }

  useEffect(() => () => clear && clear(), [clear])

  useEffect(
    () => () =>
      setIsAlive(data => {
        data.value = false
        return data
      }),
    [],
  )

  const getStatus = (commandId: string) => {
    /** 是否清空定时器(命令执行成功或者页面关闭) */
    let flag = false
    /** 请求次数 */
    let number = 0
    /** 轮询规则，次数：时间 */
    const rules: { [key: number]: number } = {
      0: 1000,
      5: 2000,
      10: 3000,
    }
    let timer: NodeJS.Timeout | undefined = undefined
    let cTimer: NodeJS.Timeout | undefined = undefined

    const clearTimer = () => {
      setStatusName('')
      setLoading(false)
      flag = true
      timer && clearInterval(timer)
      cTimer && clearTimeout(cTimer)
      setClear(undefined)
    }

    const query = async () => {
      number++
      if (typeof rules[number] === 'number') {
        timer && clearInterval(timer)
        timer = setInterval(query, rules[number])
      }
      const res = await getCommandStatusForPicture({ commandId })
      if (res) {
        // 如果定时器已清空，则return，什么也不做
        if (flag) return
        const { status, statusName, fileName } = res.data
        setStatusName(statusName)
        if (!isOrderAgain(status)) {
          clearTimer()
          setStatusName(statusName)
          setFileUrl(fileName)
        }
      }
    }

    query()
    timer = setInterval(query, 1000)
    cTimer = setTimeout(() => {
      clearTimer()
      setStatusName(f({ id: 'tx08_1', description: '请求超时' }))
    }, 60000)

    return clearTimer
  }

  const send = async () => {
    const formData = await formRef.current.validateFields()
    setFileType(formData.action)
    setFileUrl(undefined)
    const payload = {
      alarmIds: result?.id || record?.id,
      vehicleId: result?.vehicleId || record?.vehicleId,
      ...formData,
    } as webAPIRequest.SendTakePicture
    setLoading(true)
    setStatusName(f({ id: 'tx08_0', description: '等待服务器响应' }))
    const res = await sendTakePicture(payload)
    if (res && isAlive.value) {
      const commandId = res.data
      const cl = getStatus(commandId)
      setClear(() => cl)
    } else {
      setLoading(false)
      setStatusName('')
    }
  }

  const formItems: MyFormItemProps[] = [
    {
      name: 'action',
      rules: [
        {
          required: true,
          message: s({ id: 'tx020023' }),
        },
      ],
      dom: <SelectCode placeholder={s({ id: 'tx020023' })} code="FilmMode" />,
      label: f({ id: 'tx020023', description: '拍摄方式' }),
    },
    {
      name: 'channel',
      rules: [
        {
          required: true,
          message: s({ id: 'tx020024' }),
        },
      ],
      dom: (
        <SelectCode
          placeholder={s({ id: 'tx020024' })}
          code="VideoChannel"
          filterWithKeys={result?.videoChannel}
        />
      ),
      label: f({ id: 'tx020024', description: '摄像头' }),
    },
  ]

  // useEffect(() => {
  //   if (fileUrl && fileType === FilmModeOption.video) {
  //     createDownLoadUrl(fileUrl, true)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fileType, fileUrl])

  return (
    <>
      <div className="title">{f({ id: 'tx020022', description: '拍照信息' })}</div>
      <div className="photographInfo">
        <Form ref={formRef} items={formItems} row={2} gutter={16} />
      </div>

      <Button size="small" type="primary" onClick={send} loading={loading}>
        {f({ id: 'tx020020', description: '发送命令' })}
      </Button>
      {statusName && <span style={{ marginLeft: '0.12rem' }}>{statusName}</span>}

      {fileUrl && (
        <div style={{ marginTop: 8 }}>
          {fileType === FilmModeOption.video ? (
            <video style={{ width: '100%' }} controls autoPlay src={createDownLoadUrl(fileUrl)} />
          ) : (
            <Upload value={fileUrl} isDetail />
          )}
        </div>
      )}
    </>
  )
}

export default Photograph
