/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-08-19 18:05:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-30 17:11:36
 */
import proxy from '../proxy';

const URL = proxy + '/webapi'

export const baseURL = proxy

export default URL