/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-12-09 10:32:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-10 14:38:08
 */
import { InfoItems } from 'components/info'

const useTerminalInfo = () => {
  const config: InfoItems[] = [
    {
      children: [
        {
          dataIndex: ['terminalData', 'terminalType'],
          intlTitle: 'tx060018',
          description: '终端类型',
        },
        {
          dataIndex: ['terminalData', 'manufactureId'],
          intlTitle: 'tx1908008',
          description: '制造商ID',
        },
        {
          dataIndex: ['terminalData', 'terminalModelNo'],
          intlTitle: 'tx060040',
          description: '终端型号',
        },
        {
          dataIndex: ['terminalData', 'terminalId'],
          intlTitle: 'tx060039',
          description: '终端ID',
        },
        {
          dataIndex: ['terminalData', 'simICCD'],
          title: 'ICCID',
          description: 'ICCID',
        },
        {
          dataIndex: ['terminalData', 'hardwareVersion'],
          intlTitle: 'tx1908009',
          description: '硬件版本号',
        },
        {
          dataIndex: ['terminalData', 'softwareVersion'],
          intlTitle: 'tx1908010',
          description: '固件版本',
        },
        {
          dataIndex: ['terminalData', 'gnssProperty'],
          intlTitle: 'tx060041',
          description: 'GNSS模块属性',
        },
        {
          dataIndex: ['terminalData', 'commProperty'],
          intlTitle: 'tx060042',
          description: '通信模块属性',
        },
      ],
    },
  ]

  return config
}

export default useTerminalInfo
