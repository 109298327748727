/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-12-23 15:44:41
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-08 18:27:29
 */
import api from './index'
import axios from '../axios'
import { TableResponseType } from 'components/table/interface';
import { VehicleId, PageProps } from './index.globals'

const MODULE = '/video'

export interface VideoRecording {
  /** baseId */
  baseId: string
  /** 通道号 */
  channelId: number
  /** 对于指令ID */
  commandId: string
  /** 结束时间 */
  createDate: string
  /** 音视频资源类型 */
  dataType: number
  /** 音视频资源类型-描述 */
  dataTypeName: string
  /** 结束时间 */
  endDate: string
  /** 文件大小 */
  fileLength: string
  /** 上传路径 */
  filePath: string
  /** 文件上传状态，为upload_completed时表示上传成功，upload_canceled时表示用户取消上传 */
  status: string | 'upload_completed' | 'upload_canceled'
  /** 开始时间 */
  startDate: string
  /** 存储器类型 */
  storeType: number
  /** 存储器类型-描述 */
  storeTypeName: string
  /** 码流类型 */
  streamType: number
  /** 码流类型-描述 */
  streamTypeName: string
}

/** 根据车辆id查询录像文件记录 */
export const findByVehicleId = (payload: VehicleId & PageProps) => axios.post<TableResponseType<VideoRecording>>(`${api}${MODULE}/file/item/vehicle`, payload)