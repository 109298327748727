import React, { memo, isValidElement } from 'react'
import { Form } from 'components'
import { MyFormItemProps } from 'components/form'
import { ColumnsType, ButtonGroupType, ExtraButtonGroupType, TableProps } from '../interface'
import ButtonGroup from './buttonGroup'
import ExtraButtonGroup from './extraButtonGroup'
import { Action } from '../interface'
import { isBoolean } from 'lodash-es'

interface TableHeaderProps<T> {
  searchItems?: MyFormItemProps[]
  buttonGroup?: ButtonGroupType | boolean
  extraButtonGroup?: ExtraButtonGroupType | boolean | JSX.Element
  searchRef: (node: any) => void
  formRef: any
  query: Function
  reset: Function
  exports: Function
  add: Function
  editBatch: Function
  imports?: Function
  downloadTemplate: Function
  queryLoading: boolean
  exportLoading: boolean
  columns?: ColumnsType
  columnSetting: any[]
  dispatch: React.Dispatch<Action>
  axios: TableProps<T>['axios']
  tableName: string
}

const TableHeader = <T extends {}>(props: TableHeaderProps<T>) => {
  const {
    searchItems,
    buttonGroup,
    extraButtonGroup,
    searchRef,
    formRef,
    query,
    reset,
    exports,
    add,
    editBatch,
    imports,
    downloadTemplate,
    queryLoading,
    exportLoading,
    columns,
    columnSetting,
    dispatch,
    axios,
    tableName,
  } = props

  const handleButtonValues = (buttonGroup?: ButtonGroupType | boolean): ButtonGroupType => {
    const obj = {
      showQuery: true,
      showReset: true,
      showExport: !!axios?.export,
      showColumnSetting: true,
    }
    if (isBoolean(buttonGroup)) {
      if (buttonGroup) {
        return obj
      }
      return {
        showQuery: false,
        showReset: false,
        showExport: false,
        showColumnSetting: false,
      }
    } else {
      if (buttonGroup) {
        return { ...obj, ...buttonGroup }
      }
      return obj
    }
  }

  const handleExtraButtonValues = (
    extraButtonGroup?: ExtraButtonGroupType | boolean | JSX.Element,
  ): ExtraButtonGroupType => {
    const obj = {
      showAdd: false,
      showEditBatch: false,
      showImport: !!(
        !isBoolean(extraButtonGroup) &&
        !isValidElement(extraButtonGroup) &&
        extraButtonGroup?.imports
      ),
      showDownloadTemplate: !!(
        !isBoolean(extraButtonGroup) &&
        !isValidElement(extraButtonGroup) &&
        extraButtonGroup?.templateUrl
      ),
    }
    if (!extraButtonGroup) return obj
    if (isBoolean(extraButtonGroup)) {
      return {
        showAdd: true,
        showEditBatch: true,
        showImport: true,
        showDownloadTemplate: true,
      }
    } else if (isValidElement(extraButtonGroup)) {
      return obj
    } else {
      return { ...obj, ...extraButtonGroup }
    }
  }

  const buttonValues = handleButtonValues(buttonGroup)
  const extraButtonValues = handleExtraButtonValues(extraButtonGroup)

  const showButton = !Object.values(buttonValues).every(item => item === false)
  const showExtraButton =
    (extraButtonGroup && isValidElement(extraButtonGroup)) ||
    !Object.values(extraButtonValues).every(item => item === false)

  return searchItems || showButton || showExtraButton ? (
    <div className="tx-table-header" ref={searchRef}>
      <div className="searchWrapper">
        {searchItems && <Form ref={formRef} items={searchItems} row="flex" layout="inline" />}
        {!showExtraButton && showButton && (
          <ButtonGroup
            buttonValues={buttonValues}
            query={query}
            reset={reset}
            exports={exports}
            queryLoading={queryLoading}
            exportLoading={exportLoading}
            columns={columns}
            columnSetting={columnSetting}
            dispatch={dispatch}
          />
        )}
      </div>
      {showExtraButton && (
        <div className="searchWrapper">
          {isValidElement(extraButtonGroup) ? (
            extraButtonGroup
          ) : (
            <ExtraButtonGroup
              extraButtonValues={extraButtonValues}
              add={add}
              editBatch={editBatch}
              imports={imports}
              downloadTemplate={downloadTemplate}
              tableName={tableName}
            />
          )}
          {showButton && (
            <ButtonGroup
              buttonValues={buttonValues}
              query={query}
              reset={reset}
              exports={exports}
              queryLoading={queryLoading}
              exportLoading={exportLoading}
              columns={columns}
              columnSetting={columnSetting}
              dispatch={dispatch}
            />
          )}
        </div>
      )}
    </div>
  ) : null
}

export default memo(TableHeader)
