/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-12-09 10:26:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-17 16:28:46
 */
import { InfoItems } from 'components/info'

const useVehicleInfo = () => {
  const config: InfoItems[] = [
    {
      children: [
        {
          dataIndex: ['vehicleData', 'plateNo'],
          intlTitle: 'tx010001',
          description: '车牌号',
        },
        {
          dataIndex: ['vehicleData', 'plateColorName'],
          intlTitle: 'tx010011',
          description: '车牌颜色',
        },
        {
          dataIndex: ['vehicleData', 'companyName'],
          intlTitle: 'tx130001',
          description: '所属企业',
        },
        {
          dataIndex: ['vehicleData', 'depName'],
          intlTitle: 'tx130002',
          description: '车队',
        },
        {
          dataIndex: ['vehicleData', 'serialNo'],
          intlTitle: 'tx010024',
          description: '自编号',
        },
        {
          dataIndex: ['vehicleData', 'simNo'],
          intlTitle: 'tx060019',
          description: '终端手机号',
        },
        {
          dataIndex: ['vehicleData', 'region'],
          intlTitle: 'tx010025',
          description: '车籍地',
        },
        {
          dataIndex: ['vehicleData', 'termId'],
          intlTitle: 'tx060039',
          description: '终端ID',
        },
        {
          dataIndex: ['vehicleData', 'runStatusName'],
          intlTitle: 'tx010061',
          description: '运行状态',
        },
        {
          dataIndex: ['vehicleData', 'vehicleTypeName'],
          intlTitle: 'tx010027',
          description: '车辆类型',
        },
        {
          dataIndex: ['vehicleData', 'industryName'],
          intlTitle: 'tx010026',
          description: '行业类型',
        },
      ],
    },
  ]

  return config
}

export default useVehicleInfo
