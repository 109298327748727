/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-10-15 10:01:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 17:11:53
 */
import {observable, action, toJS} from 'mobx'
import {getCompanyList} from 'server/web/company'
import {getVehicleTreeFlate, getCameraTreeFlate} from 'server/web/vehicleTree'
import {arrayToTree, getLangMessage} from 'util/comm'
import {orderBy, difference, cloneDeep} from 'lodash-es'
import positionStory from './position'
import {message} from 'antd'

export interface GroupTreeItems {
  icon: JSX.Element
  key: string
  id: string
  children?: GroupTreeItems[]
  name: string
}

class MonitorTree {
  /** 企业树 */
  @observable companyTreeData: Array<GroupTreeItems> = []
  /** 企业搜索 */
  @observable companyName: string = ''
  @observable companyCheckArr: Array<{ key: string, title: string }> = []
  /** 企业勾选keys */
  @observable companyChecks: Array<string> = []
  /** 企业展开keys */
  @observable companyExpandedKeys: Array<string> = []
  /** 企业loading */
  @observable companyLoading: boolean = false

  /** 全部车辆数数组，用以实时更新车辆数 */
  @observable vehicleTreeArr: Array<any> = []
  /** 全部车辆树 */
  @observable vehicleTreeData: Array<any> = []
  /** 车辆搜索 */
  @observable vehicleName: string = ''
  /** 摄像头搜索关键词 */
  @observable cameraName: string = ''
  /** 全部车辆数 */
  @observable vehicleCount: number = 0
  /** 在线车辆数 */
  @observable vehicleOnline: number = 0
  /** 车辆展开keys */
  @observable vehicleExpandedKeys: Array<string> = []
  /** 车辆勾选 */
  @observable vehicleChecks: Array<string> = []
  /** websocket keys */
  // @observable vehicleWS: string = ''
  /** 开启轮询时间 */
  @observable vehiclePollTime: number = 20
  /** 缓存企业ids，用来查询车辆树 */
  queryVehicleCompanyKeys: string = ''
  /** 默认轮询时间 */
  defaultPollTime: number = 20
  /** 最大勾选数 */
  VehicleMaxCheck: number = 100

  /** 全部摄像头树 */
  @observable cameraTreeData: Array<any> = []

  /** 查询企业数据 */
  @action
  async queryCompanyData() {
    this.cleanCompanyTreeData()
    this.updateCompanyLoading(true)
    const result = await getCompanyList({companyName: ''})
    this.updateCompanyLoading(false)
    if (result) {
      // 获取展开的keys
      this.updateCompanyExpand(result.data.filter((item) => item.parentId === '0').map((item) => item.id))
      // 数组转换为tree
      const groupTreeData: Array<GroupTreeItems> = arrayToTree(result.data, 'id', 'parentId')
      this.updateCompanyTreeData(groupTreeData)
    }
  }

  /** 搜索企业 */
  @action
  updateCompanyName(name: string) {
    this.companyName = name
  }

  /** 更新企业树 */
  @action
  updateCompanyTreeData(data: Array<GroupTreeItems>) {
    this.companyTreeData = data
  }

  /** 清除企业树 */
  @action
  cleanCompanyTreeData() {
    if (this.companyTreeData.length) {
      this.companyTreeData = []
    }
  }

  /** 更新企业loading */
  @action
  updateCompanyLoading(status: boolean) {
    this.companyLoading = status
  }

  /** 更新展开的树 */
  @action
  updateCompanyExpand(keys: Array<string>) {
    this.companyExpandedKeys = keys
  }

  /**单个添加展开树key */
  @action
  addCompanyExpand(key: string) {
    // 当前展开是否包含该节点key
    const isHas: boolean = this.companyExpandedKeys.some(item => item === key)
    if (!isHas) {
      this.companyExpandedKeys.push(key)
    }
  }

  /** 清空展开树 */
  @action
  cleanCompanyExpand() {
    this.companyExpandedKeys = []
  }

  /** 企业树勾选 */
  @action
  updateCompanyCheck(keys: Array<string>) {
    this.companyChecks = keys
  }

  /** 企业树勾选名 */
  @action
  updateCompanyCheckNames(data: any) {
    this.companyCheckArr = data
  }

  /** 轮询车辆树查询 */
  @action
  pollqueryVehicle() {
    if (this.queryVehicleCompanyKeys) {
      this.queryVehicleTree(this.queryVehicleCompanyKeys)
    }
  }

  /** 轮询摄像头树查询 */
  @action
  pollqueryCamera() {
    if (this.queryVehicleCompanyKeys) {
      this.queryCameraTree(this.queryVehicleCompanyKeys)
    }
  }

  /** 更新企业ids */
  @action
  updateVehicleCompanyKeys(value: string) {
    this.queryVehicleCompanyKeys = value
    // this.updatePollTime(value ? this.defaultPollTime : 0)
    this.queryVehicleTree(this.queryVehicleCompanyKeys)
    this.queryCameraTree(this.queryVehicleCompanyKeys)
  }

  /** 更新轮询时间 */
  @action
  updatePollTime(time: number) {
    this.vehiclePollTime = time
  }

  /**
   * 车辆数查询
   * 主要工作是查询整个车辆树的数据，并初步处理，给节点加上pId,key,title用于在树里展示。
   * 是整个treeStore用于车辆数时候的唯一数据来源
   * @param companyIds 账号的企业ID
   * */
  @action
  async queryVehicleTree(companyIds: string) {

    const result = await getVehicleTreeFlate({companyIds})
    // 处理树结构，统一pId
    if (result && result.data?.vehicleDtos && result.data.vehicleDtos?.length) {
      // 所有车辆
      const vehicleData = result.data.vehicleDtos.map(item => {
        const videoChannel = item.videoChannel ? item.videoChannel.split(',') : []
        const videoChannelDesc = item.videoChannel ? item.videoChannelDesc.split(',') : []
        return {
          ...item,
          key: item.vehicleId,
          pId: item.depId,
          passway: item.videoChannel ? videoChannel : '',
          videoChannelDesc: item.videoChannel ? videoChannelDesc : '',
          title: item.plateNo,
          children: videoChannel.map((value: string, index: number) => {
            return {
              key: item.vehicleId! + '_' + value,
              type: 99,
              passway: [value],
              simNo: item.simNo,
              plateNo: item.plateNo,
              vehicleId: item.vehicleId,
              title: videoChannelDesc[index],
              switcherIcon: index === videoChannel.length - 1 ? 'corner' : '',
              checkable: false
            }
          })
        }
      })


      // 企业节点
      const companyData = result.data.companyDtos.map(item => ({
        ...item,
        key: item.id,
        pId: '0',
        title: item.name,
        icon: ''
      }))

      // 车队节点
      const departmentData = result.data.departmentDtos.map(item => ({
        ...item,
        key: item.id,
        pId: item.companyId,
        title: item.depName,
        icon: ''
      }))

      // 更新全部车辆数
      this.updateVehicleCount(vehicleData.length)
      // 更新在线车辆数
      this.updateVehicleOnline(vehicleData.filter(item => item.online).length)

      // 处理车辆数据
      this.handleVehicleData([
        ...vehicleData,
        ...companyData,
        ...departmentData
      ])

    } else {
      this.init()
    }
  }

  /**
   * 摄像头树查询
   */
  @action
  async queryCameraTree(companyIds: string) {
    const result = await getCameraTreeFlate({companyIds})
    if (result && (result.data?.cameraDtos?.length || result.data?.companyDtos?.length)) {
      // 摄像头节点
      const cameraData = result.data.cameraDtos.map(i => {
        return {
          ...i,
          key: i.cameraId,
          pId: i.companyId,
          title: i.cameraName
        }
      })

      // 企业节点
      const companyData = result.data.companyDtos.map(item => ({
        ...item,
        key: item.id,
        pId: '0',
        title: item.name,
        icon: ''
      }))

      this.handleCameraData([
        ...cameraData,
        ...companyData
      ])
    } else {
      this.init()
    }
  }

  /** 初始化所有缓存数据 */
  @action
  init() {
    // 更新全部车辆数
    this.updateVehicleCount(0)
    // 更新在线车辆数
    this.updateVehicleOnline(0)
    // 处理车辆数据
    this.handleVehicleData([])
    // 处理车辆展开数据
    this.updateVehicleExpand([])
    // 处理车辆勾选数据
    this.vehicleChecks = []
    positionStory.subPlateNoInfos([])
  }

  /** 处理车辆数据，进行状态排序 */
  @action
  handleVehicleData(arr: Array<any>) {
    if (arr.length) {
      // 处理取消勾选车辆数据
      const newChecks = this.vehicleChecks.filter(item => arr.some(a => a.key === item))
      const diffChecks = difference(this.vehicleChecks, newChecks)
      this.vehicleChecks = newChecks
      if (diffChecks.length) {
        positionStory.subPlateNoInfos(diffChecks)
      }
      // 车辆状态排序
      this.vehicleTreeArr = orderBy(arr,
        ['online', 'isAlarm', 'isRun', 'plateNo'],
        ['desc', 'desc', 'desc', 'desc']
      )
      this.vehicleTreeData = arrayToTree(this.vehicleTreeArr)
    } else {
      this.vehicleTreeArr = []
      this.vehicleTreeData = []
    }

  }

  /** 处理摄像头数据 */
  @action
  handleCameraData(arr: Array<any>) {
    if (arr.length) {
      this.cameraTreeData = arrayToTree(arr)
    } else {
      this.cameraTreeData = []
    }
  }

  // /** 车辆状态变更 */
  // @action
  // updateVehicleStatus(info: webAPIResponse.vehiclesInfo) {
  //   const cloneVehicleArr = cloneDeep(this.vehicleTreeArr)
  //   // 找到当前车辆
  //   const vehicleIndex = cloneVehicleArr.findIndex(item => item.key === info.vehicleId)

  //   if (vehicleIndex !== -1) {
  //     let isUpdate = false
  //     const vehicleInfo = cloneVehicleArr[vehicleIndex]
  //     if (vehicleInfo.online !== info.online) {
  //       !!info.online ? this.addVehicleOnline() : this.subVehicleOnline()
  //     }
  //     if (vehicleInfo.online !== info.online || vehicleInfo.isRun !== info.isRun) {
  //       isUpdate = true
  //     }
  //     if (isUpdate) {
  //       cloneVehicleArr[vehicleIndex] = {
  //         ...cloneVehicleArr[vehicleIndex],
  //         online: info.online,
  //         isRun: info.isRun
  //       }
  //       this.handleVehicleData(cloneVehicleArr)
  //     }
  //   }
  // }

  /** 更新车辆数 */
  @action
  updateVehicleCount(value: number) {
    this.vehicleCount = value
  }

  /** 更新在线车辆数 */
  @action
  updateVehicleOnline(value: number) {
    this.vehicleOnline = value
  }

  // /** 增加在线车辆数 */
  // @action
  // addVehicleOnline() {
  //   this.vehicleOnline += 1
  // }

  // /** 减少在线车辆数 */
  // @action
  // subVehicleOnline() {
  //   this.vehicleOnline -= 1
  // }

  /** 更新展开的树 */
  @action
  updateVehicleExpand(keys: Array<string>) {
    this.vehicleExpandedKeys = keys
  }

  /**单个添加展开树key */
  @action
  addVehicleExpand(key: string) {
    // 当前展开是否包含该节点key
    const isHas: boolean = this.vehicleExpandedKeys.some(item => item === key)
    if (!isHas) {
      this.vehicleExpandedKeys.push(key)
    }
  }

  /** 清空展开树 */
  @action
  cleanVehicleExpand() {
    this.vehicleExpandedKeys = []
  }

  /** 搜索企业 */
  @action
  updateVehicleName(name: string) {
    this.vehicleName = name
  }

  /** 搜索摄像头 */
  @action
  updateCameraName(name: string) {
    this.cameraName = name
  }

  /** 勾选全部车辆 */
  @action
  checkAllVehicle(type: 1 | 2 | 3) {
    const vehicleTreeData = cloneDeep(this.vehicleTreeData)
    let keys: string[]
    if (type === 1) { // 全部车辆
      keys = this.getVehicleKey(vehicleTreeData)
    } else if (type === 2) { // 在线车辆
      keys = this.getVehicleKey(vehicleTreeData, 1)
    } else { // 离线车辆
      keys = this.getVehicleKey(vehicleTreeData, 2)
    }
    this.checkVehicle(keys, type)
  }

  /** 取消全部勾选 */
  @action
  cancelCheckVehicle(type: 1 | 2 | 3) {
    const vehicleData = cloneDeep(this.vehicleTreeArr.filter(item => {
      return item.type === 2
    }))
    let keys: Array<string> = []

    if (type === 1) { // 全部车辆
      keys = vehicleData.map(item => item.key)
    } else if (type === 2) { // 在线车辆
      keys = vehicleData.filter(item => !!item.online).map(item => item.key)
    } else { // 离线车辆
      keys = vehicleData.filter(item => !item.online).map(item => item.key)
    }
    // 从vehicleChecks中剔除车辆key
    const checkNum = this.vehicleChecks.filter(item => !keys.some(a => item === a))

    this.vehicleChecks = checkNum
    positionStory.subPlateNoInfos(keys)
    this.closeInfowindow(checkNum)
  }

  /** 勾选车辆
   * @param keys 当前勾选keys
   * @param type 1 全部 2 上线 3 离线
   */
  checkVehicle(keys: Array<string>, type: 1 | 2 | 3) {
    // 是否新增
    let isAdd
    // 获取与缓存不同的keys
    const differenceKeys = difference(keys, toJS(this.vehicleChecks))

    isAdd = !!differenceKeys.length
    if (isAdd) {
      // 如果不同勾选数加上缓存勾选数大于100
      if (differenceKeys.length + this.vehicleChecks.length > this.VehicleMaxCheck) {
        this.maxCheckTip()
        // 获取剩余勾选数
        let surplus = this.VehicleMaxCheck - this.vehicleChecks.length
        keys = [...this.vehicleChecks, ...differenceKeys.splice(0, surplus)]
        this.vehicleChecks = keys
      } else {
        this.vehicleChecks = [...this.vehicleChecks, ...differenceKeys]
      }
      // 获取勾选车辆详情列表
      positionStory.getRealTimeTableData(this.vehicleChecks.toString())
      // 获取勾选车辆运单数据
      positionStory.getWaybillData(this.vehicleChecks.toString())
    } else { // 取消勾选节点
      const vehicleData = cloneDeep(this.vehicleTreeArr.filter(item => {
        return item.type === 2
      }))
      let data: Array<string>

      if (type === 1) { // 全部车辆
        data = vehicleData.map(item => item.key)
      } else if (type === 2) { // 在线车辆
        data = vehicleData.filter(item => !!item.online).map(item => item.key)
      } else { // 离线车辆
        data = vehicleData.filter(item => !item.online).map(item => item.key)
      }

      if (keys.length) {
        // 获取没有勾选的节点
        const noChecks = difference(data, keys)

        // 过滤没有勾选的节点
        this.vehicleChecks = this.vehicleChecks.filter(item => {
          return !noChecks.some(a => a === item)
        })

        this.closeInfowindow(this.vehicleChecks)
        if (this.vehicleChecks.length) {
          positionStory.subPlateNoInfos(noChecks)
        } else {
          positionStory.subPlateNoInfos([])
        }
      } else {
        positionStory.subPlateNoInfos(data)
      }

    }
  }

  /**
   * 选择全部操作
   * @param type
   */
  @action
  isCheckAll(type: 1 | 2 | 3): boolean {
    let isCheckAll = false
    const vehicleData = cloneDeep(this.vehicleTreeArr.filter(item => {
      return item.type === 2
    }))
    if (this.vehicleChecks.length) {
      if (type === 1) { // 全部车辆
        let keys = vehicleData.map(item => item.key)
        if (keys.length === this.vehicleChecks.length) {
          isCheckAll = true
        }
      } else if (type === 2) { // 上线车辆
        let keys = vehicleData.filter(item => !!item.online).map(item => item.key)
        const checkNum = this.vehicleChecks.filter(item => keys.some(a => item === a))
        if (checkNum.length && checkNum.length === this.vehicleOnline) {
          isCheckAll = true
        }
      } else { // 离线车辆
        let keys = vehicleData.filter(item => !item.online).map(item => item.key)
        const checkNum = this.vehicleChecks.filter(item => keys.some(a => item === a))
        const num = this.vehicleCount - this.vehicleOnline
        if (checkNum.length && checkNum.length === num) {
          isCheckAll = true
        }
      }
    }
    return isCheckAll
  }

  /** 更新websocket keys */
  // @action
  // updateWSKeys(keys: string) {
  //   this.vehicleWS = keys
  // }

  /** 取消勾选的车辆中，是否有infowindow */
  closeInfowindow(keys: Array<string>) {
    const vId = positionStory.realTimeVehicleInfo ? positionStory.realTimeVehicleInfo.vehicleId : ''
    if (vId) {
      const hasInfowindow = keys.some(item => item === vId)
      if (!hasInfowindow) {
        positionStory.updateRealTimeVehicleInfo(null)
      }
    }
  }

  /** 最大勾选数提示 */
  maxCheckTip() {
    message.warning(getLangMessage({id: 'tx000210'}, {value: '100'}))
  }

  /** 递归获取车辆key
   * @param data
   * @param status 是否在线 0为全部 1为在线 2为离线
   */
  getVehicleKey(data: any[], status: 0 | 1 | 2 = 0): Array<string> {
    let keys: Array<string> = []
    data.forEach(item => {
      // 判断是否为车辆
      if (item.type === 2) {
        // 判断是否在线
        if (status === 0) {
          keys.push(item.key)
        } else if (status === 1 && item.online) {
          keys.push(item.key)
        } else if (status === 2 && !item.online) {
          keys.push(item.key)
        }
      } else if (item.children) {
        keys = [...keys, ...this.getVehicleKey(item.children, status)]
      }
    })
    return keys
  }

}

const monitorTreeStore: MonitorTree = new MonitorTree()

export {MonitorTree}
export default monitorTreeStore
