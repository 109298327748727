/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-08-03 17:35:39
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-09-15 11:36:50
 */
import { observable, action } from 'mobx';
import { webAPIResponse } from 'server/web/index.globals';
import { StatisticsType, ChartStatisticsType, ChartLoadings } from './interface';
import { format, dateObj, statistics, chartStatistics, chartLoadings } from './initData';
import moment from 'moment';

class HomeStore {
  @observable timeObj = dateObj
  @observable selectTime = dateObj.days
  @observable begTime = moment().subtract(1, 'days').format(format)
  @observable endTime = moment().subtract(1, 'days').format(format)

  /** 卡片统计数据 */
  @observable statistics: StatisticsType = statistics

  /** 图形统计数据 */
  @observable chartStatistics: ChartStatisticsType = chartStatistics

  @observable chartLoadings: ChartLoadings = chartLoadings

  @action
  updateTimeInterval = (value: number) => {
    this.selectTime = value
    this.begTime = moment().subtract(value, 'days').format(format)
  }

  @action
  updateStatistics = (value: any) => {
    const data = value === '-' || typeof value === 'number' ? { alarmNum: value } : value
    this.statistics = {
      ...this.statistics,
      ...data
    }
  }

  @action
  updateChartStatistics = (key: keyof ChartStatisticsType, value: any) => {
    let flag = false
    if (value) {
      flag = true
      // 如果返回值为空数据，则设置为初始数据
      if (key === 'alarmTrend' && (value as []).length === 0) {
        value = chartStatistics.alarmTrend
      }
      if (key === 'alarmMainStatis' && (value as []).length === 0) {
        value = chartStatistics.alarmMainStatis
      }
      if (key === 'vehicleTops' && (value as []).length === 0) {
        value = chartStatistics.vehicleTops
      }
      if (key === 'vehicleTeamTops' && (value as []).length === 0) {
        value = chartStatistics.vehicleTeamTops
      }
      if (key === 'alarmTypeStatis' && (value as webAPIResponse.AlarmTypeStatis).lists && (value as webAPIResponse.AlarmTypeStatis).lists.length === 0) {
        value = chartStatistics.alarmTypeStatis
      }
    }
    if (flag) {
      this.chartStatistics[key] = value
    }

  }

  @action
  updateLoading = (key: keyof ChartLoadings, value: boolean) => {
    this.chartLoadings[key] = value
  }
}

const homeStore = new HomeStore()

export { HomeStore, dateObj }
export default homeStore