/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-09-11 15:34:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-20 10:14:17
 */

import axios from './axios'
import { webAPIRequest, webAPIResponse } from './web/index.globals'
import proxy from './proxy';

const MODULE = proxy + '/video'

const MODULECOM = proxy + '/ztvideo'

export interface videoList {
  channelId: number,
  url: string
}

/** 历史视频资源列表指令下发 */
export const historicalVideo = (data: webAPIRequest.historicalVideo) => axios.post(`${MODULE}/videoPlayBack/getResourceListWeb`, data)

/** 历史视频资源列表指令下发 ==新的*/
export const historicalComVideo = (data: webAPIRequest.historicalVideo) => axios.post(`${MODULECOM}/videoPlayBack/getResourceListWeb`, data)

/** 根据指令id查询音视频资源列表 */
export const getResourceListById = (data: webAPIRequest.resourceListById) => axios.post<webAPIResponse.resourceListById[]>(`${MODULE}/videoPlayBack/getResourceListByIdWeb`, data)

/** 根据指令id查询音视频资源列表 ==新的 */
export const getResourceListComById = (data: webAPIRequest.resourceListById) => axios.post<webAPIResponse.resourceListById[]>(`${MODULECOM}/videoPlayBack/getResourceListByIdWeb`, data)

/** 获取远程回放url */
export const sendVideoPlayBackControlWeb = (data: webAPIRequest.sendVideoPlayBackCommandWeb) => axios.post<webAPIResponse.sendVideoPlayBackCommandWeb>(`${MODULE}/videoPlayBack/sendVideoPlayBackCommandWeb`, data)

/** 获取远程回放url==新的 */
export const sendVideoPlayBackControlWebN = (data: webAPIRequest.sendVideoPlayBackCommandWeb) => axios.post<webAPIResponse.sendVideoPlayBackCommandWeb>(`${MODULECOM}/videoPlayBack/sendVideoPlayBackCommandWeb`, data)

/** 回放心跳*/
export const videoPlayBackHeartBeat = (data:any) => axios.post<any>(`${MODULECOM}/videoPlayBack/heartBeat`, data)

/** 回放控制指令下发 */
export const sendVideoControl = (data: webAPIRequest.sendVideoControl) => axios.post(`${MODULE}/videoPlayBack/sendVideoPlayBackControlWeb`, data)

/** 回放控制指令下发 */
export const sendVideoComControl = (data: webAPIRequest.sendVideoControl) => axios.post(`${MODULECOM}/videoPlayBack/sendVideoPlayBackControlWeb`, data)

/** 获取视频--综合监控 */
export const getVideoAjax = (data: webAPIRequest.openVideo) => axios.post<webAPIResponse.openVideo>(`${MODULE}/broadcast/openVideo`, data)
/** 获取视频--综合监控==新的 */
export const getVideoAjaxN = (data: webAPIRequest.openVideo) => axios.post<webAPIResponse.openVideo>(`${MODULECOM}/broadcast/openVideo`, data)

/** 获取视频--视频监控 */
// export const getVideoAjax = (data: webAPIRequest.openVideo) => axios.post<webAPIResponse.openVideo>(`${MODULE}/broadcast/openVideo`, data)
export const getComVideoAjax = (data: webAPIRequest.openVideo) => axios.post<webAPIResponse.openVideo>(`${MODULECOM}/broadcast/openVideo`, data)


/** 心跳 */
export const bateTimeAjax = (data: webAPIRequest.bateTimeAjax) => axios.post<any>(`${MODULE}/broadcast/batch/heartBeat`, data)

/** 心跳--视频监控 */
export const bateComTimeAjax = (data: webAPIRequest.bateTimeAjax) => axios.post<any>(`${MODULECOM}/broadcast/batch/heartBeat`, data)

/** 关闭直播 */
export const closeVideo = (data: webAPIRequest.closeVideo) => axios.post<any>(`${MODULE}/broadcast/closeVideo`, data)
/** 关闭直播--视频监控  */
export const closeComVideo = (data: webAPIRequest.closeVideo) => axios.post<any>(`${MODULECOM}/broadcast/closeVideo`, data)

/** 关闭所以直播 */
export const closeAllVideo = (data: webAPIRequest.closeAllVideo[]) => axios.post<any>(`${MODULE}/broadcast/batch/closeVideo`, data)

/** 关闭所以直播--视频监控 */
export const closeComAllVideo = (data: webAPIRequest.closeAllVideo[]) => axios.post<any>(`${MODULECOM}/broadcast/batch/closeVideo`, data)
