import React, { FC, useState, useEffect, useRef } from "react";
import { Modal, Form, SelectCode } from "components";

import useInterval from "hooks/useInterval";
import Video from "components/videoRTC/video";
import { InputNumber, Form as AntdForm } from "antd";
import { ModalProps } from "../interface";
import { useIntl } from "react-intl";
import {
  talkbackSendCmd,
  talkbackHeartbeat,
  talkbackClose,
} from "server/web/sendCommand";
import { useAxios, useBasicCodes } from "util/hook";
import { MyFormItemProps } from "components/form";
import { handleLayout, pleaseEnter as e, pleaseSelect as s } from "util/comm";

const VoiceIntercom: FC<ModalProps> = (props) => {
  const { record, ...extra } = props;
  const { formatMessage: f } = useIntl();
  const [form] = AntdForm.useForm();
  const [sendLoading, setSendLoading] = useState(false);
  const sessionIdRef = useRef<string>("");

  const [interval, setInterval] = useState<number | undefined>(undefined);
  const [voiceUrl, setVoiceUrl] = useState<string>("");

  const channelIdRef = useRef<string>("");

  //0 初始化 1//请求中 //2通话中
  const [step, setStep] = useState<number>(0);

  const [{ data: basicCodes }] = useBasicCodes(["VideoChannel"]);

  const [, sendCommand] = useAxios({
    axios: talkbackSendCmd,
    defaultStart: false,
  });

  const clear = useInterval(() => {
    VoiceInterHeartbeat();
  }, interval);

  useEffect(() => {
    return () => {
      setSendLoading(false);
      setStep(0);
      setVoiceUrl("");
      clear();
      setInterval(undefined);
    };
  }, []);

  //心跳
  async function VoiceInterHeartbeat() {
    try {
      if (sessionIdRef.current) {
        const res: any = await talkbackHeartbeat({
          sessionId: sessionIdRef.current,
        });
      }
    } catch (error) {}
  }

  function generateSessionId() {
    sessionIdRef.current = Math.random().toString().substr(2, 21);
    return sessionIdRef.current;
  }

  const config: MyFormItemProps[] = [
    {
      name: "channelId",
      intlLable: "tx070005",
      description: "通道",
      rules: [{ required: true }],
      dom: (
        <SelectCode
          defaultEchoAfterQuery
          basicCodesOption={basicCodes?.VideoChannel}
          placeholder={s({ id: "tx070005" })}
        />
      ),
    },
  ];

  const handleOk = async () => {
    if (step == 2) {
      onDestroy();
      return;
    }

    const formData = await form.validateFields();
    setSendLoading(true);
    try {
      channelIdRef.current = formData?.channelId;
      const res: any = await sendCommand({
        payload: {
          ...{
            simNo: record!.simNo,
            sessionId: generateSessionId(),
          },
          ...formData,
        },
      });
      setVoiceUrl(res?.url ?? "");
      setStep(1);
      if (!res?.url) {
        setSendLoading(false);
      }
    } finally {
    }
  };
  function onStart() {
    setStep(2);
    setSendLoading(false);
    //开始定时心跳
    setInterval(1000);
  }

  //销毁停止定时
  function onDestroy() {
    stopVoiceInter();
    setSendLoading(false);
    setStep(0);
    setVoiceUrl("");
    clear();
    setInterval(undefined);
  }

  // 停止语音
  async function stopVoiceInter() {
    try {
      if (sessionIdRef.current) {
        const res: any = await talkbackClose({
          simNo: record!.simNo,
          sessionId: sessionIdRef.current,
          channelId: channelIdRef.current,
        });
      }
      sessionIdRef.current = "";
    } catch (error) {}
  }
  return (
    <Modal
      {...extra}
      title={f({ id: "tx000067", description: "语音对讲" })}
      width={700}
      okText={
        step === 0
          ? f({ id: "tx6011001", description: "开始对讲" })
          : step === 1
          ? f({ id: "tx6011002", description: "请求中" })
          : step === 2
          ? f({ id: "tx6011003", description: "结束对讲" })
          : ""
      }
      confirmLoading={sendLoading}
      onOk={handleOk}
    >
      <Form
        items={config}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      />

      <div style={{ height: "400px" }}>
        <Video
          url={voiceUrl}
          type="voiceIntercom"
          mediaType="flv"
          onlyPlayAudio={true}
          showTool={false}
          onStart={onStart}
          onDestroy={onDestroy}
        />
      </div>
    </Modal>
  );
};

export default VoiceIntercom;
