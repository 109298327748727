import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { Form as AntdForm, Input, Checkbox, Button, Select, InputNumber } from 'antd';
import { useIntl } from 'react-intl'
import { Form, Tabs, TabPane, SelectCode, Upload } from 'components';
import { MyFormItemProps } from 'components/form';
import { sendBubbleNow, sendTakePictureTimer, getCommandStatusForPicture, getTakePictureTimer, getBubbleTimer } from 'server/web/sendCommand'
import { useInterval } from 'react-use'
import { positionStory } from 'store';
import { observer } from 'mobx-react'
import { createDownLoadUrl, pleaseEnter as e, pleaseSelect as s } from 'util/comm';
import { MaxLength, MinLength } from 'util/validator';
import isOrderAgain from 'util/isOrderAgain'
import './index.less'

function PhotographModal() {

  const { formatMessage: f } = useIntl()

  const [onceForm] = AntdForm.useForm()
  const [secondForm] = AntdForm.useForm()
  const [buttonDisable, setButtonDisable] = useState(false)
  const [timeText, setTimeText] = useState(120)
  /** 是否显示高级设置 */
  const [advancedVisible, setAdvancedVisible] = useState(false)

  const [statusTimer, setStatusTimer] = useState(false)
  const [commandId, setCommandId] = useState('')
  const [statusName, setStatusName] = useState('')
  const [queryDataStatus, setQueryDataStatus] = useState('')
  const [fileUrl, setFileUrl] = useState('')

  const [paramsTimer, setParamsTimer] = useState(false)
  const [timerCommandId, setTimerCommandId] = useState('')
  const [paramsTimeText, setParamsTimeText] = useState(60)
  const [paramsButtonDisable, setParamsButtonDisable] = useState(false)

  const statusTime = useRef(0)
  const bubbleParamsTime = useRef(0)

  useInterval(() => {
    let now = timeText
    now -= 1
    setTimeText(now)
    if (!now) {
      setButtonDisable(false)
      setTimeText(120)
    }
  }, buttonDisable ? 1000 : null)

  useInterval(() => {
    let now = paramsTimeText
    now -= 1
    setParamsTimeText(now)
    if (!now) {
      setParamsButtonDisable(false)
      setParamsTimeText(60)
      setStatusName('')
    }
  }, paramsButtonDisable ? 1000 : null)

  useInterval(() => {
    statusTime.current += 1
    if (statusTime.current < 120) {
      queryCommandStatus()
    } else {
      setStatusName(f({ id: 'tx08_1', description: '请求超时' }))
      statusTime.current = 0
      setStatusTimer(false)
      setButtonDisable(false)
      setTimeText(120)
    }
  }, statusTimer ? 1000 : null)

  // 获取参数状态
  useInterval(() => {
    bubbleParamsTime.current += 1
    if (bubbleParamsTime.current < 60) {
      queryBubbleTimer()
    } else {
      bubbleParamsTime.current = 0
      setParamsButtonDisable(false)
      setParamsTimer(false)
      setParamsTimeText(60)
      setQueryDataStatus(f({ id: 'tx08_1', description: '请求超时' }))
    }
  }, paramsTimer ? 1000 : null)

  useEffect(() => {
    return () => {
      setStatusTimer(false)
      setParamsTimer(false)
    }
  }, [])

  const videoCheckBox = useMemo(() =>
    <Checkbox.Group
      options={ [
        {
          label: f({ id: 'tx070029', description: '打开定时拍照' }),
          value: '0'
        },
        {
          label: f({ id: 'tx070030', description: '上传照片' }),
          value: '1'
        },
      ] }
    />
    , [])

  const onceItems: MyFormItemProps[] = [
    {
      intlLable: 'tx020023',
      description: '拍摄方式',
      name: 'action',
      labelCol: { span: 8 },
      dom: <SelectCode queryTime='useEffect' placeholder={ s({ id: 'tx020023' }) } code="FilmMode" />
    },
    {
      intlLable: 'tx020024',
      description: '摄像头',
      name: 'channel',
      required: true,
      rules: [
        {
          required: true,
          message: s({ id: 'tx020024' }),
        },
      ],
      dom: <SelectCode
        queryTime="useEffect"
        placeholder={ s({ id: 'tx020024' }) }
        code="VideoChannel"
        filterWithKeys={ positionStory.realTimeVehicleInfo ? positionStory.realTimeVehicleInfo.videoChannel : '' }
      />
    },
    {
      intlLable: 'tx020050',
      description: '拍摄张数',
      required: true,
      labelCol: { span: 8 },
      rules: [
        {
          required: true,
          message: e({ id: 'tx020050' }),
        },
      ],
      name: 'photoNum',
      dom: <InputNumber style={ { width: '100%' } } />
    },
    {
      intlLable: 'tx020051',
      description: '拍摄间隔(秒)',
      required: true,
      rules: [
        {
          required: true,
          message: e({ id: 'tx020051' }),
        },
      ],
      name: 'interval',
      dom: <InputNumber style={ { width: '100%' } } />
    },
    {
      intlLable: 'tx020053',
      description: '图片尺寸',
      labelCol: { span: 8 },
      required: true,
      rules: [
        {
          required: true,
          message: s({ id: 'tx020053' }),
        },
      ],
      name: 'picSize',
      dom: <SelectCode
        queryTime='useEffect'
        placeholder={ s({ id: 'tx020053' }) }
        code="Resolution" />
    },
    {
      intlLable: 'tx020052',
      description: '保存方式',
      required: true,
      rules: [
        {
          required: true,
          message: s({ id: 'tx020052' }),
        },
      ],
      dom: <>
        <AntdForm.Item name='saveType' noStyle>
          <SelectCode
            queryTime='useEffect'
            style={ { width: 'calc(100% - 75px)' } }
            placeholder={ s({ id: 'tx020052' }) }
            code="SaveType" />
        </AntdForm.Item>
        <Button
          type='link'
          size='small'
          onClick={ () => {
            setAdvancedVisible(old => !old)
          } }
        >{ f({ id: 'tx020060', description: '高级设置' }) }</Button>
      </>
    },
    {
      intlLable: 'tx020054',
      description: '品质(1-10)',
      labelCol: { span: 8 },
      required: true,
      name: 'quality',
      rules: [
        {
          required: true,
          message: s({ id: 'tx020054' }),
        },
      ],
      dom: <Select options={ [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
      ] } />
    },
    {
      intlLable: 'tx020055',
      description: '亮度(0~255)',
      required: true,
      rules: [
        {
          required: true,
          message: e({ id: 'tx020055' }),
        },
        MaxLength(255, 'number'),
        MinLength(0, 'number')
      ],
      name: 'light',
      dom: <InputNumber style={ { width: '100%' } } />
    },
    {
      intlLable: 'tx020056',
      description: '对比度(0~127)',
      labelCol: { span: 8 },
      required: true,
      rules: [
        {
          required: true,
          message: e({ id: 'tx020056' }),
        },
        MaxLength(127, 'number'),
        MinLength(0, 'number')
      ],
      name: 'compare',
      dom: <InputNumber style={ { width: '100%' } } />
    },
    {
      intlLable: 'tx020057',
      description: '饱和度(0~127)',
      required: true,
      rules: [
        {
          required: true,
          message: e({ id: 'tx020057' }),
        },
        MaxLength(127, 'number'),
        MinLength(0, 'number')
      ],
      name: 'stature',
      dom: <InputNumber style={ { width: '100%' } } />
    },
    {
      intlLable: 'tx020058',
      description: '色度(0~255)',
      labelCol: { span: 8 },
      required: true,
      rules: [
        {
          required: true,
          message: e({ id: 'tx020058' }),
        },
        MaxLength(255, 'number'),
        MinLength(0, 'number')
      ],
      name: 'grade',
      dom: <InputNumber style={ { width: '100%' } } />
    },
  ]

  const secondItems: MyFormItemProps[] = [
    {
      intlLable: 'tx020059',
      description: '拍摄间隔',
      required: true,
      name: 'interval',
      dom: <Input />
    },
    {
      intlLable: 'tx000188',
      description: '时间单位',
      name: 'intervalType',
      dom: <SelectCode queryTime='useEffect' placeholder={ s({ id: 'tx000188' }) } code="IntervalType" />
    },
    {
      intlLable: 'tx070024',
      description: '第一路',
      name: '0',
      row: 2,
      dom: videoCheckBox
    },
    {
      intlLable: 'tx070025',
      description: '第二路',
      name: '1',
      row: 2,
      dom: videoCheckBox
    },
    {
      intlLable: 'tx070026',
      description: '第三路',
      name: '2',
      row: 2,
      dom: videoCheckBox
    },
    {
      intlLable: 'tx070027',
      description: '第四路',
      name: '3',
      row: 2,
      dom: videoCheckBox
    },
    {
      intlLable: 'tx070028',
      description: '第五路',
      name: '4',
      row: 2,
      dom: videoCheckBox
    },
  ]

  /** 获取指令状态 */
  const queryCommandStatus = useCallback(async () => {
    const res = await getCommandStatusForPicture({ commandId })
    if (res) {
      const { status, statusName, fileName } = res.data
      if (!isOrderAgain(status)) {
        setFileUrl(fileName || '')
        setStatusTimer(false)
        setButtonDisable(false)
        setTimeText(120)
        statusTime.current = 0
      }
      setStatusName(statusName)
    }
  }, [commandId])

  /** 获取定时拍照参数状态轮询 */
  const queryBubbleTimer = useCallback(async () => {
    const res = await getCommandStatusForPicture({ commandId: timerCommandId })
    if (res) {
      const { status, statusName } = res.data
      setQueryDataStatus(statusName)
      if (!isOrderAgain(status)) {
        setParamsTimer(false)
        setParamsButtonDisable(false)
        bubbleParamsTime.current = 0
        const res = await getBubbleTimer({ commandId: timerCommandId })
        if (res) {
          if (secondForm) {
            let strTimerOnArr: Array<string | number> = [0, 0, 0, 0, 0]
            let strUploadPicArr: Array<string | number> = [0, 0, 0, 0, 0]
            let { strTimerOn, strUploadPic, intervalType, interval } = res.data
            if (strTimerOn) {
              strTimerOnArr = strTimerOn.split(',')
            }

            if (strUploadPic) {
              strUploadPicArr = strUploadPic.split(',')
            }

            let obj: any = {}
            for (const key in strTimerOnArr) {
              let val: Array<string> = []
              if (strTimerOnArr[key] === '1') {
                val.push('0')
              }
              if (strUploadPicArr[key] === '1') {
                val.push('1')
              }
              obj[key] = val.length ? val : ''
            }

            secondForm.setFieldsValue({
              interval: interval,
              intervalType: intervalType.toString(),
              ...obj
            })
          }
        }
      }
    }
  }, [timerCommandId])

  /** 高级参数显示
   * @param type false 隐藏；true 显示
   */
  const advancedParam = (type: boolean = false) => {
    if (!type) {
      return onceItems.slice(0, 6)
    }
    return onceItems
  }

  /**
   * 立即拍照发送指令
   */
  const sendOnceParam = () => {
    // setButtonDisable(true)
    if (onceForm) {
      onceForm.validateFields().then(async (data: any) => {
        setButtonDisable(true)
        const result = await sendBubbleNow({
          ...data,
          vehicleId: positionStory.realTimeVehicleInfo?.vehicleId
        })
        if (result) {
          setCommandId(result.data)
          setStatusTimer(true)
        }
      })
    }
  }

  /** 定时拍照发送指令 */
  const sendSecondParam = () => {
    if (secondForm) {
      secondForm.validateFields().then(async (data: any) => {
        setButtonDisable(true)
        let strTimerOnArr = [0, 0, 0, 0, 0]
        let strUploadPicArr = [0, 0, 0, 0, 0]
        for (const key in data) {
          const isNumber = !isNaN(Number(key))
          const value = data[key]
          if (isNumber && value) {
            if (value.includes('0')) {
              strTimerOnArr[Number(key)] = 1
            }
            if (value.includes('1')) {
              strUploadPicArr[Number(key)] = 1
            }
          }
        }

        data.strTimerOn = strTimerOnArr.join(',')
        data.strUploadPic = strUploadPicArr.join(',')
        data.vehicleId = positionStory.realTimeVehicleInfo?.vehicleId
        const result = await sendTakePictureTimer(data)
        if (result) {
          setCommandId(result.data)
          setStatusTimer(true)
        }
      })
    }
  }

  /** 获取定时参数 */
  const queryParams = async () => {
    const result = await getTakePictureTimer({ vehicleId: positionStory.realTimeVehicleInfo?.vehicleId! })
    if (result) {
      // 处理
      setTimerCommandId(result.data)
      setParamsTimer(true) // 开启轮询获取状态
      setParamsButtonDisable(true) // 禁止button
    }
  }

  return (
    <div className='tx-photograph-modal'>
      <Tabs
        tabBarGutter={ 16 }
      >
        <TabPane
          tab={ f({ id: 'tx070022', description: '立即拍照' }) }
          key={ 1 }
        >
          <div className='tx-photograph-content'>
            <div className='tx-photograph-content-tool'>
              <span>
                { f({ id: 'tx020022', description: '拍照信息' }) }
              </span>
              <Button disabled={ buttonDisable } onClick={ sendOnceParam }>
                { f({ id: 'tx020020', description: '发送命令' }) }
                { buttonDisable ? ` ${timeText} s` : '' }
              </Button>
              <span> { statusName } </span>
            </div>
            <div className='tx-photograph-content-body'>
              <div className='tx-photograph-content-form'>
                <Form
                  form={ onceForm }
                  items={ advancedParam(advancedVisible) }
                  row={ 2 }
                  gutter={ 10 }
                  labelCol={ {
                    span: 10
                  } }
                  initialValues={ {
                    action: '1',
                    interval: 5,
                    photoNum: 1,
                    picSize: '1',
                    saveType: '0',
                    quality: 5,
                    light: 125,
                    compare: 125,
                    stature: 125,
                    grade: 125,
                    channel: "1",
                  } }
                />
              </div>
              <div className='tx-photograph-content-image'>
              {fileUrl ? (
                  fileUrl.lastIndexOf(".mp4") > -1 ? (
                    <video src={createDownLoadUrl(fileUrl)} controls autoPlay />
                  ) : (
                    <Upload
                      className="tx-photograph-upload"
                      value={fileUrl}
                      isDetail
                    />
                  )
                ) : null}
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane
          tab={ f({ id: 'tx070023', description: '定时拍照' }) }
          key={ 2 }
        >
          <div className='tx-photograph-content'>
            <div className='tx-photograph-content-tool'>
              <span>
                { f({ id: 'tx070031', description: '定时拍照参数设置信息' }) }
              </span>
              <Button disabled={ buttonDisable } onClick={ sendSecondParam }>
                { f({ id: 'tx020020', description: '发送命令' }) }
                { buttonDisable ? ` ${timeText} s` : '' }
              </Button>
              <span> { statusName } </span>
              <Button
                disabled={ paramsButtonDisable }
                onClick={ queryParams }
              >
                { f({ id: 'tx000049', description: '参数查询' }) }
                { paramsButtonDisable ? ` ${paramsTimeText} s` : '' }
              </Button>
              <span> { queryDataStatus } </span>
            </div>
            <div className='tx-photograph-content-body'>
              <Form
                form={ secondForm }
                items={ secondItems }
                row={ 2 }
                gutter={ 10 }
                initialValues={ {
                  interval: '120',
                  intervalType: '0'
                } }
              />
            </div>
          </div>

        </TabPane>
      </Tabs>
    </div>
  )
}

export default observer(PhotographModal)
