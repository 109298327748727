/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-11-30 16:16:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-30 16:24:36
 */
import api from './index'
import axios from '../axios'
import { webAPIResponse } from './index.globals'

const MODULE = '/systemfile'

/** 大屏-获取广告 */
export const getAdvertisement = () => axios.post<webAPIResponse.Advertisement[]>(`${api}${MODULE}/getScreenMaxVideos`, {})
