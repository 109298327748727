import React from 'react'
import { Carousel, Empty, Image } from 'antd'
import { webAPIResponse } from 'server/web/index.globals'
import { createDownLoadUrl } from 'util/comm'

interface PictureType {
  result?: webAPIResponse.AlarmInfo
}

const Picture = (props: PictureType) => {
  const { result } = props
  return (
    <Carousel>
      {result && result.alarmImgList && result.alarmImgList.length > 0 ? (
        result.alarmImgList.map(item => {
          const { id, filePath } = item
          return <Image key={id} src={createDownLoadUrl(filePath)} />
        })
      ) : (
        <div>
          <Empty style={{ marginTop: 30 }} />
        </div>
      )}
    </Carousel>
  )
}

export default Picture
