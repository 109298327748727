import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import useSetState from "util/useSetState";
import { intlStore } from "store";
import { Form, Row, Col } from "antd";
import { FormProps } from "antd/lib/form/Form";
import { FormInstance } from "antd/lib/form";
import { FormItemProps } from "antd/lib/form/FormItem";
import { Gutter } from "antd/lib/grid/row";
import classnames from "classnames";
import { isNumber } from "lodash-es";

export interface MyFormProps<T> extends FormProps {
  /**
   * formItem数组
   */
  items: MyFormItemProps[];
  /**
   * 表单初始数据
   */
  initData?: T;
  /**
   * 每行显示数量，默认为 1
   */
  row?: "flex" | number;
  /**
   * label的宽度
   */
  labelWidth?: number | string;
  ref?: any;
  /** 栅格间隔，可以写成像素值或支持响应式的对象写法来设置水平间隔 { xs: 8, sm: 16, md: 24},或者使用数组形式同时设置 [水平间距, 垂直间距]
   * @description 必须是2的倍数，antd计算规则为
   * @example
   *     {
   *        marginLeft: gutter[0]! / -2,
   *        marginRight: gutter[0]! / -2,
   *     }
   */
  gutter?: Gutter | [Gutter, Gutter];
  /** 栅格左侧的间隔格数，间隔内不可以有栅格 */
  offset?: number;
}

export interface MyFormItemProps extends FormItemProps {
  /**
   * item 下的dom
   */
  dom?: JSX.Element;
  /**
   * 行占比，与props中的row相等时，占据整行
   */
  row?: number;
  /** 栅格左侧的间隔格数，间隔内不可以有栅格 */
  offset?: number;
  /**
   * 需要特殊处理值，表单验证出发后，进行处理,返回类型可以是对象
   * @param value 该字段的值
   * @param values 本次请求的所有字段
   */
  handleValue?: (value: any, values: any) => any;
  /** 可组合控件 */
  groupItem?: MyFormItemGroupProps[];
}

export interface MyFormItemGroupProps extends Omit<FormItemProps, "label"> {
  /**
   * item 下的dom
   */
  dom?: JSX.Element;
}

interface MyState {
  items: any[];
}

type FormType = <T extends object>(
  props: MyFormProps<T>,
  ref: React.Ref<any>
) => React.ReactElement;

function Index<T>(props: MyFormProps<T>, ref: any) {
  const { items, initData, row, labelWidth, gutter, offset, ...extra } = props;

  const [state, setState] = useSetState<MyState>({
    items: [],
  });

  const [form] = Form.useForm();

  useEffect(() => {
    const className = props.className
      ? "." + props.className + " .ant-form-item-label"
      : "";
    if (labelWidth && className) {
      requestAnimationFrame(() => {
        const doms = (window as any).document.querySelectorAll(className);
        const width = isNumber(labelWidth) ? labelWidth + "px" : labelWidth;
        doms.forEach((item: any) => {
          item.style.width = width;
        });
      });
    }
  }, [labelWidth, props.className, items]);

  useEffect(() => {
    handleItem();
  }, [items, row]);

  useImperativeHandle(ref, () => {
    return {
      ...form,
      validateFields: validateFields,
    };
  });

  /**
   * 创建Item
   * @param item
   */
  const handleItem = () => {
    let result = [];

    if (row && row === "flex") {
      result = items.map((item: any) => {
        return <div key={item.name}>{createItem(item)}</div>;
      });
    } else {
      result = items.map((item: MyFormItemProps, index: number) => {
        let span: number = 24 * ((item.row || 1) / (row || 1));
        const selfOffset = item.offset ? item.offset : offset ? offset : 0;
        return (
          <Col
            offset={selfOffset}
            span={span}
            key={(item.name as string) || "form_item_" + index}
          >
            {" "}
            {createItem(item)}{" "}
          </Col>
        );
      });
    }

    setState({
      items: result,
    });
  };

  /**
   * 处理Item
   * @param item
   */
  const createItem = (item: MyFormItemProps) => {
    const { dom, label, handleValue, groupItem, ...extra } = item;

    const _groupItem = groupItem
      ? groupItem.map((item) => {
          const { dom, ...extra } = item;
          return <Form.Item {...extra}>{dom}</Form.Item>;
        })
      : null;

    const _dom = _groupItem ? _groupItem : dom;
    // TODO 暂未处理groupItem换行问题
    if (label) {
      return (
        <Form.Item
          label={label}
          {...extra}
          className={classnames({
            "ant-form-item-label-en": intlStore.lang === "en_US",
          })}
        >
          {_dom}
        </Form.Item>
      );
    }
    return (
      <Form.Item
        {...extra}
        className={classnames({
          "ant-form-item-label-en": intlStore.lang === "en_US",
        })}
      >
        {_dom}
      </Form.Item>
    );
  };

  /**
   * 表单验证
   */
  const validateFields: FormInstance["validateFields"] = async (nameList) => {
    const result: any = await form.validateFields(nameList);

    if (result) {
      let obj: any = result;
      items.forEach((item: MyFormItemProps) => {
        const name: any = item.name;
        // 判断是否有处理特殊值需求
        if (item.handleValue && typeof name === "string") {
          const value = item.handleValue(result[name], result);
          // 是否为对象
          if (typeof value === "object" && !Array.isArray(value)) {
            obj = Object.assign({}, obj, value);
          } else {
            obj[name] = value;
          }
        } else {
          if (name) {
            obj[name] = result[name];
          }
        }
      });
      return obj;
    }
    return result;
  };

  return (
    <Form
      form={props.form || form}
      name={props.name || "hotpot-form"}
      colon={props.colon || false}
      initialValues={initData || {}}
      {...extra}
      className={classnames("tx-form", props.className)}
    >
      <Row gutter={gutter || 0}>{state.items}</Row>
    </Form>
  );
}

export default forwardRef(Index) as FormType;
