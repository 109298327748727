import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { Divider, Input } from 'antd'
import { SelectCode, Form } from 'components'
import { useIntl } from 'react-intl'
import { FormInstance } from 'antd/lib/form'
import { MyFormItemProps } from 'components/form'
import { webAPIResponse } from 'server/web/index.globals'
import { alarmStatus } from '../index'
import useBasicCodes from 'util/useBasicCodes'
import { pleaseSelect as s, pleaseEnter as e } from 'util/comm'
import { MaxLength } from 'util/validator'
import { isUndefined } from 'lodash-es'
import Lnglats from 'util/lnglats'

const { TextArea } = Input

interface BaseInfoType {
  record?: webAPIResponse.AlarmPage
  result?: webAPIResponse.AlarmInfo
}

const BaseInfo = (props: BaseInfoType, ref: React.Ref<any>) => {
  const { record, result } = props
  const plateNo = result?.plateNo || record?.plateNo || ''
  const driverName = result?.driverName || record?.driverName || ''
  const alarmTypeName = result?.alarmTypeName || record?.alarmTypeName || ''
  const depName = result?.depName || record?.depName || ''
  // const location = result?.location || record?.location || ''
  const location = <Lnglats lnglat={[Number(record?.longitude) || Number(result?.longitude), Number(record?.latitude) || Number(result?.latitude)]} />
  // const location = lnglatAmap(record?.longitude || result?.longitude, record?.latitude || result?.latitude)
  const alarmTime = result?.alarmTime || record?.alarmTime || ''
  const similarity = isUndefined(result?.similarity) ? '' : result?.similarity
  const compareTypeName = isUndefined(result?.compareTypeName) ? '' : result?.compareTypeName

  const { formatMessage: f } = useIntl()
  const formRef = useRef<FormInstance>()
  const [{ data: basicCodes }] = useBasicCodes(['ProcessSuggest'])
  const formItem: MyFormItemProps[] = [
    {
      label: f({ id: 'tx010001', description: '车牌号' }),
      dom: <span>{plateNo}</span>,
    },
    {
      label: f({ id: 'tx110004', description: '驾驶员' }),
      dom: <span>{driverName}</span>,
    },
    {
      label: f({ id: 'tx020002', description: '报警类型' }),
      dom: <span>{alarmTypeName}</span>,
    },
    {
      label: f({ id: 'tx010002', description: '车队名称' }),
      dom: <span>{depName}</span>,
    },
    {
      label: f({ id: 'tx020048', description: '比对相似度' }),
      dom: <span>{similarity}</span>,
      hidden: result?.showType !== '1',
    },
    {
      label: f({ id: 'tx020049', description: '比对类型' }),
      dom: <span>{compareTypeName}</span>,
      hidden: result?.showType !== '1',
    },
    {
      label: f({ id: 'tx020004', description: '报警地点' }),
      dom: <span>{location}</span>,
    },
    {
      label: f({ id: 'tx020003', description: '报警时间' }),
      dom: <span>{alarmTime}</span>,
    },
    {
      name: 'processSuggest',
      label: f({ id: 'tx030003', description: '处理意见' }),
      rules: [
        {
          required: true,
          message: f(
            { id: 'tx000201', description: '请选择处理意见' },
            { value: f({ id: 'tx030003' }) },
          ),
        },
      ],
      dom: (
        <SelectCode
          className="select"
          placeholder={s({ id: 'tx030003' })}
          basicCodesOption={basicCodes?.ProcessSuggest}
        />
      ),
    },
    {
      name: 'remark',
      label: f({ id: 'tx000105', description: '备注' }),
      dom: <TextArea placeholder={e({ id: 'tx000105' })} className="textArea" />,
      rules: [MaxLength(255)],
    },
    {
      name: 'alarmIds',
      hidden: true,
      initialValue: [record?.id],
      dom: <Input />,
    },
    {
      name: 'processed',
      hidden: true,
      initialValue: alarmStatus.clear,
      dom: <Input />,
    },
  ]

  useImperativeHandle(ref, () => ({
    ...formRef.current,
  }))

  return (
    <div className="baseInfo">
      <div className="title">
        <span>{f({ id: 'tx000111', description: '基础信息' })}</span>
        <Divider className="divider" />
      </div>
      <Form ref={formRef} initData={result} items={formItem} row={2} gutter={40} colon />
    </div>
  )
}

export default forwardRef(BaseInfo)
