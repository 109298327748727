/*
 * @Description: 报警数据相关
 * @Author: shenkaiyao
 * @Date: 2020-08-24 10:11:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-07 15:45:01
 */
import api from './index'
import axios from '../axios'
import { TableResponseType } from 'components/table/interface';
import { webAPIRequest, webAPIResponse, PageProps, Id } from './index.globals';

const MODULE = '/alarm'

export interface MediaProps {
  /** 时间 */
  createDate: string
  /** 文件大小 */
  fileLength: number
  /** 文件名称 */
  fileName: string
  /** 文件路径 */
  filePath: string
  /** 时间 */
  fileType: number
  /** 文件类型 */
  id: string
  /** 状态 */
  status: string
}

/** 获取安全报警查询页面列表数据 */
export const getAlarmPage = (payload: webAPIRequest.AlarmPage) => axios.post<TableResponseType<webAPIResponse.AlarmPage>>(`${api}${MODULE}/findPageBy`, payload)

/** 获取报警详情数据 */
export const getAlarmInfo = (payload: Id) => axios.post<webAPIResponse.AlarmInfo>(`${api}${MODULE}/findInfo`, payload)

/** 综合监控/查询中心-报警处理 */
export const handleAlarm = (payload: webAPIRequest.HandleAlarm) => axios.post<boolean>(`${api}${MODULE}/query/handle`, payload)

/** 综合监控-获取报警分页 */
export const getMonitorAlarmPage = (data: PageProps) => axios.post<TableResponseType<webAPIResponse.monitorAlarmPage>>(`${api}${MODULE}/page`, data)
/** 综合监控-车辆运单 */
export const getWaybill = (data: PageProps) => axios.post<TableResponseType<webAPIResponse.monitorAlarmPage>>(`${api}${MODULE}/getConcreteTransBill`, data)

/** 综合监控-轨迹回放-报警数据查询 */
export const getMonitorTrajectoryAlarmPage = (data: webAPIRequest.monitorTrajectoryAlarmPage) => axios.post<TableResponseType<webAPIResponse.monitorTrajectoryAlarmPage>>(`${api}${MODULE}/findByVehicle`, data)

/** 不分页视频附件 */
export const getAttachment = () => axios.post<webAPIResponse.alarmAttachment[]>(`${api}${MODULE}/attachment/get`, {})

/** 查询中心-报警数据-导出 */
export const exportAlarmAllBy = (payload: any) => axios.post<boolean>(`${api}${MODULE}/exportAlarmAllBy`, payload)

/**报警地图查询*/
export const getAlarmMapPage = (payload: any) => axios.post(`${api}${MODULE}/alarmMap/page`, payload)

/**报警地图所有点位查询 */
export const getAlarmMapList = (payload: any) => axios.post(`${api}${MODULE}/alarmMap/list`, payload)