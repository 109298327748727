import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { OlMap, OlMapTool, OlMarker, OlVector, OlInfoWindow, OlPathSimplifier } from 'components'
// import OlPathSimplifier from 'components/ol/pathSimplifierCopy';
import { PathToolType } from 'components/ol/pathSimplifier';
function DevOlMap() {
  const [type, setType] = useState<string>('baidu')
  const [position, setposition] = useState([106.57995, 29.562947])
  const [rotation, setrotation] = useState(10)
  const [tool, setTool] = useState<PathToolType>(PathToolType.none)
  const [point, setPoint] = useState(0)
  const [visible, setVisible] = useState(1)

  useEffect(() => {
    setTimeout(() => {
      setposition([106.57895, 29.568947])
    }, 4000)
    setTimeout(() => {
      setrotation(100)
    }, 7000)
  }, [])

  return (
    <div
      style={ {
        width: '100%',
        height: '100vh'
      } }
    >
      <Select
        value={ type }
        onSelect={ setType }
      >
        <Select.Option value='amap'> 高德 </Select.Option>
        <Select.Option value='baidu'> 百度 </Select.Option>
      </Select>
      <Select
        value={ visible }
        onSelect={ setVisible }
      >
        <Select.Option value={ 1 }> 显示 </Select.Option>
        <Select.Option value={ 0 }> 隐藏 </Select.Option>
      </Select>
      <Select
        value={ tool }
        onSelect={ (value) => {
          setTool(value as PathToolType)
        } }
      >
        <Select.Option value='start'> 开始 </Select.Option>
        <Select.Option value='stop'> 停止 </Select.Option>
        <Select.Option value='pause'> 暂停 </Select.Option>
        <Select.Option value='recovery'> 恢复 </Select.Option>
      </Select>
      <Select
        value={ point }
        onSelect={ setPoint }
      >
        <Select.Option value={ 1 }> 1 </Select.Option>
        <Select.Option value={ 4 }> 4 </Select.Option>
        <Select.Option value={ 8 }> 8 </Select.Option>
        <Select.Option value={ 9 }> 9 </Select.Option>
        <Select.Option value={ 10 }> 10 </Select.Option>
        <Select.Option value={ 11 }> 11 </Select.Option>
      </Select>
      <OlMap type={ type } center={ [106.57995, 29.572947] } zoom={ 4 }>
        <OlMarker

          position={ [103.26194116040091, 26.941265723065115] }
          offset={ [0, -13] }
          icon={ {
            src: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [24, 36],
            scale: 1.2
          } }
        />
        {/* <OlMarker

          position={ [116.53080498376438, 26.940678730612834] }
          offset={ [0, -13] }
          icon={ {
            src: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [24, 36],
            scale: 1.2
          } }
        /> */}
        {/* <OlMarker

          position={ [116.52999953871486, 36.42528150413185] }
          offset={ [0, -13] }
          icon={ {
            src: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            size: [24, 36],
            scale: 1.2
          } }
        /> */}
        <OlPathSimplifier
          movePoint={ point }
          speed={ 1000000 }
          path={ [
            [106.57995, 29.572947],
            [106.67995, 29.562947],
            [106.77995, 29.563947],
            [106.87995, 29.565947],
            [106.97995, 29.566947],
            [107.07995, 29.567947],
            [107.17995, 29.568947],
            [107.27995, 29.569947],
          ] }
          markerIcon={ {
            anchor: [0.5, 0.5]
          } }
          tool={ tool }
        />
        <OlVector
          features={ [
            {
              type: 'polygon',
              bounds: [[106.548469, 29.610522],
              [106.553763, 29.610522],
              [106.553763, 29.609062],
              [106.548469, 29.609062]]
            },
            {
              type: 'circle',
              center: [107.57995, 29.572947],
              radius: 3000
            },
            // {
            //   type: 'marker',
            //   bounds: [[107.57995, 29.572947]]
            // },
          ] }
          view={ true }
        />

      </OlMap>
    </div>
  )
}

export default DevOlMap
