import React, { useRef, useState, useMemo, FC, useCallback, useEffect } from 'react'
import { Stretch as TXStretch, Form, Info, Tabs, TabPane, SelectQuery, DatePicker } from 'components'
import { Input, Button, message, Form as AntdForm, Checkbox } from 'antd'
import { MyFormItemProps } from 'components/form'
import { useIntl } from 'react-intl'
import { InfoItems } from 'components/info'
import TimeLine from './timeLine'
import moment from 'moment'
import useSetState from 'util/useSetState'
import { getTrackSeg, getTrackLocationAll, TrackListsDto, getTrackExport } from 'server/web/trackList'
import { getVehiclePage } from 'server/web/vehicle';
import { webAPIRequest, webAPIResponse } from 'server/web/index.globals'
import { TableResponseType } from 'components/table/interface';
import { concat } from 'lodash-es'
import { monitorStore, trajectoryStory } from 'store'
import { useObserver, observer } from 'mobx-react'
import { fileDownload } from 'util/comm';
interface StretchProps {
  /** 查询后数据改变事件 */
  dataChange?: Function
  /** 车牌号 */
  vehicleNo?: string
  visible?: boolean
  isForeign?: boolean
}

const areEqual = (prevProps: StretchProps, nextProps: StretchProps) => {
  return prevProps.vehicleNo === nextProps.vehicleNo
}

const tabBarStyle: React.CSSProperties = {
  height: '.4rem',
  margin: 0,
  paddingLeft: 16,
  color: '#222',
}

interface MyState {
  trackConLists: TrackListsDto[]
  trackInconLists: TrackListsDto[]
  integrityRate: number
  isAbnormalName: string
  isAbnormal: number
  mileageCon: string
  mileageIncon: string
  segNumCon: string
  segNumIncon: string
  visible: boolean
  loading: boolean
  exportLoading: boolean
  formData: webAPIRequest.TrackSeg | undefined
  locationArr: locationArrayData[]
  disableDay: string
}

interface locationArrayData {
  id: string
  data: Array<[number, number]>
}

const Stretch: FC<StretchProps> = React.memo(
  observer((props: StretchProps) => {
    const [state, setState] = useSetState<MyState>({
      trackConLists: [],
      trackInconLists: [],
      integrityRate: 0,
      isAbnormal: 0,
      isAbnormalName: '',
      mileageCon: '',
      mileageIncon: '',
      segNumCon: '',
      segNumIncon: '',
      visible: false,
      loading: false,
      exportLoading: false,
      formData: undefined,
      locationArr: [],
      disableDay: moment(new Date()).format('YYYY-MM-DD 00:00:00'),
    })

    const selfForm: any = useRef()
    const [dayType, setDatType] = useState<number>(0)
    const { formatMessage: f } = useIntl()

    const endTimeDisable = useCallback(
      current => {
        return (
          current &&
          (current < moment(state.disableDay) ||
            current > moment(state.disableDay).add(3, 'days') ||
            current > moment().endOf('day'))
        )
      },
      [state.disableDay],
    )

    useEffect(() => {
      if (monitorStore.tabsKey === '2' && monitorStore.vehicleInfo.plateNo) {
        selfForm.current.setFieldsValue({
          plateNo: monitorStore.vehicleInfo.plateNo,
        })
      }
      if (props.isForeign && monitorStore.vehicleInfo.plateNo) {
        selfForm.current.setFieldsValue({
          plateNo: monitorStore.vehicleInfo.plateNo,
          begTime: monitorStore.vehicleInfo.begTime,
          endTime: monitorStore.vehicleInfo.endTime,
        })
        query()
      }
    }, [monitorStore.vehicleInfo])

    const items: MyFormItemProps[] = useMemo(() => {
      return [
        {
          name: 'plateNo',
          dom: <SelectQuery
            query={ getVehiclePage }
            disabled={ props.isForeign }
            handQueryData={ (result: TableResponseType<webAPIResponse.VehiclePage>) => {
              return result.records.map(item => ({
                label: item.plateNo,
                value: item.plateNo
              }))
            } }
            queryParam={ {
              current: 1,
              size: 10
            } }
            searchKey='plateNoLike'
            placeholder={ f({ id: 'tx010001', description: '车牌号' }) }
          />,
        },
        {
          name: 'begTime',
          dom: (
            <DatePicker
              showTime
              placeholder={ f({ id: 'tx000103', description: '开始时间' }) }
              format="YYYY-MM-DD HH:mm"
              style={ { width: '100%' } }
              disabledDate={ (current: any) => current && current > moment().endOf('day') }
              onChange={ (date: any) => {
                setState({ disableDay: date })
              } }
            />
          ),
        },
        {
          name: 'endTime',
          dom: (
            <DatePicker
              showTime
              placeholder={ f({ id: 'tx000104', description: '结束时间' }) }
              format="YYYY-MM-DD HH:mm"
              style={ { width: '100%' } }
              disabledDate={ endTimeDisable }
            />
          ),
          required: true,
        },
        {
          dom: (
            <div style={ { display: 'flex', justifyContent: 'space-between' } }>
              <Button type={ dayType === 0 ? 'primary' : 'default' } onClick={ todayClick }>
                { f({ id: 'tx000124', description: '今天' }) }
              </Button>
              <Button type={ dayType === 1 ? 'primary' : 'default' } onClick={ yesterdayClick }>
                { f({ id: 'tx000123', description: '昨天' }) }
              </Button>
              <Button type={ dayType === 2 ? 'primary' : 'default' } onClick={ latelyClick }>
                { f({ id: 'tx000125', description: '最近三天' }) }
              </Button>
            </div>
          ),
        },
        {
          dom: (
            <>
              <AntdForm.Item name='minSpeed' style={ { width: '42%', float: 'left' } }>
                <Input
                  placeholder={ f({ id: 'tx010022', description: '最低速度' }) }
                  style={ { width: '100%' } }
                />
              </AntdForm.Item>
              <AntdForm.Item name='isValid' valuePropName='checked' style={ { width: '45%', float: 'right' } }>
                <Checkbox>{ f({ id: 'tx000058', description: '显示无效位置' }) }</Checkbox>
              </AntdForm.Item>
            </>
          )

          // 验证有问题
        },
      ]
    }, [dayType, state.disableDay])

    const [infoItems] = useState<InfoItems[]>([
      {
        children: [
          { dataIndex: 'isAbnormalName', intlTitle: 'tx000128', description: '轨迹异常' },
          {
            dataIndex: 'integrityRate', intlTitle: 'tx000129', description: '轨迹完整率', render: (value: string) => {
              return <span>{ value + '%' }</span>
            }
          },
        ],
      },
    ])

    const [TabsinfoItems] = useState<InfoItems[]>([
      {
        children: [
          { dataIndex: 'segNumCon', intlTitle: 'tx000130', description: '轨迹分段' },
          { dataIndex: 'mileageCon', intlTitle: 'tx000131', description: '轨迹里程' },
        ],
      },
    ])

    const [TabsinfoItems2] = useState<InfoItems[]>([
      {
        children: [
          { dataIndex: 'segNumIncon', intlTitle: 'tx000130', description: '轨迹分段' },
          { dataIndex: 'mileageIncon', intlTitle: 'tx000131', description: '轨迹里程' },
        ],
      },
    ])

    /** 查询按钮点击事件 */
    const query = (isExports: boolean = false) => {
      monitorStore.cleanRealSpeedData()
      if (selfForm) {
        selfForm.current
          .validateFields()
          .then(async (data: any) => {
            data.isValid = +data.isValid
            if (!data.plateNo) {
              message.warning(f({ id: 'tx000202' }, { value: f({ id: 'tx010001' }) }))
            } else if (!data.begTime) {
              message.warning(f({ id: 'tx000201' }, { value: f({ id: 'tx000103' }) }))
            } else if (!data.endTime) {
              message.warning(f({ id: 'tx000201' }, { value: f({ id: 'tx000104' }) }))
            } else if (moment(data.endTime) < moment(data.begTime)) {
              message.warning(f({ id: 'tx000137', description: '结束时间不能小于开始时间' }))
            } else if (moment(data.endTime) > moment(data.begTime).add(3, 'days')) {
              message.warning(f({ id: 'tx000138', description: '时间范围不能超过3天' }))
            } else {
              // 查询时间
              if (!isExports) {
                monitorStore.cleanTrajectPositionData()

                setState({
                  loading: true,
                  trackConLists: [],
                  trackInconLists: []
                })
                getTrackSeg(data).then(res => {
                  setState({ loading: false })
                  trajectoryStory.updateDrawLoading(true)

                  if (res) {
                    setState({
                      formData: data,
                      trackConLists: res.data.trackConLists || [],
                      trackInconLists: res.data.trackInconLists || [],
                      isAbnormal: res.data.isAbnormal || 0,
                      isAbnormalName: res.data.isAbnormalName || '',
                      integrityRate: res.data.integrityRate || 0,
                      mileageCon: res.data.mileageCon || '0',
                      mileageIncon: res.data.mileageIncon || '0KM',
                      segNumCon: res.data.segNumCon || '0',
                      segNumIncon: res.data.segNumIncon || '0KM',
                      visible: true,
                    })

                    if (res.data.trackConLists) {
                      const locationObjArr: TrackListsDto[] = concat(
                        res.data.trackConLists || [],
                        res.data.trackInconLists || [],
                      )
                      if (!locationObjArr.length) {
                        trajectoryStory.updateDrawLoading(false)
                      }
                      const newLocation = locationObjArr.map(item => ({
                        id: item.id,
                        begTime: item.begTime,
                        endTime: item.endTime,
                        isAbnormal: res.data.isAbnormal || 0,
                        isValid: data.isValid,
                        plateNo: data.plateNo,
                        minSpeed: data.minSpeed?data.minSpeed:'0'
                      }))
                      loopQueryLocal(newLocation, 0, [])
                    } else {
                      trajectoryStory.updateDrawLoading(false)
                    }

                  } else {
                    trajectoryStory.updateDrawLoading(false)
                  }
                }).catch((_err: any) => {
                  setState({ loading: false })
                })
              } else {
                // 导出事件
                setState({ exportLoading: true })
                const result: any = await getTrackExport(data)
                setState({ exportLoading: false })
                if (result) {
                  fileDownload(result)
                }
              }
            }
          })
      }
    }

    const loopQueryLocal = (data: any, index: number, trackLocationArr: any[]) => {
      if (index < data.length) {
        const query = data[index]
        getTrackLocationAll(query).then(res => {
          if (res && res.data && res.data.length) {
            const arr: Array<[number, number]> = res.data.map(item => {
              return [item.longitudePrimeval, item.latitudePrimeval]
            })
            loopQueryLocal(data, index + 1, [
              ...trackLocationArr,
              { id: query.id, data: arr }
            ])
          }
        })
      } else {
        trajectoryStory.updateDrawLoading(false)
        monitorStore.updateTrajectPositionData(trackLocationArr)
      }
    }

    // const queryLocal = (query: any) => {
    //   getTrackLocationAll(query).then(res => {
    //     if (res) {
    //       const arr: Array<[number, number]> = res.data.map(item => {
    //         return [item.longitudePrimeval, item.latitudePrimeval]
    //       })
    //       monitorStore.addTrajectPositionData({ id: query.id, data: arr })
    //     }
    //   })
    // }

    /** 导出按钮点击事件 */
    const exports = () => {
      query(true)
    }

    /** 今天按钮点击事件 */
    function todayClick() {
      setDatType(0)
      if (selfForm) {
        setState({ disableDay: moment(new Date()).format('YYYY-MM-DD 00:00') })
        selfForm.current.setFieldsValue({
          begTime: moment(new Date()).format('YYYY-MM-DD 00:00'),
          endTime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        })
      }
    }

    /** 昨天按钮点击事件 */
    function yesterdayClick() {
      setDatType(1)
      if (selfForm) {
        setState({ disableDay: moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD 00:00') })
        selfForm.current.setFieldsValue({
          begTime: moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD 00:00'),
          endTime: moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD 23:59'),
        })
      }
    }

    /** 最近三天按钮点击事件 */
    function latelyClick() {
      setDatType(2)
      if (selfForm) {
        setState({ disableDay: moment(new Date()).subtract(2, 'day').format('YYYY-MM-DD 00:00') })
        selfForm.current.setFieldsValue({
          begTime: moment(new Date()).subtract(2, 'day').format('YYYY-MM-DD 00:00'),
          endTime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        })
      }
    }

    return useObserver(() => (
      <TXStretch width={ 280 } className="trajectory-stretch">
        <Form
          ref={ selfForm }
          items={ items }
          initData={ {
            begTime: moment(new Date()).format('YYYY-MM-DD 00:00:00'),
            endTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            minSpeed: '0',
            isValid: 0
          } }
        />
        <div className="trajectory-stretch-btns">
          {
            <Button
              type="primary"
              onClick={ () => { query() } }
              loading={ state.loading }
              style={ { padding: '0 18px', marginRight: 4 } }
            >
              { f({ id: 'tx000006', description: '查询' }) }
            </Button>
          }
          {/* <Button
            onClick={ exports }
            style={ { padding: '0 18px' } }
            loading={ state.exportLoading }
          >
            { f({ id: 'tx000009', description: '导出' }) }
          </Button> */}
        </div>
        <div className="trajectory-stretch-info">
          { state.visible &&
            <Info titleWidth={ 70 } items={ infoItems } data={ state } row={ 2 } />
          }
          { state.visible && (
            <Tabs
              tabBarStyle={ tabBarStyle }
              defaultActiveKey="1"
              tabBarGutter={ 16 }
              className="trajectory-stretch-tabs"
            >
              <TabPane key="1" tab={ f({ id: 'tx000198', description: '连续轨迹' }) }>
                <div style={ { height: '100%', display: 'flex', flexDirection: 'column' } }>
                  <Info titleWidth={ 70 } items={ TabsinfoItems } data={ state } />
                  <div className="trajectory-stretch-timeline-content">
                    <TimeLine
                      items={ state.trackConLists }
                      formData={ state.formData }
                      isAbnormal={ state.isAbnormal }
                    />
                  </div>
                </div>
              </TabPane>
              <TabPane key="2" tab={ f({ id: 'tx000199', description: '不连续轨迹' }) }>
                <div style={ { height: '100%', display: 'flex', flexDirection: 'column' } }>
                  <Info titleWidth={ 70 } items={ TabsinfoItems2 } data={ state } />
                  <div className="trajectory-stretch-timeline-content">
                    <TimeLine
                      items={ state.trackInconLists }
                      formData={ state.formData }
                      isAbnormal={ state.isAbnormal }
                    />
                  </div>
                </div>
              </TabPane>
            </Tabs>
          ) }
        </div>
      </TXStretch>
    ))
  }),
  areEqual,
)

export default Stretch
