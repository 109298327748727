import React from 'react'
import Chunk from './components/chunk'
import Title from './components/title';
import ContentTotal from './components/contentTotal'
import { useIntl } from 'react-intl'
import { Progress } from 'antd';

interface VehicleTotalProps {
  vehicleNum?: number
  onLineVehicleNum?: number
  dangerVehicleNum?: number
  onLineVehicleNumRatio?: string
  dangerVehicleNumRatio?: string
}

function VehicleTotal(props: VehicleTotalProps) {

  const { formatMessage: f } = useIntl()

  return (
    <div className='tx-large-vehicle-total'>
      <Chunk>
        <div className='large-vehicle-total'>
          <div className='top-title'>
            <div className='top-title-name'>{ f({ id: 'tx010018', description: '车辆总数' }) } </div>
            <Title value={ props.vehicleNum || 0 } />
          </div>
          <div>
            <div className='content-total-data' >
              <div className='content-total-item'>
                <ContentTotal title='tx010110' value={ props.onLineVehicleNum || 0 } icon='icon1' />
                <Progress className='item-progress' type="circle" percent={ Number(props.onLineVehicleNumRatio || 0) } strokeColor='#1398F5' format={ value => <span className='item-progress-value'>{ value } <span>%</span></span> } />
                <div className='item-title'>
                  { f({ id: 'tx000160', description: '在线率' }) }
                </div>
              </div>
              <div className='content-total-item'>
                <ContentTotal title='tx010111' value={ props.dangerVehicleNum || 0 } icon='icon2' />
                <Progress className='item-progress' type="circle" percent={ Number(props.dangerVehicleNumRatio || 0) } strokeColor='#F76262' format={ value => <span className='item-progress-value'>{ value } <span>%</span></span> } />
                <div className='item-title'>
                  { f({ id: 'tx000161', description: '危险率' }) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Chunk>
    </div>
  )
}

export default VehicleTotal
