/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-09-28 19:41:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-11 16:15:20
 */
import { useState, useEffect } from 'react';
import { departmentTree } from 'server/web/department'
import { handleTreeData } from 'util/comm'
import { BasicCode } from 'server/web/basicCode'
import { CompanyIds } from 'server/web/index.globals'
import { QueryState, UseAxiosHook } from './interface';

type Padload = CompanyIds
type Result = BasicCode[]

const useDepTree: UseAxiosHook<Padload, Result> = (padload, callback) => {
  const [param, setParam] = useState(padload)
  const [state, setState] = useState<QueryState<Result>>({
    data: undefined,
    loading: false,
    success: false
  });

  useEffect(() => {
    const queryDepTree = async () => {
      setState((state) => ({ ...state, loading: true, success: false }))
      const res = await departmentTree(param)
      setState((state) => ({ ...state, loading: false }))
      if (res) {
        const data = handleTreeData(res.data, { title: 'depName', key: 'id' })
        setState(state => ({
          ...state, data, success: true
        }))
        callback && callback(data)
      } else {
        setState(state => ({
          ...state, success: false
        }))
      }
    }
    queryDepTree()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  return [state, setParam]
}

export default useDepTree