import React, { useState, useRef, memo } from 'react'
import { Input } from 'antd'
import { Modal, Table, TreeSelect, SelectCode } from 'components'
import { FormInstance } from 'antd/lib/form'
import { ButtonGroupType, ColumnsType, TableAxiosType } from 'components/table/interface'
import { MyFormItemProps } from 'components/form'
import { ModalProps } from './interface'
import { useIntl } from 'react-intl'
import { getVehiclePage } from 'server/web/vehicle'
import { webAPIResponse } from 'server/web/index.globals'
import { pleaseEnter as e, pleaseSelect as s } from 'util/comm'
import useDepTree from 'util/useDepTree'
import useCompanyTree from 'util/useCompanyTree'
import './index.less'

const PlateNo = (props: ModalProps<webAPIResponse.VehiclePage>) => {
  const tableName = 'modal.plateNo'
  const { onOk } = props
  const form = useRef<FormInstance>()
  const { formatMessage: f } = useIntl()
  const [selectValue, setSelectValue] = useState<webAPIResponse.VehiclePage[]>([])

  const [{ data: companyTree, loading: companyTreeLoading }] = useCompanyTree({ companyName: '' })

  const [{ data: depTree, loading: depLoading }, setDepTree] = useDepTree({
    companyIds: form.current?.getFieldValue('companyIds'),
  })

  const [columns] = useState<ColumnsType<webAPIResponse.VehiclePage>>([
    {
      dataIndex: 'plateNo',
      intlTitle: 'tx010001',
      description: '车牌号',
    },
    {
      dataIndex: 'simNo',
      intlTitle: 'tx060001',
      description: 'sim卡号',
    },
    {
      dataIndex: 'companyName',
      intlTitle: 'tx130001',
      description: '所属企业',
    },
    {
      dataIndex: 'depName',
      intlTitle: 'tx010002',
      description: '车队',
    },
    {
      dataIndex: 'plateColorName',
      intlTitle: 'tx010011',
      description: '车牌颜色',
    },
    {
      dataIndex: 'onlineName',
      intlTitle: ['tx060002', 'tx010101'],
      description: '在线状态',
    },
  ])

  const formItems: MyFormItemProps[] = [
    {
      name: 'plateNoLike',
      dom: (
        <Input
          style={{ width: '1.4rem' }}
          placeholder={e({ id: 'tx010001', description: '车牌号' })}
        />
      ),
    },
    {
      name: 'companyIds',
      dom: (
        <TreeSelect
          style={{ width: '2.2rem' }}
          showSearch
          treeCheckable
          virtual={false}
          placeholder={s({ id: 'tx130000', description: '企业' })}
          treeDefaultExpandAll
          treeData={companyTree}
          loading={companyTreeLoading}
          showCheckedStrategy="SHOW_ALL"
          onChange={(value: string[]) => {
            setDepTree({ companyIds: value })
            form.current?.setFieldsValue({
              depIds: undefined,
            })
          }}
          maxTagCount={0}
          maxTagPlaceholder={omittedValues =>
            f(
              { id: 'tx000213', description: '共选择xxx项企业' },
              { value: omittedValues.length, value2: f({ id: 'tx130000' }) },
            )
          }
        />
      ),
    },
    {
      name: 'depIds',
      dom: (
        <TreeSelect
          style={{ width: '2.16rem' }}
          showSearch
          treeCheckable
          virtual={false}
          placeholder={s({ id: 'tx010002', description: '车队' })}
          treeData={depTree}
          loading={depLoading}
          showCheckedStrategy="SHOW_ALL"
          maxTagCount={0}
          maxTagPlaceholder={omittedValues =>
            f(
              { id: 'tx000213', description: '共选择xxx项车队' },
              { value: omittedValues.length, value2: f({ id: 'tx130002' }) },
            )
          }
        />
      ),
    },
    {
      name: 'online',
      dom: (
        <SelectCode
          style={{ width: '1.4rem' }}
          allowClear
          placeholder={s({ id: ['tx060002', 'tx010101'], description: '在线状态' })}
          code="OnlineState"
        />
      ),
    },
  ]

  const [buttonGroup] = useState<ButtonGroupType>({
    showExport: false,
    showColumnSetting: false,
  })

  const [axios] = useState<TableAxiosType>({
    query: getVehiclePage,
  })

  const onTableSelect = (_value: React.ReactText[], record: webAPIResponse.VehiclePage[]) => {
    setSelectValue(record)
  }

  return (
    <Modal
      width={1000}
      {...props}
      bodyStyle={{ height: '55vh' }}
      onOk={() => onOk && onOk(selectValue)}
      wrapClassName="tx-modal-wrap"
      getContainer={false}
      maskStyle={{ position: 'absolute' }}
    >
      <Table
        tableName={tableName}
        columns={columns}
        rowKey="vehicleId"
        axios={props.axios || axios}
        form={form}
        initLoading={true}
        showSerialNumberCol={false}
        searchItems={formItems}
        buttonGroup={buttonGroup}
        deleteFormField={['companyIdsEcho', 'depIdsEcho']}
        rowSelection={{
          onChange: onTableSelect,
        }}
        pagination={{
          pageSizeOptions: ['10', '15', '20', '50', '100'],
        }}
      />
    </Modal>
  )
}

export default memo(PlateNo)
