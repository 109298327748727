/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-12-15 10:30:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-15 11:41:09
 */
import { useEffect, useRef } from 'react';

type Timeout = NodeJS.Timeout

interface Config {
  /** 当前页面是否活跃 */
  isAlive: boolean
  /** setTimeout创建的定时器 */
  timerOfTimeout: Timeout[]
  /** setInterval创建的定时器 */
  timerOfInterval: Timeout[]
}

/** 当前页面是否活跃，并在页面销毁时自动清除挂载到timerOfTimeout和timerOfInterval上的定时器 */
function useIsAlive() {
  const config = useRef<Config>({ isAlive: false, timerOfTimeout: [], timerOfInterval: [] })

  useEffect(
    () => {
      config.current.isAlive = true
      return () => {
        config.current.isAlive = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const { timerOfInterval, timerOfTimeout } = config.current
        const clearTimer = (timer: Timeout[], callback: (timer: Timeout) => void) => timer.forEach(callback)
        clearTimer(timerOfInterval, clearInterval)
        clearTimer(timerOfTimeout, clearTimeout)
        config.current.timerOfTimeout = []
        // eslint-disable-next-line react-hooks/exhaustive-deps
        config.current.timerOfInterval = []
      }
    },
    [],
  )

  return config

}


export default useIsAlive