/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-10-20 18:17:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-21 11:04:40
 */
import api from './index'
import axios from '../axios'
import { webAPIRequest, webAPIResponse } from './index.globals'
import { TableResponseType } from 'components/table/interface';

const Module = '/mapArea'

const Module2 = '/mapAreaBind'

/** 电子围栏分页查询 */
export const mapAreaPage = (data: webAPIRequest.MapAreaPage = {}) => axios.post<TableResponseType<webAPIResponse.MapAreaPage>>(`${api}${Module}/page`, data)

/** 所有电子围栏查询 */
export const getMapAreaAll = (data: webAPIRequest.MapAreaPage = {}) => axios.post<webAPIResponse.MapAreaPage[]>(`${api}${Module}/getMapAreaAll`, data)

/** 所有电子围栏树查询 */
export const getMapAreaAllTree = (data: webAPIRequest.MapAreaPage = {}) => axios.post<webAPIResponse.MapAreaTree[]>(`${api}${Module}/getMapAreaTreeBy`, data)

/** 获取当前用户电子围栏缓存 */
export const getMapAreaAllCache = () => axios.post<string>(`${api}${Module}/getRedisMapAreaByUserId`, {})

/** 新增电子围栏 */
export const addMapArea = (data: webAPIRequest.AddMapArea) => axios.post<boolean>(`${api}${Module}/add`, data)

/** 修改电子围栏 */
export const updateMapArea = (data: webAPIRequest.AddMapArea) => axios.post<boolean>(`${api}${Module}/update`, data)

/** 查询电子围栏详情 */
export const queryMapAreaInfo = (data: { areaId: string }) => axios.post<webAPIResponse.MapAreaInfo>(`${api}${Module}/getMapAreaInfo`, data)

/** 删除电子围栏 */
export const deleteMapArea = (data: webAPIRequest.DeleteMapArea) => axios.post<boolean>(`${api}${Module}/delete`, data)

/** 车辆绑定电子围栏 */
export const queryMapAreapage = (data: webAPIRequest.MapAreaBindPage) => axios.post<TableResponseType<webAPIResponse.MapAreaPage>>(`${api}${Module2}/findMapAreaBindPage`, data)

/** 车辆绑定电子围栏(下发平台) */
export const bindMapAreaVehicle = (data: webAPIRequest.MapAreaBind) => axios.post(`${api}${Module2}/bindMapAreaVehicle`, data)

/** 车辆绑定电子围栏(下发终端) */
export const bindMapAreaVehicleToTerminal = (data: webAPIRequest.MapAreaBind) => axios.post(`${api}${Module2}/send/bindMapArea/toTerminal`, data)

/** 车辆解绑电子围栏(撤回下发) */
export const unbindMapAreaVehicleAll = (data: webAPIRequest.MapAreaBind) => axios.post(`${api}${Module2}/unBindMapAreaVehicleAll`, data)

/** 查询未绑定电子围栏分页列表*/
export const findMapAreaNotBindPage = (data: webAPIRequest.MapAreaBind) => axios.post(`${api}${Module2}/findMapAreaNotBindPage`, data)

