import React, { useEffect } from 'react'
import Chunk from './components/chunk'
import Title from './components/title';
import { Echarts } from 'components'
import echarts from 'echarts/lib/echarts'
import useSetState from 'util/useSetState'
import { find24HrTrendStatisBy } from 'server/web/screen'
import moment from 'moment'
import { useInterval } from 'react-use';

interface MyState {
  series: number[],
  xAxis: Array<string>
}

const defaultAxis = (): Array<string> => {
  const _ = new Array(5).fill(1).map((_item: number, index: number) => {
    const _hour = moment(new Date()).subtract((5 - index), 'hour').hour()
    const hourStr = _hour > 9 ? _hour : '0' + _hour
    return `${hourStr}:00`
  })
  return _
}

const defaultSeries = [0, 0, 0, 0, 0]

function AlarmDistribution() {

  useEffect(() => {
    queryData()
  }, [])

  useInterval(() => {
    queryData()
  }, 300000)

  const queryData = async () => {
    const result = await find24HrTrendStatisBy()
    if (result && result.data.length) {
      const serise = result.data.map((item) => item.alarmNum)
      const xAxis = result.data.map((item) => item.alarmTime) as Array<string>
      setState({
        series: serise ? serise : defaultSeries,
        xAxis: xAxis ? xAxis : defaultAxis()
      })
    }
  }

  const [state, setState] = useSetState<MyState>({
    series: defaultSeries,
    xAxis: defaultAxis()
  })

  return (
    // <div className='tx-large-distri'>
    //   <Chunk>
    //     <div className='large-distri-content'>
    //       <Title title='tx020029' />

    //     </div>
    //   </Chunk>
    // </div>
    <div className='large-distri-echarts'>
      <Echarts
        grid={ {
          left: '3%',
          right: '2%',
          top: '5%',
          bottom: '5%',
          containLabel: true
        } }
        tooltip={ {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          }
        }
        }
        xAxis={ {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            color: '#fff'
          },
          data: state.xAxis
        } }
        yAxis={ {
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(23, 23, 82, 1)'
            }
          },
          axisLabel: {
            color: '#fff'
          }
        } }
        series={ [{
          type: 'line',
          areaStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: `rgba(247,98,98,1)` },
                { offset: 1, color: `rgba(247,98,98,0)` }
              ]
            )
          },
          lineStyle: {
            color: 'rgb(247,98,98, .8)'
          },
          data: state.series
        }] as any }
        style={ {
          width: '100%',
          height: '100%'
        } }
      />
    </div>
  )
}

export default AlarmDistribution
