/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-12-02 19:43:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-16 09:27:49
 */
import api from './index'
import axios from '../axios'

const MODULE = '/user'

export interface userAlarmSetting {
  /** 报警声音提示 */
  alarmSoundsEnabled?: boolean
  /** 报警视频弹窗提示 */
  alarmVideoPopupEnabled?: boolean
  /** 危险车辆提醒信息弹窗提示 */
  dangerVehiclePopupEnabled?: boolean
  /** 实时报警信息弹窗提示 */
  realTimeAlarmPopupEnabled?: boolean
  /** 上级查岗信息弹窗提示 */
  upsCheckPopupEnabled?: boolean
  /** 默认视频码流 */
  streamType?: string
  /** 默认请求音视频 */
  videoDataType?: string
  /** 自动关闭视频 */
  maxAudioListeningTime?: string
  /** 自动关闭监听 */
  maxVideoPreviewTime?: string
  /** 默认地图 */
  mapType?: string
  /** 默认地图LEVEL */
  initZoomLevel?: string
  /** 默认地图中心经度 */
  initLng?: string
  /** 默认地图中心纬度 */
  initLat?: string
  /** 默认地图车标类型 */
  vehicleIconType?: string
  /** 地图车标标签 */
  vehicleLabelType?: string
  /** 实时状态显示类型 */
  displayStateType?: string
  /** 默认视频通道数 */
  videoChannelNum?: string
}

/** 获取用户报警设置 */
export const queryUserAlarmSetting = () => axios.post(`${api}${MODULE}/preference/get`)

/** 更新用户报警设置 */
export const updateUserAlarmSetting = (payload: userAlarmSetting) => axios.post<userAlarmSetting>(`${api}${MODULE}/preference/save`, payload)