import React, { useState, memo, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useIntl } from 'react-intl'
import { DrawerChildren } from './interface'
import { Tabs, TabPane, OlMap, OlMarker } from 'components'
import { getAlarmInfo } from 'server/web/alarm'
import { Picture, SendText, Photograph, Monitor, BaseInfo } from './components'
import { webAPIResponse } from 'server/web/index.globals'
import { createDownLoadUrl } from 'util/comm'
import './index.less'

const keyList = ['video', 'picture', 'sendText', 'photograph', 'monitor']

const SecurityDrawer = (props: DrawerChildren) => {
  const { formRef, record, query, payload } = props
  const { formatMessage: f } = useIntl()
  const [result, setResult] = useState<webAPIResponse.AlarmInfo>()

  useEffect(() => {
    const queryData = async () => {
      const method = query || getAlarmInfo
      const res = await method((payload as any) || { id: record?.id })
      if (res && res.data) {
        setResult(res.data)
      }
    }
    queryData().finally(() => formRef?.current?.resetFields())
  }, [formRef, payload, query, record])

  return (
    <>
      {/**
       * 地图模块
       */}
      <div className="map">
        <OlMap
          type=""
          center={[
            result?.longitude || record?.longitude || 0,
            result?.latitude || record?.latitude || 0,
          ]}
        >
          <OlMarker
            position={[
              result?.longitude || record?.longitude || 0,
              result?.latitude || record?.latitude || 0,
            ]}
          />
        </OlMap>
      </div>

      <Row gutter={32} className="body">
        <Col span={11} className="left">
          <Tabs tabBarGutter={32} tabBarStyle={{ height: '.4rem', margin: 0, marginBottom: 8 }}>
            {result?.showType !== '1' && (
              <TabPane
                tab={f({ id: 'tx020013', description: '报警视频' })}
                className="alarmVideo"
                key={keyList[0]}
              >
                {/**
                 * 报警视频模块
                 */}
                <video
                  style={{ width: '100%' }}
                  controls
                  autoPlay
                  src={createDownLoadUrl(result?.accVideo?.filePath)}
                />
              </TabPane>
            )}
            <TabPane
              tab={f({ id: 'tx020014', description: '报警图片' })}
              className="alarmPicture"
              key={keyList[1]}
            >
              {/**
               * 报警图片模块
               */}
              <Picture result={result} />
            </TabPane>
          </Tabs>
        </Col>

        <Col span={13} className="right">
          <Tabs tabBarGutter={32}>
            <TabPane
              tab={f({ id: 'tx020015', description: '下发文本' })}
              className="sendText"
              key={keyList[2]}
            >
              {/**
               * 下发文本模块
               */}
              <SendText record={record} result={result} />
            </TabPane>

            <TabPane
              tab={f({ id: 'tx020021', description: '拍照' })}
              className="photograph"
              key={keyList[3]}
            >
              {/**
               * 拍照模块
               */}
              <Photograph record={record} result={result} />
            </TabPane>
            <TabPane
              tab={f({ id: 'tx120001', description: '监听' })}
              className="monitor"
              key={keyList[4]}
            >
              {/**
               * 监听模块
               */}
              <Monitor record={record} result={result} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>

      {/**
       * 基础信息模块
       */}
      <BaseInfo ref={formRef} record={record} result={result} />
    </>
  )
}

export default memo(SecurityDrawer)
