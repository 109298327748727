import React, { FC, Dispatch, SetStateAction, useState, useEffect } from 'react'
import { Button, Checkbox, Space, message, Form, Input } from 'antd'
import { Modal, SelectCode, FormTabs } from 'components'
import { FormTabsProps } from 'components/formTabs/interface'
import { FormInstance } from 'antd/lib/form'
import { useIntl } from 'react-intl'
import { queryUserAlarmSetting, updateUserAlarmSetting, userAlarmSetting } from 'server/web/userAlarmSetting'
import { handleLayout, pleaseEnter as e, pleaseSelect as s } from "util/comm"
import useBasicCodes from "util/useBasicCodes"
import { userSettingStore } from 'store'
import { observer } from "mobx-react";
import './alarmSetting.less'
import { BetweenValue, isLat, isLon } from 'util/validator'

interface Props {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

interface VisibleControl {
  blockName: string
}

const AlarmSetting: FC<Props> = props => {
  const { visible, setVisible } = props
  const [form] = Form.useForm()
  const { formatMessage: f } = useIntl()
  const [loading, setLoading] = useState(false)


  const { updateSetting } = userSettingStore

  useEffect(() => {
    update()
  }, [])

  /** 统一处理验证时表单数据 */
  const handleFormRef = (form: FormInstance) => {
    if (form) {
      const validateFields = form.validateFields
      form.validateFields = () =>
        new Promise((resolve, reject) => {
          validateFields()
            .then(res => {
              res.videoChannel = Array.isArray(res.videoChannel)
                ? res.videoChannel?.join()
                : res.videoChannel
              resolve(res)
            })
            .catch(reject)
        })
    }
    return form
  }

  const [formRef] = useState(() => handleFormRef(form))

  const [{ data: basicCodes }] = useBasicCodes([
    'StreamType',
    'VideoDataType',
    'VideoTimeClose',
    'MapType',
    'VehicleLabelType',
    'VehicleDisplayState',
    'VideoShowChannel'
  ])

  const config: FormTabsProps['config'] = [
    {
      key: 'alarmSetting',
      title: f({ id: 'tx1805024', description: '报警设置' }),
      forceRender: true,
      form: [
        {
          name: 'realTimeAlarmPopupEnabled',
          valuePropName: 'checked',
          children: (<Checkbox>{ f({ id: 'tx320002', description: '启用实时报警信息弹窗提示' }) }</Checkbox>)
        },
        {
          name: 'alarmVideoPopupEnabled',
          valuePropName: 'checked',
          children: (<Checkbox>{ f({ id: 'tx320003', description: '启用报警视频弹窗提示' }) }</Checkbox>)
        },
        {
          name: 'dangerVehiclePopupEnabled',
          valuePropName: 'checked',
          children: (<Checkbox>{ f({ id: 'tx320004', description: '启用危险车辆提醒信息弹窗提示' }) }</Checkbox>)
        },
        {
          name: 'upsCheckPopupEnabled',
          valuePropName: 'checked',
          children: (<Checkbox>{ f({ id: 'tx320005', description: '启用上级查岗信息弹窗提示' }) }</Checkbox>)
        },
        {
          name: 'alarmSoundsEnabled',
          valuePropName: 'checked',
          children: (<Checkbox>{ f({ id: 'tx320006', description: '启用报警声音提示' }) }</Checkbox>)
        },
      ],
    },
    {
      key: 'videoSetting',
      title: f({ id: 'tx320001', description: '视频设置' }),
      ...handleLayout(8, 16),
      forceRender: true,
      form: [
        {
          label: f({ id: 'tx320007', description: '默认视频码流' }),
          name: 'streamType',
          children: (<SelectCode
            style={ { width: 185 } }
            placeholder={ s({ id: 'tx320007' }) }
            allowClear={ false }
            basicCodesOption={ basicCodes?.StreamType }
            code="StreamType" />)
        },
        {
          label: f({ id: 'tx320008', description: '默认请求音视频' }),
          name: 'videoDataType',
          children: (<SelectCode
            allowClear={ false }
            style={ { width: 185 } }
            placeholder={ s({ id: 'tx320008' }) }
            basicCodesOption={ basicCodes?.VideoDataType }
            code="VideoDataType" />)
        },
        {
          label: f({ id: 'tx320009', description: '自动关闭视频' }),
          name: 'maxAudioListeningTime',
          children: (<SelectCode
            allowClear={ false }
            style={ { width: 185 } }
            placeholder={ s({ id: 'tx320011' }) }
            basicCodesOption={ basicCodes?.VideoTimeClose }
            code="VideoTimeClose" />)
        },
        // {
        //   label: f({ id: 'tx320010', description: '自动关闭监听' }),
        //   name: 'maxVideoPreviewTime',
        //   children: (<SelectCode
        //   allowClear={false}
        //     style={ { width: 185 } }
        //     placeholder={ s({ id: 'tx320011' }) }
        //     basicCodesOption={ basicCodes?.VideoTimeClose }
        //     code="VideoTimeClose" />)
        // },
        {
          label: f({ id: 'tx320020', description: '视频默认通道数' }),
          name: 'videoChannelNum',
          children: (<SelectCode
            allowClear={ false }
            style={ { width: 185 } }
            placeholder={ s({ id: 'tx320020' }) }
            basicCodesOption={ basicCodes?.VideoShowChannel }
            code="VideoTimeClose" />)
        },
      ],
    },
    {
      key: 'mapSetting',
      title: f({ id: 'tx320012', description: '地图设置' }),
      ...handleLayout(8, 16),
      forceRender: true,
      form: [
        {
          label: f({ id: 'tx320013', description: '默认地图' }),
          name: 'mapType',
          required: true,
          children: (<SelectCode
            allowClear={ false }
            style={ { width: 185 } }
            placeholder={ s({ id: 'tx320013' }) }
            basicCodesOption={ basicCodes?.MapType }
            code="MapType" />)
        },
        {
          label: f({ id: 'tx320014', description: '地图车辆标签' }),
          name: 'vehicleLabelType',
          children: (<SelectCode
            style={ { width: 185 } }
            allowClear={ false }
            placeholder={ s({ id: 'tx320014' }) }
            basicCodesOption={ basicCodes?.VehicleLabelType }
            code="VehicleLabelType" />)
        },
        // TODO 当前没这个码表,先不启用 
        // {
        //   label: f({ id: 'tx320019', description: '默认地图车标类型' }),
        //   name: 'vehicleIconType',
        //   children: (<SelectCode
        //     style={ { width: 185 } }
        //     allowClear={false}
        //     placeholder={ s({ id: 'tx320014' }) }
        //     basicCodesOption={ basicCodes?.VehicleIconType }
        //     code="VehicleIconType" />)
        // },
        {
          label: f({ id: 'tx320016', description: '地图初始层级' }),
          name: 'initZoomLevel',
          validateFirst: true,
          rules: [{ required: true }, BetweenValue(4, 18)],
          children: (<Input
            style={ { width: 185 } }
            allowClear={ false }
            placeholder={ e({ id: 'tx320016' }) } />)
        },
        {
          label: f({ id: 'tx320017', description: '默认地图中心经度' }),
          name: 'initLng',
          validateFirst: true,
          rules: [isLon(), { required: true }],
          children: (<Input
            style={ { width: 185 } }
            allowClear={ false }
            placeholder={ e({ id: 'tx320017' }) } />)
        },
        {
          label: f({ id: 'tx320018', description: '默认地图中心纬度' }),
          name: 'initLat',
          validateFirst: true,
          rules: [isLat(), { required: true }],
          children: (<Input
            style={ { width: 185 } }
            allowClear={ false }
            placeholder={ e({ id: 'tx320018' }) } />)
        },
        {
          label: f({ id: 'tx320015', description: '实时状态显示类型' }),
          name: 'displayStateType',
          children: (<SelectCode
            style={ { width: 185 } }
            allowClear={ false }
            placeholder={ s({ id: 'tx320015' }) }
            basicCodesOption={ basicCodes?.VehicleDisplayState }
            code="VehicleDisplayState" />)
        },
      ],
    },
  ]

  /** 取消 */
  const handleCancel = () => {
    setVisible(false)
  }

  /** 保存 */
  const handleSave = async () => {
    const data = await form.validateFields()
    if (data) {
      setLoading(true)
      const res = await updateUserAlarmSetting(data as userAlarmSetting)
      setLoading(false)
      if (res) {
        updateSetting(data)
        handleCancel()
        message.success(f({ id: 'tx000024', description: '操作成功' }))
      }
    }
  }

  const update = async () => {
    const res = await queryUserAlarmSetting()
    if (res) {
      const _data = res.data
      const filterKeys = [
        'streamType',
        'videoDataType',
        'maxVideoPreviewTime',
        'maxAudioListeningTime',
        'videoChannelNum',
        'displayStateType'
      ]
      // TODO 针对numer转string 后期修改SelectCode组件 删除
      for (const key in _data) {
        if (filterKeys.includes(key)) {
          _data[key] = _data[key] !== null ? _data[key].toString() : null
        }
      }
      updateSetting(_data)
      formRef.setFieldsValue(_data)
    }
  }

  return (
    <Modal
      title={ f({ id: 'tx320000', description: '设置' }) }
      visible={ visible }
      onCancel={ handleCancel }
      footer={ false }
      width={ 520 }
      bodyStyle={ {
        padding: '24px 0'
      } }
    >
      <div className="alarm-setting-modal">
        <FormTabs
          loading={ loading }
          config={ config }
          form={ formRef }
          row={ 1 }
          tabsProps={ {
            tabPosition: 'left'
          } }
        />
        <div className="footer">
          <Space>
            <Button onClick={ handleSave } type="primary">{ f({ id: 'tx000001', description: '确定' }) }</Button>
            <Button onClick={ handleCancel }>{ f({ id: 'tx000002', description: '取消' }) }</Button>
          </Space>
        </div>
      </div>
    </Modal>
  );
}

export default observer(AlarmSetting)