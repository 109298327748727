import React, { useEffect, useState } from 'react'
import { GatewayOutlined, InboxOutlined } from '@ant-design/icons';
import { Popover, Tree, Button, Result, Spin } from 'antd';
import { positionStory } from 'store'
import { useObserver } from 'mobx-react'
import { useIntl } from 'react-intl'
import { getMapAreaAllTree } from 'server/web/mapArea';

function FenceTree() {

  const { formatMessage: f } = useIntl()
  const [tree, setTree] = useState<Array<any>>([])
  const [expandedKeys, setExpandedKeys] = useState<Array<string>>([])

  useEffect(() => {
    let headerDom: HTMLDivElement | null = null
    let tabsDom: HTMLDivElement | null = null
    setTimeout(() => {
      headerDom = document.querySelector('.tx-header')
      tabsDom = document.querySelector('.monitor-tree-tab')
      if (headerDom) {
        headerDom.onclick = () => {
          positionStory.updateFenceTreeVisible(false)
        }
      }
      if (tabsDom) {
        tabsDom.onclick = () => {
          positionStory.updateFenceTreeVisible(false)
        }
      }
    }, 0)
    return () => {
      if (headerDom) {
        headerDom.onclick = null
      }
      if (tabsDom) {
        tabsDom.onclick = null
      }
    }
  }, [])

  const okEvent = () => {
    positionStory.queryFenceData(1)
  }

  const cancelEvent = () => {
    positionStory.updateFenceTreeVisible(false)
  }

  const onCheck = (keys: Array<string>) => {
    positionStory.updateFenceTreeCheck(keys)
  }

  const queryTree = async () => {
    positionStory.updateFenceTreeLoading(true)
    const result = await getMapAreaAllTree()
    positionStory.updateFenceTreeLoading(false)
    if (result && result.data) {
      const eKeys: Array<string> = []
      const newArr = result.data.map((item, index: number) => {
        const id = item.areaType + index
        eKeys.push(id)
        return {
          ...item,
          key: id
        }
      })
      setTree(handleTreeData(newArr))
      setExpandedKeys(eKeys)
    }
  }

  const handleTreeData = (data: Array<any>): Array<any> => {
    if (data && data.length) {
      return data.map(item => {
        const obj: any = {
          key: item.areaId || item.key,
          title: item.areaTypeName || item.name
        }
        if (item.list) {
          obj.children = handleTreeData(item.list)
        }
        return obj

      })
    }
    return []
  }

  const onExpand = (keys: Array<string>) => {
    setExpandedKeys(keys)
  }

  return useObserver(() =>
    <Popover
      placement="rightTop"
      trigger="click"
      title={ <span
        style={ {
          padding: '8px 12px',
          color: '#444'
        } }
      >{ f({ id: 'tx1805023', description: '电子围栏' }) }</span> }
      content={ (
        <div className='group-tree-content' >
          <div className='group-tree'>
            {
              positionStory.fenceTreeLoading ?
                <div className='group-tree-loading'>
                  <Spin size='large' />
                </div>
                :
                tree.length ?
                  <Tree
                    checkable
                    showIcon
                    selectable={ false }
                    onCheck={ onCheck }
                    treeData={ tree }
                    checkedKeys={ positionStory.fenceTreeCheck }
                    expandedKeys={ expandedKeys }
                    onExpand={ onExpand }
                  /> :
                  <Result
                    className='tx-fence-tree-noData'
                    icon={ <InboxOutlined /> }
                    title={
                      <span style={ { fontSize: 16 } }>
                        { f({ id: 'tx000107', description: '暂无数据' }) }
                      </span>
                    }
                  />
            }
          </div>
          <div className='group-tree-tool'>
            <Button type='primary' onClick={ okEvent }> { f({ id: 'tx000001' }) } </Button>
            <Button onClick={ cancelEvent }> { f({ id: 'tx000002' }) } </Button>
          </div>
        </div>
      ) }
      visible={ positionStory.fenceTreeVisible }
    >
      <div
        className='tx-fence-tree'
        onClick={ () => {
          queryTree()
          positionStory.updateFenceTreeVisible(true)
        } }
      >
        <GatewayOutlined />
      </div>
    </Popover>
  )
}

export default FenceTree


