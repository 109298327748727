import React, { useState, useRef, useCallback, useEffect } from "react";
import { Button, Checkbox, Input, message, Form as AntdForm } from "antd";
import {
  Table,
  Tabs,
  TabPane,
  Form,
  Select,
  Stretch,
  SelectQuery,
  SelectCode,
  OlMap,
  OlMarker,
  OlInfoWindow,
} from "components";
import Video from "./video";
import { DatePicker } from "components";
import {
  historicalComVideo,
  getResourceListComById,
  sendVideoPlayBackControlWebN,
  sendVideoComControl,
  videoPlayBackHeartBeat,
} from "server/video";
import { ColumnsType, TableResponseType } from "components/table/interface";
import { getVehiclePage } from "server/web/vehicle";
import { webAPIResponse } from "server/web/index.globals";
import useSetState from "util/useSetState";
import { getLangMessage } from "util/comm";
import TimeLine from "./timeLine";
import { TiemLineItemProps } from "./tiemLineItem";
import { uniqBy, isString, debounce } from "lodash-es";
import moment from "moment";
import { useIntl } from "react-intl";
import { monitorStore, positionStory, userSettingStore } from "store";
import { observer } from "mobx-react";
import InforWindowContent from "../components/inforWindowContent";
import { MapTextStyle } from "../comm";
import classnames from "classnames";
import { VideoDownload } from "../rightCommand";
import async from "router/async";

interface PlayBackProps {
  className?: string;
  /** 是否是第三方接入页面 */
  isForeign?: boolean;
}

interface MyState {
  simNo: string;
  plateNo: string;
  passageway: number;
  hide: boolean;
  vehicleNo: string;
  pollList: Array<webAPIResponse.resourceListById>;
  timeline: TiemLineItemProps[] | undefined;
  hasAudio: boolean;
  hasVideo: boolean;
  loading: boolean;
  endTime: number;
  startTime: number;
  url: string;
  visible: boolean;
  disableDay: string;
}

const tabBarStyle = {
  height: ".4rem",
  margin: 0,
  paddingLeft: 16,
  color: "#222",
  backgroundColor: "#fff",
};

function Index(props: PlayBackProps) {
  const [form] = AntdForm.useForm();

  const number = useRef(0);
  const url: any = useRef("");
  const { formatMessage: f } = useIntl();

  const [channeSelectData, setChanneSelectData] = useState([
    {
      value: 99,
      title: getLangMessage({ id: "tx060022", description: "全部通道" }),
    },
  ]);

  const [disable, setDisable] = useState(false);
  const [downloadFileVisible, setDownloadFileVisible] = useState(false);

  const [defaultValue, setDefaultValue] = useState<any>(null);
  const heartBeatTime: any = useRef();

  useEffect(() => {
    return () => {
      cleanHeartBeatTime();
    };
  }, []);

  /**
   * 清除心跳
   */
  const cleanHeartBeatTime = () => {
    if (heartBeatTime.current) {
      window.clearInterval(heartBeatTime.current);
    }
  };

  useEffect(() => {
    if (
      monitorStore.tabsKey === "4" &&
      monitorStore.playbackVehicleInfo &&
      monitorStore.playbackVehicleInfo.plateNo
    ) {
      form.setFieldsValue({
        plateNo: monitorStore.playbackVehicleInfo.plateNo,
        simNo: monitorStore.playbackVehicleInfo.simNo,
      });
      // 获取通道号
      if (monitorStore.playbackVehicleInfo.videoChannelDesc) {
        const videoChannelDescArr =
          monitorStore.playbackVehicleInfo.videoChannelDesc.split(",");
        const videoChannelArr =
          monitorStore.playbackVehicleInfo.videoChannel.split(",");

        // 转换为数据
        const newArr = videoChannelArr.map((pa, index: number) => ({
          value: Number(pa),
          title: videoChannelDescArr[index],
        }));
        setDisable(false);
        newArr.unshift(channeSelectData[0]);
        setChanneSelectData(newArr);
      } else {
        setDisable(true);
        message.warning(f({ id: "tx000218", description: "该车辆无视频通道" }));
      }
    }
  }, [monitorStore.playbackVehicleInfo]);

  useEffect(() => {
    setState({
      visible: monitorStore.playbackVehicleInfo ? true : false,
    });
  }, [monitorStore.playbackVehicleInfo]);

  const columns: ColumnsType<webAPIResponse.resourceListById> = [
    {
      dataIndex: "channelId",
      intlTitle: "tx070006",
      description: "通道号",
      width: 74,
    },
    {
      dataIndex: "action",
      intlTitle: "tx000007",
      description: "操作",
      width: 70,
      fixed: "right",
      render: (_text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setDefaultValue((_pre: any) => {
                return {
                  channel: record?.channelId + "",
                  rangePickerTime: [
                    moment(+record?.startDate).format("YYYY-MM-DD HH:mm:ss"),
                    moment(+record?.endDate).format("YYYY-MM-DD HH:mm:ss"),
                  ],
                };
              });

              setDownloadFileVisible(true);
            }}
          >
            {f({ id: "tx000013", description: "下载" })}
          </Button>
        );
      },
    },
    {
      dataIndex: "startDate",
      intlTitle: "tx000103",
      description: "开始时间",
      width: 180,
      render: (value: string) => {
        return moment(Number(value)).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      dataIndex: "endDate",
      intlTitle: "tx000104",
      description: "结束时间",
      width: 180,
      render: (value: string) => {
        return moment(Number(value)).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      dataIndex: "dataTypeName",
      intlTitle: "tx000104",
      description: "媒体类型",
      width: 100,
    },
    {
      dataIndex: "streamTypeName",
      intlTitle: "tx070002",
      description: "码流类型",
      width: 100,
    },
    {
      dataIndex: "storeTypeName",
      intlTitle: "tx070003",
      description: "存储器类型",
      width: 100,
    },
    {
      dataIndex: "fileLength",
      intlTitle: "tx070004",
      description: "文件大小(KB)",
      width: 120,
    },
  ];

  const [state, setState] = useSetState<MyState>({
    simNo: "",
    url: "",
    plateNo: "",
    passageway: 0,
    hide: false,
    vehicleNo: "",
    pollList: [],
    timeline: [],
    hasAudio: false,
    hasVideo: true,
    startTime: 0,
    endTime: 0,
    loading: false,
    visible: false,
    disableDay: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
  });

  const sessionIdParamRef = useRef<string | null>(null);

  const dataTypeSelect = (keys: any) => {
    setState({
      hasAudio: keys === 2 ? false : true,
    });
  };

  const endTimeDisable = useCallback(
    (current: moment.Moment) => {
      return (
        current &&
        (current < moment(state.disableDay) ||
          current > moment(state.disableDay).add(2, "days") ||
          current > moment().endOf("day"))
      );
    },
    [state.disableDay]
  );

  const items = [
    {
      name: "plateNo",
      dom: (
        <SelectQuery
          query={getVehiclePage}
          handQueryData={(
            result: TableResponseType<webAPIResponse.VehiclePage>
          ) => {
            return result.records.map((item) => ({
              label: item.plateNo,
              value: item.plateNo + "," + item.simNo + "," + item.vehicleId,
            }));
          }}
          queryParam={{
            current: 1,
            size: 10,
          }}
          onSelect={async (value: string) => {
            const arr = value.split(",");
            monitorStore.getVehiclesInfoAjax(arr[2], "4");
          }}
          searchKey="plateNoLike"
          disabled={!!props.isForeign}
          placeholder={f({ id: "tx010001", description: "车牌号" })}
        />
      ),
    },
    {
      name: "startTime",
      dom: (
        <DatePicker
          showTime
          placeholder={f({ id: "tx000103", description: "开始时间" })}
          format="YYYY-MM-DD HH:mm:ss"
          style={{ width: "100%" }}
          disabledDate={(current: any) =>
            current && current > moment().endOf("day")
          }
          onChange={(date: any) => {
            setState({ disableDay: date });
          }}
        />
      ),
    },
    {
      name: "endTime",
      dom: (
        <DatePicker
          showTime
          placeholder={f({ id: "tx000104", description: "结束时间" })}
          format="YYYY-MM-DD HH:mm:ss"
          style={{ width: "100%" }}
          disabledDate={endTimeDisable}
        />
      ),
    },
    {
      name: "channelId",
      dom: (
        <Select
          placeholder={f({ id: "tx070006", description: "通道号" })}
          data={channeSelectData}
        />
      ),
    },
    {
      name: "dataType",
      dom: (
        <SelectCode
          queryTime="useEffect"
          code="VideoResourceType"
          onSelect={dataTypeSelect}
          allowClear={false}
          placeholder={f({ id: "tx070012", description: "资源类型" })}
        />
      ),
    },
    {
      name: "streamType",
      dom: (
        <SelectCode
          queryTime="useEffect"
          code="StreamAvType"
          allowClear={false}
          placeholder={f({ id: "tx070002", description: "码流类型" })}
        />
      ),
    },
    {
      name: "storeType",
      dom: (
        <SelectCode
          queryTime="useEffect"
          code="StoreType"
          allowClear={false}
          placeholder={f({ id: "tx070003", description: "存储器类型" })}
        />
      ),
    },
    {
      name: "simNo",
      hidden: true,
      dom: <Input />,
    },
  ];

  const initialValues: any = {
    simNo: "",
    startTime: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
    endTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    channelId: 99,
    dataType: "2",
    streamType: "1",
    storeType: "1",
  };

  /** 查询 */
  const queryClick = () => {
    form.validateFields().then(async (data: any) => {
      sessionIdParamRef.current = null;
      cleanData();
      if (data.simNo) {
        if (!data.startTime || !data.startTime) {
          return message.warning(
            getLangMessage({ id: "tx000201" }, { value: "tx000102" })
          );
        }

        const startTime = moment(data.startTime);
        const endTime = moment(data.endTime);

        const diffTime = endTime.diff(startTime, "hours");
        if (diffTime > 24) {
          return message.warning(
            f({ id: "tx000219", description: "时间范围不能超过24小时" })
          );
        }

        setState({ loading: true });
        data.channelId = data.channelId === 99 ? 0 : Number(data.channelId);
        data.dataType = Number(data.dataType);
        data.streamType = Number(data.streamType);
        data.storeType = Number(data.storeType);

        const result = await historicalComVideo(data);

        if (result) {
          getPollList(result.data, data.simNo);
        } else {
          setState({ loading: false });
        }
      } else {
        message.warning(
          f(
            { id: "tx000201", description: "请选择车辆" },
            { value: f({ id: "tx010016" }) }
          ),
          3
        );
      }
    });
  };

  /**
   * 请求资源列表
   * @param commandId
   * @param simNo
   */
  const getPollList = (commandId: string, simNo: string) => {
    getResourceListComById({
      commandId,
      simNo,
    }).then((res: any) => {
      if (res.data) {
        setState({ loading: false });
        number.current = 0;
        setState({
          pollList: res.data,
          loading: false,
        });
        handleTimelineData(res.data);
      } else {
        if (number.current <= 60) {
          number.current += 1;
          setTimeout(() => {
            getPollList(commandId, simNo);
          }, 1000);
        } else {
          setState({ loading: false });
          number.current = 0;
        }
      }
    });
  };

  const handleTimelineData = (data: any) => {
    // 返回通道数组
    const fItme = data.map((item: any) => ({ channelId: item.channelId }));
    // 去重
    const uniqItem = uniqBy(fItme, "channelId");
    // 重新组装数组
    const arr = uniqItem.map((item: any) => {
      return {
        channelId: item.channelId,
        data: data
          .filter((selfItem: any) => selfItem.channelId === item.channelId)
          .map((selfItem: any) => ({
            endDate: selfItem.endDate,
            startDate: selfItem.startDate,
          })),
      };
    });

    setState({
      timeline: arr,
    });
  };

  /**
   * table 双击播放事件
   * @param data
   */
  const tableDoubleClick = (data: any) => {
    const sTime = isString(data.startDate)
      ? Number(data.startDate)
      : data.startDate;
    const eTime = isString(data.endDate) ? Number(data.endDate) : data.endDate;
    timeLineClick(sTime, eTime, data.channelId);
  };

  /**
   * 时间chunk点击事件
   * @param startTime
   * @param endTime
   * @param channelId
   */
  const timeLineClick = async (
    startTime: number,
    endTime: number,
    channelId: number
  ) => {
    const data = await form.validateFields();
    if (url.current) {
      // 下发停止指令
      await onDestroy();

      setTimeout(() => {
        getVideoUrl(startTime, endTime, channelId, data);
      }, 350);
    } else {
      getVideoUrl(startTime, endTime, channelId, data);
    }

    setState({
      passageway: channelId,
    });
  };
  /**
   * 获取视频路径
   * @param startTime
   * @param endTime
   * @param channelId
   * @param data
   */
  const getVideoUrl = async (
    startTime: number,
    endTime: number,
    channelId: number,
    formData: any
  ) => {
    await cleanHeartBeatTime();
    const result = await sendVideoPlayBackControlWebN({
      ...formData,
      startTime: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
      channelId,
      times: 0,
      playBackType: 0,
      sessionId: sessionIdParamRef.current ?? null,
    });
    if (result) {
      url.current = result.data.videoList[0].url.includes("?")
        ? result.data.videoList[0].url + "&t=" + new Date().getTime()
        : result.data.videoList[0].url + "?t=" + new Date().getTime();

      sessionIdParamRef.current = result?.data?.sessionId ?? null;

      setState({
        ...formData,
        vehicleNo: formData.plateNo,
        passageway: channelId,
        url: url.current,
        startTime,
        endTime,
      });
      await cleanHeartBeatTime();
      //拿到结果后下发心跳
      heartBeatTime.current = setInterval(() => {
        heartBeatTimeQuery(formData?.simNo ?? "");
      }, 10000);
    } else {
      url.current = "";
      setState({
        url: "",
      });
    }
  };

  const heartBeatTimeQuery = async (simNo: string) => {
    if (simNo) {
      try {
        let res = await videoPlayBackHeartBeat({ simNo: simNo });
      } catch (error) {}
    }
  };
  /** 停止 */
  const onDestroy = () => {
    url.current = "";
    setState({
      url: "",
      endTime: 0,
      startTime: 0,
    });
  };

  //  清除时间轴数据以及table数据
  const cleanData = () => {
    setState({
      timeline: [],
      pollList: [],
    });
  };

  /** */
  // const download = async (record: webAPIResponse.resourceListById) => {
  //   const result = await videoDownload({
  //     ...record,
  //     channel: record.channelId,
  //     vehicleId: monitorStore.playbackVehicleInfo?.vehicleId!,
  //     alarmStatus: '1',
  //     taskCondition: '1',
  //     videoType: record.dataType
  //   })
  //   if (result) {

  //   }
  // }

  const handleVehicleNo = (vehicle: webAPIResponse.vehiclesInfo) => {
    const type = userSettingStore.baseSetting.vehicleLabelType;
    switch (type) {
      case "1": // 显示车牌+车队
        return vehicle.depName
          ? vehicle.plateNo + "-" + vehicle.depName
          : vehicle.plateNo;
      case "2": //显示车牌+simNo
        return vehicle.simNo
          ? vehicle.plateNo + "-" + vehicle.simNo
          : vehicle.plateNo;
      case "3": // 显示车牌+编号
        return vehicle.serialNo
          ? vehicle.plateNo + "-" + vehicle.serialNo
          : vehicle.plateNo;
      default:
        return vehicle.plateNo;
    }
  };

  return (
    <div className={classnames("tx-playback", props.className)}>
      {monitorStore.playbackVehicleInfo?.vehicleId && state.pollList.length ? (
        <VideoDownload
          onCancel={() => {
            setDownloadFileVisible(false);
            setDefaultValue(null);
          }}
          visible={downloadFileVisible}
          record={{ vehicleId: monitorStore.playbackVehicleInfo?.vehicleId }}
          defaultValue={defaultValue}
        />
      ) : null}

      <Stretch>
        <Form form={form} items={items} initialValues={initialValues} />
        <Button
          type="primary"
          loading={state.loading}
          style={{ width: "100%" }}
          onClick={queryClick}
          disabled={disable}
        >
          {f({ id: "tx000006", description: "查询" })}
        </Button>
      </Stretch>
      <div className="tx-playback-top">
        <div className="tx-playback-content">
          <Video
            simNo={state.simNo}
            vehicleNo={state.vehicleNo}
            hasVideo={state.hasVideo}
            hasAudio={state.hasAudio}
            endTime={state.endTime}
            startTime={state.startTime}
            url={state.url}
            onDestroy={onDestroy}
            passageway={state.passageway}
            cleanHeartBeatTime={cleanHeartBeatTime}
          />
        </div>
        <div className="tx-playback-map">
          <OlMap
            center={
              monitorStore.playbackVehicleInfo
                ? [
                    monitorStore.playbackVehicleInfo.longitude,
                    monitorStore.playbackVehicleInfo.latitude,
                  ]
                : undefined
            }
            type={positionStory.mapType}
            updateSize={monitorStore.tabsKey}
          >
            {monitorStore.playbackVehicleInfo ? (
              <OlMarker
                icon={{
                  src:
                    monitorStore.playbackVehicleInfo.icon ||
                    "/images/monitor/gray.png",
                  // src: handleIcon(monitorStore.playbackVehicleInfo),
                  scale: 0.5,
                  size: [28, 28],
                }}
                label={{
                  content: (
                    <div style={MapTextStyle}>
                      {handleVehicleNo(monitorStore.playbackVehicleInfo)}
                    </div>
                  ),
                  offset: [-6, 5],
                }}
                position={[
                  monitorStore.playbackVehicleInfo.longitude,
                  monitorStore.playbackVehicleInfo.latitude,
                ]}
                rotation={monitorStore.playbackVehicleInfo.direction}
                onClick={() => {
                  setState({ visible: true });
                  monitorStore.updatePlaybackVehicleInfo(
                    monitorStore.playbackVehicleInfo
                  );
                }}
              />
            ) : null}
            <OlInfoWindow
              visible={state.visible}
              position={
                monitorStore.playbackVehicleInfo
                  ? [
                      monitorStore.playbackVehicleInfo.longitude,
                      monitorStore.playbackVehicleInfo.latitude,
                    ]
                  : undefined
              }
              offset={[0, -21]}
              autoMove={false}
            >
              <InforWindowContent
                info={monitorStore.playbackVehicleInfo}
                showTool={false}
                onClose={() => {
                  setState({ visible: false });
                }}
              />
            </OlInfoWindow>
          </OlMap>
        </div>
      </div>
      <div className="tx-playback-bottom">
        <Tabs tabBarStyle={tabBarStyle} tabBarGutter={16}>
          <TabPane key="1" tab={f({ id: "tx000162", description: "时间轴" })}>
            <TimeLine
              onClick={debounce(timeLineClick, 500)}
              data={state.timeline}
            />
          </TabPane>
          <TabPane key="2" tab={f({ id: "tx000163", description: "录像文件" })}>
            <Table
              key="baseId"
              tableName="playback-table"
              columns={columns}
              dataSource={state.pollList}
              buttonGroup={false}
              onRow={(record: any) => {
                return {
                  onDoubleClick: () => {
                    tableDoubleClick(record);
                  },
                };
              }}
              size="small"
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default observer(Index);
