import React, { useEffect, useState } from 'react'
import { Table } from 'components'
import { ColumnsType } from 'components/table/interface'
import { Button } from 'antd'
import { useIntl } from 'react-intl'
import { getMonitorAlarmPage } from 'server/web/alarm'
import { positionStory, tableStore } from 'store'
import { observer } from 'mobx-react'
import { lnglatAmap, lnglatBmap } from 'util/lnglat'

interface AlarmDataTableProps {
  handleClick: (record: any) => void
}

function AlarmDataTable(props: AlarmDataTableProps) {
  const { formatMessage: f } = useIntl()
  const tableName = 'AlarmDataTable'
  const [lngValue, setLngValue] = useState<any>()
  const [loadingState, setLoadingState] = useState<boolean>(false)

  const [columns] = useState<ColumnsType>([
    {
      intlTitle: 'tx010001',
      dataIndex: 'plateNo',
      description: '车牌号',
      width: 140
    },
    {
      intlTitle: 'tx010002',
      dataIndex: 'depName',
      description: '车组',
      width: 120
    },
    {
      intlTitle: 'tx010003',
      dataIndex: 'plateColor',
      description: '颜色',
      width: 100
    },
    {
      intlTitle: 'tx020002',
      dataIndex: 'alarmTypeName',
      description: '报警类型',
      width: 120
    },
    {
      intlTitle: 'tx020001',
      dataIndex: 'alarmSourceName',
      description: '报警来源',
      width: 140
    },
    {
      intlTitle: 'tx020003',
      dataIndex: 'alarmTime',
      description: '报警时间',
      width: 160
    },
    // {
    //   intlTitle: 'tx020004',
    //   dataIndex: '_',
    //   description: '报警地点',
    //   width: 200,
    //   render: (_value, record: any) => {
    //     return <Lnglats lnglat={[Number(record?.longitude), Number(record?.latitude)]} />
    //   }
    // },
    {
      dataIndex: 'lnglatLocation',
      intlTitle: 'tx020004',
      description: '报警地点',
      width: 180,
      ellipsis: true
    },
    {
      intlTitle: 'tx060001',
      dataIndex: 'simNo',
      description: 'SIM卡号',
      width: 120
    },
    {
      dataIndex: 'Action',
      intlTitle: 'tx000007',
      fixed: 'right',
      description: '操作',
      width: 100,
      render: (_value: any, record: any) => {
        return (
          <Button
            type="link"
            style={{ color: '#307CEC' }}
            onClick={() => {
              props.handleClick(record)
            }}
          >
            {
              record.processed === 0 ?
                f({ id: 'tx030001', description: '处理' })
                :
                f({ id: 'tx000010', description: '查看' })

            }
          </Button>
        )
      }
    }
  ])

  useEffect(() => {
    let isCancelled = false;
    tableStore.queryTable(tableName)
    return () => {
      isCancelled = true;
    }
  }, [positionStory.mapType])

  return <Table
    tableName= {tableName}
    showPaginationRefresh
    columns={columns}
    size="small"
    buttonGroup={false}
    pollingTime={positionStory.tablePollTime}
    loading={loadingState}
    axios={{
      query: getMonitorAlarmPage,
      queryCallback: async (data: any) => {
        const { records } = data
        if (records && records.length > 0) {
          setLoadingState(true)
          const lngRecords = await Promise.all(records.map(async (item: any) => {
            return {
              ...item,
              lnglatLocation: positionStory.mapType == 'amap' ?
                await lnglatAmap(item?.longitude, item?.latitude)
                : await lnglatBmap(item?.longitude, item?.latitude)
            }
          }))
          setLngValue(lngRecords)
        }else{
          setLngValue([])
        }
        setLoadingState(false)
      }
    }}
    dataSource={lngValue}
  />
}

export default observer(AlarmDataTable)
