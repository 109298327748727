/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-09-03 09:25:40
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-15 11:46:27
 */
/** 报警督办 */

import api from './index'
import axios from '../axios'
import { TableResponseType } from 'components/table/interface';
import { webAPIRequest, webAPIResponse, PageProps } from './index.globals'

const MODULE = '/supervise'

/** 综合监控-报警督办列表 */
export const getAlarmSupervisePage = (data: PageProps) => axios.post<TableResponseType<webAPIResponse.alarmSupervise>>(`${api}${MODULE}/findAlarmSupervisePage`, data)

/** 综合监控-报警督办详情 */
export const getAlarmSuperviseInfo = (data: webAPIRequest.alarmSuperviseInfo) => axios.post<webAPIResponse.alarmSuperviseInfo>(`${api}${MODULE}/findAlarmSuperviseInfo`, data)

/** 综合监控-报警督办处理 */
export const updateAlarmSupervise = (data: webAPIRequest.alarmSuperviseUpdate) => axios.post<any>(`${api}${MODULE}/updateAlarmSupervise`, data)