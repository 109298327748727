/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-09-28 19:40:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-07 11:22:54
 */
import { useState, useEffect } from 'react';
import { getCompanyList } from 'server/web/company'
import { webAPIRequest } from 'server/web/index.globals';
import { arrayToTree, handleTreeData } from 'util/comm'
import { BasicCode } from 'server/web/basicCode'
import { QueryState, UseAxiosHook } from './interface';

type Padload = webAPIRequest.CompanyTree
type Result = BasicCode[]

const useCompanyTree: UseAxiosHook<Padload, Result> = (padload, callback) => {
  const [param, setParam] = useState(padload)
  const [state, setState] = useState<QueryState<Result>>({
    data: undefined,
    loading: false,
    success: false
  });

  useEffect(() => {
    const queryCompanyList = async () => {
      setState((state) => ({ ...state, loading: true, success: false }))
      const res = await getCompanyList(param)
      setState((state) => ({ ...state, loading: false }))
      if (res) {
        /** 过滤isLeaf属性，该属性会干扰TreeSelect组件正常显示 */
        res.data?.forEach(item => item?.isLeaf && delete item.isLeaf)
        const data = handleTreeData(arrayToTree(res.data, 'id', 'parentId'), { title: 'name', key: 'id' })
        setState(state => ({
          ...state, data, success: true
        }))
        callback && callback(data)
      } else {
        setState(state => ({
          ...state, success: false
        }))
      }
    }
    queryCompanyList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  return [state, setParam]
}

export default useCompanyTree