import React from 'react'
import { Button, Space } from 'antd'
import { useIntl } from 'react-intl'
import './index.less'

export interface DrawerFooterProps {
  loading?: boolean
  okText?: string
  cancelText?: string
  onOk?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onCancel?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  showOk?: boolean
  showCancel?: boolean
}

const Footer = (props: DrawerFooterProps) => {
  const { formatMessage: f } = useIntl()
  const {
    loading,
    okText = f({ id: 'tx000001', description: '确定' }),
    cancelText = f({ id: 'tx000002', description: '取消' }),
    onOk,
    onCancel,
    showOk,
    showCancel
  } = props
  return (
    <div className="tx-drawer-body-footer">
      <Space size={ 10 }>
        {
          showOk !== false ?
            <Button loading={ loading } type="primary" onClick={ onOk }>
              { okText }
            </Button>
            : null
        }
        {
          showCancel !== false ?
            <Button onClick={ onCancel }>{ cancelText }</Button>
            : null
        }
      </Space>
    </div>
  )
}

export default Footer
