import React, { FC, useState, useEffect } from 'react'
import { Button, Radio, List, Tooltip, message } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { Modal } from 'components'
import { useIntl } from 'react-intl'
import { getIssueContent, delIssueContent } from 'server/web/dispatch'
import { webAPIResponse } from 'server/web/index.globals'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { useAxios } from 'util/hook'

const { Group } = Radio
const { Item } = List

type Record = webAPIResponse.IssueContent

interface CommonTextModalProps extends Omit<ModalProps, 'onOk'> {
  onOk?: (record?: Record) => void
}

const CommonTextModal: FC<CommonTextModalProps> = props => {
  const { onOk, visible, ...extra } = props
  const { formatMessage: f } = useIntl()
  const [radioValue, setRadioValue] = useState<Record>()
  const [deleteId, setDeleteId] = useState<string>()

  const [{ data: dataSource, loading }, queryContent] = useAxios({
    axios: getIssueContent,
  })

  const [, deleteContent] = useAxios({
    axios: delIssueContent,
    defaultStart: false,
    onFinally: () => setDeleteId(undefined),
  })

  useEffect(() => {
    if (visible) {
      queryContent()
    }
  }, [queryContent, visible])

  const onChange = (e: RadioChangeEvent) => {
    const { value } = e.target
    setRadioValue(value)
  }

  const onDelete = async (id: string) => {
    setDeleteId(id)
    await deleteContent({ payload: { id } })
    message.success(f({ id: 'tx000024', description: '操作成功' }))
    queryContent()
  }

  return (
    <Modal
      title={f({ id: 'tx020018', description: '常用文本' })}
      visible={visible}
      onOk={() => onOk && onOk(radioValue)}
      bodyStyle={{
        padding: 12,
        height: 300,
        overflow: 'auto',
      }}
      {...extra}
    >
      <Group onChange={onChange} style={{ width: '100%' }}>
        <List
          size="small"
          loading={loading}
          dataSource={dataSource}
          renderItem={item => (
            <Item
              key={item.id}
              actions={[
                <Button
                  type="link"
                  danger
                  key="delete"
                  loading={deleteId === item.id}
                  onClick={() => onDelete(item.id)}
                >
                  {f({ id: 'tx000004', description: '删除' })}
                </Button>,
              ]}
            >
              <div className="span">
                <Radio value={item} />
                <Tooltip title={item.issueContent} placement="topLeft">
                  <span>{item.issueContent}</span>
                </Tooltip>
              </div>
            </Item>
          )}
        />
      </Group>
    </Modal>
  )
}

export default CommonTextModal
