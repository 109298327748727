/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-09-27 18:22:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-15 11:46:49
 */
import api from './index'
import axios from '../axios'
import { webAPIResponse } from './index.globals'

const MODULE = '/api/vehiclescores'

/** 不分页危险车辆 */
export const getVehiclescoresDanger = () => axios.post<webAPIResponse.vehiclescoresDanger[]>(`${api}${MODULE}/statistic/danger`, {})