import React from 'react'
import Chunk from '../components/chunk'
import Title from '../components/title';
import ContentTotal from '../components/contentTotal'
import { useIntl } from 'react-intl'

interface RealTimeVolumeProps {
  /** 完成总方量 */
  accomplishTotalVolumen: number
  /** 运输中方量 */
  carriageTtotalVolumen: number
  /** 预计总方量 */
  planTotalVolumen: number
}

function RealTimeVolume(props: Partial<RealTimeVolumeProps>) {

  const { formatMessage: f } = useIntl()

  return (
    <div className='tx-large-vehicle-total'>
      <Chunk>
        <div className='large-vehicle-total'>
          <Title value={ f({ id: 'tx000142', description: '实时方量数据' }) } />
          <div className='top-title'>
            <div className='top-title-name'>{ f({ id: 'tx000150', description: '预计总方量' }) } </div>
            <Title value={ props.planTotalVolumen || 0 } />
          </div>
          <div>
            <div className='content-total-data' >
              <div className='content-total-item'>
                <ContentTotal title='tx000151' value={ props.accomplishTotalVolumen || 0 } icon='icon_zongfangliang_01' />
              </div>
              <div className='content-total-item'>
                <ContentTotal title='tx000152' value={ props.carriageTtotalVolumen || 0 } icon='icon_yunshufangliang_01' />
              </div>
            </div>
          </div>
        </div>
      </Chunk>
    </div>
  )
}

export default RealTimeVolume
