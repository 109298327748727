import React, { useEffect, useState } from 'react'
import { Table } from 'components'
import { ColumnsType } from 'components/table/interface'
import { Button, Modal } from 'antd'
import { useIntl } from 'react-intl'
import { getLangMessage, createDownLoadUrl } from 'util/comm'
import { getMediaPage } from 'server/web/accessory';
import { positionStory, tableStore } from 'store';
import { lnglatAmap, lnglatBmap } from 'util/lnglat'

interface MediaFilesTableProps {

}

interface MediaFilesItem {
  mediaType: string
  path: string
}

function MediaFilesTable(_props: MediaFilesTableProps) {
  const { formatMessage: f } = useIntl()
  const [visible, setVisible] = useState<boolean>(false)
  const [mediaInfo, setMediaInfo] = useState<MediaFilesItem>({
    mediaType: '',
    path: ''
  })
  const tableName = 'positionAccessory'
  const [lngValue, setLngValue] = useState<any>()
  const [loadingState, setLoadingState] = useState<boolean>(false)

  const [columns] = useState<ColumnsType>([
    {
      dataIndex: 'Action',
      intlTitle: 'tx000007',
      description: '操作',
      width: 140,
      render: (_value: any, record: any) => {
        return (
          <>
            <Button type="link"
              onClick={() => { showMedia(record) }}>
              {f({ id: 'tx000010', description: '查看' })}</Button>
            <Button
              type="link"
              onClick={() => {
                createDownLoadUrl(record.filePath, true)
              }}
            >{f({ id: 'tx000013', description: '下载' })}</Button>
          </>
        )
      }
    },
    {
      intlTitle: 'tx010001',
      dataIndex: 'plateNo',
      description: '车牌号',
      width: 140
    },
    {
      intlTitle: 'tx010002',
      dataIndex: 'depName',
      description: '车组',
      width: 140
    },
    {
      intlTitle: 'tx060011',
      dataIndex: 'mediaTypeName',
      description: '多媒体类型',
      width: 140
    },
    {
      intlTitle: 'tx070006',
      dataIndex: 'channelId',
      description: '通道号',
      width: 100
    },
    {
      intlTitle: 'tx070007',
      dataIndex: 'eventCodeName',
      description: '事件类型',
      width: 140
    },
    {
      intlTitle: 'tx000102',
      dataIndex: 'createDate',
      description: '时间',
      width: 160
    },
    // {
    //   intlTitle: 'tx000106',
    //   dataIndex: '_',
    //   description: '地址',
    //   width: 200,
    //   render: (_value, record: any) => {
    //     return <Lnglats lnglat={[Number(record?.longitude), Number(record?.latitude)]} />
    //   }
    // }
    {
      dataIndex: 'lnglatLocation',
      intlTitle: 'tx000106',
      description: '地址',
      width: 180,
      ellipsis: true
    },
  ])

  const showMedia = async (record: any) => {
    if (record.filePath) {
      setVisible(true)
      if (record.filePath) {
        setMediaInfo({
          mediaType: record.mediaType,
          path: createDownLoadUrl(record.filePath, false)
        })
      }
    }
  }

  useEffect(() => {
    let isCancelled = false;
    tableStore.queryTable(tableName)
    return () => {
      isCancelled = true;
    }
  }, [positionStory.mapType])

  return (
    <div style={{ height: '100%' }}>
      <Modal
        title={getLangMessage(
          { id: 'tx000100', description: '媒体文件信息' },
          { value: 'tx020026', value2: 'tx010015' }
        )}
        width={500}
        onCancel={() => {
          setVisible(false)
        }}
        visible={visible}
        footer={null}
      >
        {
          mediaInfo.mediaType === '' ? null :
            mediaInfo.mediaType === '0' ?
              <img src={mediaInfo.path} alt='' width={450} />
              :
              mediaInfo.mediaType === '1' ?
                <audio src={mediaInfo.path} controls={true}></audio>
                :
                <video src={mediaInfo.path} controls={true}></video>
        }
      </Modal>
      <Table
        tableName={tableName}
        showPaginationRefresh
        columns={columns}
        size="small"
        buttonGroup={false}
        pollingTime={positionStory.tablePollTime}
        loading={loadingState}
        axios={{
          query: getMediaPage,
          queryCallback: async (data: any) => {
            const { records } = data
            if (records && records.length > 0) {
              setLoadingState(true)
              const lngRecords = await Promise.all(records.map(async (item: any) => {
                return {
                  ...item,
                  lnglatLocation: positionStory.mapType == 'amap' ?
                    await lnglatAmap(item?.longitude, item?.latitude)
                    : await lnglatBmap(item?.longitude, item?.latitude)
                }
              }))
              setLngValue(lngRecords)
            }else{
              setLngValue([])
            }
            setLoadingState(false)
          }
        }}
        dataSource={lngValue}
      />
    </div>
  )
}

export default MediaFilesTable
