/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-11-16 21:55:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 18:19:39
 */
import React, { useEffect } from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Tabs, TabPane } from 'components'
import PostionDataTable from './postionDataTable'
import RealSpeedDataCharts from './realSpeedDataCharts';
import PoliceDataTable from './policeDataTable'
import Stretch from './stretch'
import { useObserver, observer } from 'mobx-react';
import Map from './map'
import { monitorStore, positionStory, tableStore, trajectoryStory } from 'store';
import { findAllTrackList } from 'server/web/trackList';
import { useIntl } from 'react-intl'
import './index.less'

const tabBarStyle: React.CSSProperties = {
  height: '.4rem',
  margin: 0,
  paddingLeft: 16,
  color: '#222'
}

interface TrajectoryProps {
  isForeign?: boolean
  /** 类型 app、设备 */
  TerminalType?: string
}

function Index(props: TrajectoryProps) {

  const { formatMessage: f } = useIntl()

  useEffect(() => {
    if (props.TerminalType === 'app' && monitorStore.vehicleInfo.plateNo) {
      queryApp()
    }
  }, [props.TerminalType && monitorStore.vehicleInfo])

  const queryApp = async () => {
    const result = await findAllTrackList({
      plateNo: monitorStore.vehicleInfo.plateNo,
      begTime: monitorStore.vehicleInfo.begTime!,
      endTime: monitorStore.vehicleInfo.endTime!
    })

    if (result) {
      monitorStore.updateTrajectPlayPositionData(result.data.map(item => ([item.longitudePrimeval, item.latitudePrimeval])))
    }
  }

  return useObserver(() =>
    <div className='tx-trajectory'>
      {
        props.TerminalType !== 'app' ?
          <Stretch isForeign={props.isForeign} />
          : null
      }
      <Map />
      {
        props.TerminalType !== 'app' ?
          <div className='tx-trajectory-tabs'>
            <Tabs
              tabBarStyle={tabBarStyle}
              defaultActiveKey='1'
              tabBarGutter={16}

            >
              <TabPane key='1' tab={f({ id: 'tx010062', description: '位置数据' })}>
                <PostionDataTable />
              </TabPane>
              <TabPane key='2' tab={f({ id: 'tx010063', description: '实时速度' })}>
                <RealSpeedDataCharts />
              </TabPane>
              <TabPane key='3' tab={f({ id: 'tx020025', description: '报警数据' })} forceRender={true}>
                <PoliceDataTable />
              </TabPane>
            </Tabs>
          </div>
          : null
      }
      <Spin
        wrapperClassName='tx-trajectory-loading'
        spinning={trajectoryStory.drawLoading}
        tip={f({ id: 'tx0001100', description: '加载中' })}
        indicator={<LoadingOutlined style={{ fontSize: 80 }} />}
      >
      </Spin>
    </div>
  )
}

export default observer(Index)
