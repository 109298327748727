import React, {Dispatch, FC, memo, SetStateAction, useEffect, useRef, useState} from 'react'
import {Table, Modal, DatePicker, TreeSelect} from 'components'
import {Button, Input, message, Radio} from "antd";
import {useIntl} from "react-intl";
import {FormInstance} from "antd/lib/form";
import {ColumnsType, TableAxiosType} from 'components/table/interface'
import {getLocalJson, pleaseEnter as e, pleaseSelect as s} from "../../util/comm";
import {PlateNoModal} from "../../view/queryCenter/components"
import {getExpiredServicePage, VehicleRenewRecord as Record} from 'server/web/renew'
import Add from './renewListAdd'
import moment from "moment";
import useCompanyTree from "../../util/useCompanyTree";

interface Props {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
}

const RenewList: FC<Props> = (props) => {
  const {visible: selfVisible, setVisible: setSelfVisible} = props
  const {formatMessage: f} = useIntl()
  const form = useRef<FormInstance>()
  const [addVisible, setAddVisible] = useState(false)
  const [plateNoModalVisible, setPlateNoModalVisible] = useState(false)

  const userInfo: any = getLocalJson('tx_userInfo')
  const companyId = userInfo.orgId

  const initialSelectedRow: Record[] = []
  const [selectedRow, setSelectedRow] = useState(initialSelectedRow)
  // 增加selectedVehicles来保存弹窗的时候被选择的车辆，因为如果直接用selectedRow的话，
  // 点击操作里的续时的时候，会需要改变selectedRow，下一次直接点击批量续时，就和复选框不同步了
  const [selectedVehicles, setSelectedVehicles] = useState(initialSelectedRow)

  // 载入企业树
  const [
    {data: companyTree, loading: companyTreeLoading, success: companyTreeSuccess},
  ] = useCompanyTree({companyName: ''})

  const columns: ColumnsType<Record> = [
    {
      dataIndex: 'plateNo',
      intlTitle: 'tx010001',
      description: '车牌号',
      width: 140,
    },
    {
      dataIndex: 'simNo',
      intlTitle: 'tx060019',
      description: '终端手机号',
      width: 140,
    },
    {
      dataIndex: 'companyName',
      intlTitle: 'tx130001',
      description: '所属企业',
      width: 160,
    },
    {
      dataIndex: 'depName',
      intlTitle: 'tx010002',
      description: '车队',
      width: 160,
    },
    {
      dataIndex: 'statusDesc',
      intlTitle: 'tx18050613',
      description: '服务到期状态',
      width: 160,
    },
    {
      dataIndex: 'remainTime',
      intlTitle: 'tx18050614',
      description: '剩余/已到期天数',
      width: 160,
    },
    {
      dataIndex: 'endTime',
      intlTitle: 'tx18050615',
      description: '服务到期日期',
      width: 160,
    },
    {
      dataIndex: 'installTime',
      intlTitle: 'tx18050616',
      description: '安装日期',
      width: 160,
    },
    {
      dataIndex: 'Action',
      intlTitle: 'tx000007',
      description: '操作',
      width: 160,
      fixed: 'right',
      render: (_text, record, _index) => (
        record.companyId === companyId && <Button
          type="link"
          size="small"
          onClick={() => {
            console.log(record)
            setSelectedVehicles([record])
            setAddVisible(true)
          }}
        >
          {f({id: 'tx18050611', description: '续时'})}
        </Button>
      )
    },
  ]

  const [dateRadioValue, setDateRadioValue] = useState<number | undefined>(undefined)

  const formItems = [
    {
      name: 'companyIds',
      dom: (
        <TreeSelect
          placeholder={s({id: 'tx130001'})}
          showSearch
          treeDefaultExpandAll
          dropdownMatchSelectWidth
          treeData={companyTree}
          style={{width: '2.24rem'}}
        />
      ),
    },
    {
      name: 'plateNos',
      dom: (
        <Input
          allowClear
          style={{width: '2.24rem'}}
          placeholder={e({id: 'tx010001', description: '车牌号'})}
        />
      ),
    },
    {
      name: 'expireDate',
      dom: <DatePicker style={{width: '1.6rem'}} format="YYYY-MM-DD" allowClear={true} onChange={() => {
        form.current?.setFieldsValue({namedForRemoveConsoleError:-1})
      }
      }/>,
      initialValue: moment(new Date()).add(7, 'days').format('YYYY-MM-DD')
    },
    {
      name: 'namedForRemoveConsoleError',
      dom: (
        <Radio.Group onChange={(v) => {
          setDateRadioValue(v.target.value)
        }}>
          <Radio.Button value={4}>{f({id: 'tx18050622', description: '已过期'})}</Radio.Button>
          <Radio.Button value={0}>{f({id: 'tx000124', description: '今天'})}</Radio.Button>
          <Radio.Button value={1}>{f({id: 'tx18050623', description: '近三天'})}</Radio.Button>
          <Radio.Button value={2}>{f({id: 'tx18050624', description: '近七天'})}</Radio.Button>
          <Radio.Button value={3}>{f({id: 'tx18050625', description: '近三十天'})}</Radio.Button>
        </Radio.Group>
      ),
    },
  ]

  useEffect(() => {
    const v = dateRadioValue
    let expireDate
    switch (v) {
      case 0:
        /** 今天 */
        expireDate = moment(new Date()).format('YYYY-MM-DD')
        break
      case 1:
        /** 近三天 */
        expireDate = moment(new Date()).add(3, 'days').format('YYYY-MM-DD');
        break
      case 2:
        /** 近七天 */
        expireDate = moment(new Date()).add(7, 'days').format('YYYY-MM-DD');
        break
      case 3:
        /** 近三十天 */
        expireDate = moment(new Date()).add(30, 'days').format('YYYY-MM-DD');
        break
      default:
        /** 已过期， 选择昨天的日期 */
        expireDate = moment(new Date()).add(-1, 'days').format('YYYY-MM-DD')
    }
    form.current?.setFieldsValue({expireDate})
  }, [dateRadioValue])

  const handleSelfCancel = () => {
    setSelfVisible(false)
  }

  const [axios] = useState<TableAxiosType>({
    query: getExpiredServicePage,
  })

  return (
    <>
      <Modal
        className="renewList"
        title={f({id: 'tx18050620', description: '服务到期列表'})}
        visible={selfVisible}
        centered
        footer={false}
        width={1200}
        // onOk={handleOk}
        onCancel={handleSelfCancel}
        // confirmLoading={loading}
      >
        <div style={{width: '100%', height: 650}}>
          <Table
            tableName="header.renewList"
            initLoading={true}
            initParam={{expireDate: moment(new Date()).add(7, 'days').format('YYYY-MM-DD')}}
            form={form}
            columns={columns}
            deleteFormField={['rangePickerTime']}
            rowKey="vehicleId"
            searchItems={formItems}
            axios={axios}
            extraButtonGroup={{
              customize: {
                afterAdd: (
                  <Button
                    type="default"
                    onClick={() => {
                      let hasChildCompany = false
                      selectedRow.some((item: Record) => {
                        if (item.companyId !== companyId) {
                          message.info('只能选择本企业车辆')
                          hasChildCompany = true
                          return true
                        }
                        return false
                      })
                      if (hasChildCompany) return
                      if (selectedRow.length > 0) {
                        setSelectedVehicles(selectedRow)
                        setAddVisible(true)
                      } else {
                        message.info(
                          f({id: 'tx000201', description: '请选择车辆'}, {value: f({id: 'tx010016'})}),
                        )
                      }
                    }}
                  >
                    {f({id: 'tx18050612', description: '批量续时'})}
                  </Button>
                )
              }
            }}
            rowSelection={{
              onChange: (_selectedRowKeys, selectedRows) => {
                // selectedRows.forEach((item: Record) => {
                //   if (item.companyId !== companyId) {
                //     message.info('只能选择本企业车辆')
                //   }
                // })
                setSelectedRow(selectedRows as Record[])
              },
            }}
          />
          <Add visible={addVisible} setVisible={setAddVisible} vehicles={selectedVehicles}/>
          <PlateNoModal
            destroyOnClose
            title={f({id: 'tx000209', description: '车辆选择'}, {value: f({id: 'tx010016'})})}
            onOk={value => {
              setPlateNoModalVisible(false)
              form.current?.setFieldsValue({
                plateNos: value.map(({plateNo}) => plateNo).join(),
              })
            }}
            visible={plateNoModalVisible}
            onCancel={() => setPlateNoModalVisible(false)}
          />
        </div>
      </Modal>
    </>
  )
}

export default memo(RenewList)