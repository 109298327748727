/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-09-15 17:47:59
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-01 17:33:17
 */
import api, { baseURL } from './index'
import axios from '../axios'
import { webAPIRequest, webAPIResponse } from './index.globals'

const Module = '/screenmax'
const Concrete = '/concrete'

export interface SiteList {
  /** 工地地址 */
  address: string
  /** 区域类型 */
  areaType: string
  /** 纬度 */
  centerLat: string
  /** 经度 */
  centerLng: string
  points: string
  /** 半径 */
  radius: number
  name: string
  /** 0: 搅拌站；1: 工地 */
  type: number
  siteId: string
}
export interface VehiclePosition {
  /** 纬度 */
  latitude: string
  /** 经度 */
  longitude: string
  /** 车牌号 */
  plateNo: string
  /** 车辆分值 */
  score?: string
  /** SIM卡号 */
  simNo?: string
  /**  */
  deliveryStatus?: number
  /**  */
  unloadStatus?: number
  /** 报警 */
  alarmStatus?: number
}

/** 24小时报警分布 */
export const find24HrTrendStatisBy = (data: any = {}) => axios.post<webAPIResponse.find24HrTrendStatisBy[]>(`${api}${Module}/find24HrTrendStatisBy`, data)

/** 车辆实时位置点显示 */
export const findRealTimePositionBy = (data: any = {}) => axios.post<webAPIResponse.findRealTimePositionBy[]>(`${api}${Module}/findRealTimePositionBy`, data)

/** 车辆总数、在线车辆、危险车辆、在线率、危险率、车辆排行TOP5、车队排行TOP5 */
export const findVehicleScreenStatisBy = (data: any = {}) => axios.post<webAPIResponse.findVehicleScreenStatisBy>(`${api}${Module}/findVehicleScreenStatisBy`, data)

/** 根据车辆ID查询视频链接和通道号 */
export const findVideoLinkChannelBy = (data: webAPIRequest.findVideoLinkChannelBy) => axios.post<webAPIResponse.findVideoLinkChannelBy>(`${api}${Module}/findVideoLinkChannelBy`, data)

/** 获取报警视频 */
export const queryAlarmVideo = () => axios.post<webAPIResponse.ScreenVideo[]>(`${api}${Module}/getScreenVideo`, {})

// 商砼大屏
/** 大屏-实时车辆、实时方量、实时供货数据 */
export const findRealDataBy = () => axios.post<webAPIResponse.findRealDataBy>(`${baseURL}${Concrete}/screenMax/findRealDataBy`)
/** 大屏-地图搅拌站运输线数据 */
export const findSiteCarriageRouteBy = () => axios.post<webAPIResponse.findSiteCarriageRouteBy>(`${baseURL}${Concrete}/screenMax/findSiteCarriageRouteBy`)
/** 大屏-实时运货单数据 */
export const findWaybillRealBy = () => axios.post<webAPIResponse.findWaybillRealBy[]>(`${baseURL}${Concrete}/screenMax/findWaybillRealBy`)
/** 大屏-实时供货数据 */
export const findSupplyRealBy = () => axios.post<webAPIResponse.findSiteTaskRealBy[]>(`${baseURL}${Concrete}/screenMax/findSiteTaskRealBy`)