/*
 * @Description: 
 * @Author: shenkaiyao
 * @Date: 2020-10-14 18:31:11
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-07 15:07:10
 */
import api from '.';
import axios from '../axios';
import { TableResponseType } from 'components/table/interface';
import { bucAPIRequest, bucAPIResponse, Id } from './index.globals'

const MODULE = '/user'

export interface ChangePasswordType {
  /** 旧密码 */
  oldPassword: string
  /** 密码 */
  password: string
  /** 重复密码 */
  verifyPassword: string
}


export interface SetPasswordType {
  /** 旧密码 */
  oldPassword: string
  /** 密码 */
  password: string
  /** 重复密码 */
  userid: string
}

interface FindAuthUsers {
  /** 企业id */
  companyId?: string
  /** id */
  id?: string
  /** 一页显示条数 */
  limit?: string
  /** 用户的授权组织 */
  orgIds?: string
  /** 当前页数 */
  page?: string
  /** 是否开启分页 */
  queryByPage?: string
  /** 需要导出的列标题 */
  titleStr?: string
  /** 用户名模糊 */
  userAccountLike?: string
}

interface RelieveAuth {
  /** 授权用户id */
  authUserIds: string[];
  /** 授权组织id */
  authOrgId: string;
}

/** 分页查询组织信息 */
export const queryUserList = (payload: bucAPIRequest.UserList) => axios.post<TableResponseType<bucAPIResponse.UserList>>(`${api}${MODULE}/list`, payload)

/** 通过ID得到用户信息 */
export const queryUserInfo = (payload: Id) => axios.post<bucAPIResponse.UserList>(`${api}${MODULE}/detail`, payload)

/** 新增用户 */
export const addUser = (payload: bucAPIRequest.AddUser) => axios.post<boolean>(`${api}${MODULE}/addUser`, payload)

/** 修改用户信息 */
export const updateUser = (payload: bucAPIRequest.UpdateUser) => axios.post<boolean>(`${api}${MODULE}/update`, payload)

/** 根据ID删除用户信息 */
export const deleteUser = (payload: Id) => axios.post<boolean>(`${api}${MODULE}/delete`, payload)

/** 根据ID修改用户状态为停用 */
export const userModifyDisable = (payload: Id) => axios.post<boolean>(`${api}${MODULE}/modify/state/disable`, payload)

/** 根据ID修改用户状态为正常 */
export const userModifyNormal = (payload: Id) => axios.post<boolean>(`${api}${MODULE}/modify/state/normal`, payload)

/** 用户修改密码 */
export const userChangePassword = (payload: ChangePasswordType) => axios.post<boolean>(`${api}${MODULE}/change/password`, payload)

/** 查询未被授权给该企业的用户信息 */
export const findNonAuthUsersByCompanyId = (payload: FindAuthUsers) => axios.post<bucAPIResponse.UserList[]>(`${api}${MODULE}/findNonAuthUsersByCompanyId`, payload)

/** 查询该企业授权过的用户信息 */
export const findUsersByCompanyId = (payload: FindAuthUsers) => axios.post<bucAPIResponse.UserList[]>(`${api}${MODULE}/findUsersByCompanyId`, payload)

/** 选择企业授权到用户 */
export const authOrgByUserId = (payload: RelieveAuth) => axios.post<boolean>(`${api}${MODULE}/org/auth/orgByuserId`, payload)

/** 解除授权 */
export const relieveAuth = (payload: RelieveAuth) => axios.post<boolean>(`${api}${MODULE}/org/auth/relieveById`, payload)

/** 用户信息-导出 */
export const exportUser = (payload: any) => axios.post<boolean>(`${api}${MODULE}/export`, payload)

/** 用户管理 ==修改密码 */
export const userSetPassword = (payload: SetPasswordType) => axios.post<boolean>(`${api}${MODULE}/set/password`, payload)
