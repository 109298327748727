// 按钮权限
import React, { useMemo } from "react";

import { menuStore } from "store";
export interface AccessProps {
  auth: string; // 匹配的权限字符串
  fallback?: React.ReactNode; // 无权限时的显示，默认无权限不显示任何内容
}

const Access: React.FC<AccessProps> = (props) => {
  const { auth, fallback = null, children } = props;

  const { pathname } = window.location;

  const accessible = useMemo(() => {
    let matchdata: string[] = [];

    foreachTree(menuStore.menu, (item: any) => {
      if (item.path === pathname) {
        matchdata = item?.button ?? [];
      }
    });
    if (matchdata.includes(auth)) {
      return true;
    } else {
      return false;
    }
  }, [location, auth]);

  function foreachTree(
    data: any,
    callback: Function,
    childrenName = "children"
  ) {
    for (let i = 0; i < data.length; i++) {
      callback(data[i]);
      if (data[i][childrenName] && data[i][childrenName].length > 0) {
        foreachTree(data[i][childrenName], callback, childrenName);
      }
    }
  }

  return <> {accessible ? children : fallback}</>;
};

export default Access;
