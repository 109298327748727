/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-10-14 17:23:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 14:26:54
 */
import { action, observable, toJS } from 'mobx';
import { webAPIResponse } from 'server/web/index.globals'
import { getVehiclesInfoPage } from 'server/web/gps'
import { getAttachment } from 'server/web/alarm'
import { getVehiclescoresDanger } from 'server/web/vehiclescoresDanger'
import { getWaybillByIds } from 'server/web/concrete'
import monitorStore from './index'
import { getMapAreaAll, getMapAreaAllCache } from 'server/web/mapArea'
import { cloneDeep, includes, without } from 'lodash-es';
import userSettingStore from 'store/userSetting';
import { querySupervise, SuperviseProps } from 'server/web/jt809';
import { setLocal } from 'util/comm';
import { Position as olPosition } from 'components/ol/interface'
import moment from 'moment'

interface LngLat {
  lng: number
  lat: number
}

interface ActionsProps {
  type: string,
  tips: Array<number>
  key: number
}

class Position {

  /** 实时位置 - 实时数据 */
  @observable plateNoInfos: webAPIResponse.vehiclesInfo[] = []
  /** 实时位置车辆详情 - 用于infoWindow */
  @observable realTimeVehicleInfo: webAPIResponse.vehiclesInfo | null = null
  /** infoWindowVisible */
  @observable infoWindowVisible: boolean = false
  /** 实时位置 - 点击树产生marker */
  @observable vehicleSelectMarker: webAPIResponse.vehiclesInfo | null = null
  /** 下方table轮询时间 */
  @observable tablePollTime: number = 20
  /** 实时数据轮询时间 */
  @observable realTimePollTime: number = 20
  /** table高度 */
  @observable tableHeight: number = 0
  /** 驾驶行为异常数据 */
  @observable abnormalData: webAPIResponse.alarmAttachment[] = []
  /** 地图操作栏 */
  @observable mapTool: string = 'none'
  /** 显示隐藏电子围栏 */
  @observable fenceTreeVisible: boolean = false
  /** 电子围栏loading */
  @observable fenceTreeLoading: boolean = false
  /** 电子围栏checkKeys */
  @observable fenceTreeCheck: Array<string> = []
  /** 电子围栏checkKeys */
  @observable fenceTreeData: Array<any> = []
  /** 运单数据 */
  @observable waybillData: Array<webAPIResponse.WaybillData> = []
  /** 拍照弹窗 */
  @observable photographModalVisible: boolean = false
  /** 点名弹窗 */
  @observable callModalVisible: boolean = false
  /** 区域查车 */
  @observable regionalCarModalVisible: boolean = false
  /** 设置所有地图中心 */
  // @observable mapAllCenter?: LngLat | olPosition = { lng: 109.250399, lat: 34.344602 }
  @observable mapAllCenter?: olPosition = [109.250399, 34.344602]
  /** 设置所有地图中心 */
  @observable mapAllType: string = 'amap'
  /** 设置所有地图等级 */
  @observable mapAllZoom: number = 0
  /** 地图类型 */
  @observable mapType: string = 'amap'
  /** 设置地图中心 */
  @observable mapCenter?: LngLat = undefined
  /** 地图缩放等级 */
  @observable mapZoom: number = 0

  /**右下角Modal */
  @observable tipsArr: Array<number> = []
  /** 上级查岗信息 */
  @observable superviseData: SuperviseProps[] = []

  @observable alarmRef: HTMLVideoElement | null = null

  /** 更新单个实时车辆数据 */
  @action
  updatePlateNoInfo() {
    // 是否有弹窗
    if (this.realTimeVehicleInfo) {
      const info = this.plateNoInfos.find(item => item.vehicleId === this.realTimeVehicleInfo?.vehicleId)
      if (info) {
        this.updateRealTimeVehicleInfo(info)
        // if (this.infoWindowVisible) {
        //   this.updateMapCenter(info)
        // }
      }
    }
    // 是否有选中marker点 
    if (this.vehicleSelectMarker) {
      // 判断marker是否在实时数据列表中
      const info = this.plateNoInfos.find(item => item.vehicleId === this.vehicleSelectMarker?.vehicleId)
      if (info) {
        this.updateSelectMarker(null)
      }
    }
  }

  /** 更新多个实时位置数据 */
  @action
  updatePlateNoInfos = (data: webAPIResponse.vehiclesInfo[], isPoll: boolean = false) => {
    this.plateNoInfos = data

    if (!isPoll) {
      if (data.length) {
        // 定位到最后个位置
        this.updateMapCenter(this.plateNoInfos[this.plateNoInfos.length - 1])
        // 
        this.updateTableHeight(240)
      } else {
        this.updateMapCenter(undefined)
      }
    }
    // 更新实时数据
    this.updatePlateNoInfo()
  }

  /** 减少实时数据 */
  @action
  subPlateNoInfos(keys: Array<string>) {
    this.plateNoInfos = this.plateNoInfos.filter(item => {
      return !keys.some(a => item.vehicleId === a)
    })

    this.waybillData = this.waybillData.filter(item => {
      return !keys.some(a => item.vehicleId === a)
    })

    if (!keys.length) {
      this.plateNoInfos = []
      this.updateInfoWindowVisible(false)
      this.updateTableHeight(0)
    }

    if (this.realTimeVehicleInfo && !this.plateNoInfos.some(item => this.realTimeVehicleInfo!.vehicleId === item.vehicleId)) {
      this.updateInfoWindowVisible(false)
    }
  }

  /** 更新infowindow实时位置车辆详情
   * 是否展示marker
  */
  @action
  updateRealTimeVehicleInfo = (data: webAPIResponse.vehiclesInfo | null, show: boolean = false) => {
    //  更新infoWindow数据
    this.realTimeVehicleInfo = data
    // 判断实时数据中是否有该点击的车辆
    const hasVehicle = this.plateNoInfos.some(item => item.vehicleId === data?.vehicleId)
    if (hasVehicle) {
      // 显示弹窗，不显示marker图标
      show = false
      this.updateSelectMarker(null)
    }

    if (show) {
      this.updateSelectMarker(data)
    }

    if (data && data.longitude && data.latitude) {
      this.updateInfoWindowVisible(true)
    } else {
      this.updateInfoWindowVisible(false)
    }
  }

  /** 轮询实时数据查询 */
  @action
  pollRealTimeTableData() {
    if (this.plateNoInfos.length) {
      this.getRealTimeTableData(this.plateNoInfos.map(item => item.vehicleId).toString(), true)
    }
    if (this.vehicleSelectMarker) {
      this.querySelectMarker()
    }
  }

  /** 轮询运单数据 */
  @action
  pollWaybillData() {
    if (this.plateNoInfos.length) {
      this.getWaybillData(this.plateNoInfos.map(item => item.vehicleId).toString())
    }
  }

  /** 更新点击车辆信息 */
  @action
  async querySelectMarker() {
    // 获取点击车辆详情列表
    const result = await getVehiclesInfoPage({ vehicleIds: this.vehicleSelectMarker!.vehicleId })

    if (result) {
      const vehicleInfo = result.data[0]
      this.updateSelectMarker(vehicleInfo)
      if (this.realTimeVehicleInfo && vehicleInfo.vehicleId === this.realTimeVehicleInfo.vehicleId) {
        this.realTimeVehicleInfo = vehicleInfo
      }
      if (this.infoWindowVisible) {
        this.updateMapCenter(vehicleInfo)
      }
    }
  }

  /** 实时数据 */
  @action
  async getRealTimeTableData(vehicleIds: string, isPoll: boolean = false) {
    // 获取勾选车辆详情列表
    const result = await getVehiclesInfoPage({ vehicleIds })
    if (result) {
      this.updatePlateNoInfos(result.data, isPoll)
    }
  }

  /** 获取 */
  @action
  async getWaybillData(vehicleIds: string) {
    const result = await getWaybillByIds({ vehicleIds })
    if (result) {
      this.waybillData = result.data
    }
  }

  /** 更新树点击marker信息 */
  @action
  updateSelectMarker(data: webAPIResponse.vehiclesInfo | null) {
    this.vehicleSelectMarker = data

  }

  /** 更新地图中心点位 */
  @action
  updateMapCenter(data?: webAPIResponse.vehiclesInfo) {
    this.mapCenter = data && data.longitude ? {
      lng: data.longitude,
      lat: data.latitude
    } : undefined
  }

  /** 更新所有地图中心点位 */
  @action
  updateMapCenterValue(lng: number, lat: number) {
    this.mapCenter = { lng, lat }
  }

  /** 更新地图等级 */
  @action
  updateMapZoom(zoom: number) {
    // console.log(zoom)
    this.mapZoom = zoom
  }

  /** 更新所有地图中心点位 */
  @action
  updateAllMapCenterValue(lng: number, lat: number) {
    this.mapAllCenter = [lng, lat]
    setLocal('mapCenter', [lng, lat])
  }

  /** 更新地图等级 */
  @action
  updateAllMapZoom(zoom: number) {
    this.mapAllZoom = zoom
    setLocal('mapZoom', zoom)
  }

  /** 更新infowindow显示隐藏 */
  @action
  updateInfoWindowVisible(status: boolean) {
    this.infoWindowVisible = status
  }

  /** 更新轮询时间 */
  @action
  updateTablePollTime(time: number) {
    this.tablePollTime = time || 20
  }

  /** 更新实时数据轮询时间 */
  @action
  updateRealTimePollTime(time: number) {
    this.realTimePollTime = time
  }

  /** 更新table高度 */
  @action
  updateTableHeight(value: number) {
    this.tableHeight = value
  }

  /** 报警视频 */
  @action
  async queryAbnormalData() {
    const result = await getAttachment()
    if (result) {
      this.abnormalData = result.data

    }
  }

  /** 危险驾驶 */
  @action
  async queryDangerousData() {
    const result = await getVehiclescoresDanger()
    if (result) {
      // 根据用户设置进行弹窗提示
      monitorStore.updateDangerousVehicleModallist(result.data)
    }
  }

  /** 上级查岗 */
  @action
  async querySuperviseData() {
    let data = {
      startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    }
    const result = await querySupervise(data)
    if (result) {
      // 根据用户设置进行弹窗提示
      this.superviseData = result.data

    }
  }

  /** 打开Modal */
  openModal() {
    if (userSettingStore.baseSetting.realTimeAlarmPopupEnabled) {
      this.addTips(1)
    }
    if (userSettingStore.baseSetting.alarmVideoPopupEnabled) {
      this.addTips(2)
    }
    if (userSettingStore.baseSetting.dangerVehiclePopupEnabled) {
      this.addTips(3)
    }
    if (userSettingStore.baseSetting.upsCheckPopupEnabled) {
      this.addTips(4)
    }
  }

  /** 关闭Modal */
  closeModal() {
    if (!userSettingStore.baseSetting.realTimeAlarmPopupEnabled) {
      this.deleteTips(1)
    }
    if (!userSettingStore.baseSetting.alarmVideoPopupEnabled) {
      this.deleteTips(2)
    }
    if (!userSettingStore.baseSetting.dangerVehiclePopupEnabled) {
      this.deleteTips(3)
    }
    if (userSettingStore.baseSetting.upsCheckPopupEnabled) {
      this.deleteTips(4)
    }
  }

  /** 更新地图操作 */
  @action
  updateMapTool(type: string) {
    this.mapTool = type
  }

  /** 更新显示隐藏电子围栏树 */
  @action
  updateFenceTreeVisible(value: boolean) {
    this.fenceTreeVisible = value
  }
  /** 更新电子围栏Loading */
  @action
  updateFenceTreeLoading(value: boolean) {
    this.fenceTreeLoading = value
  }

  /** 查询电子围栏树 */
  @action
  queryFenceTreeData() {

  }

  /** 查询电子围栏 缓存*/
  @action
  async queryFenceCache() {
    const result = await getMapAreaAllCache()
    if (result && result.data) {
      this.updateFenceTreeCheck(result.data.split(','))
      this.queryFenceData(0)
    }
  }
  /** 查询电子围栏数组
   * @status 判断是是缓存拉取数据 还是选择请求拉取数据
   */
  @action
  async queryFenceData(status: 0 | 1) {
    const ckecks = this.fenceTreeCheck.filter(item => !isNaN(Number(item)))
    const result = await getMapAreaAll({ areaIds: ckecks })
    if (status === 1) {
      this.updateFenceTreeVisible(false)
    }
    if (result && result.data) {

      this.fenceTreeData = result.data.filter(item => {
        if ((item.centerLng && item.centerLat) || item.points) {
          return true
        }
        return false
      })
    }
  }

  /** 电子围栏check */
  @action
  updateFenceTreeCheck(keys: Array<string>) {
    this.fenceTreeCheck = keys
  }

  /** 显示拍照弹窗 */
  @action
  showPhotographModal() {
    this.photographModalVisible = true
  }

  /** 隐藏拍照弹窗 */
  @action
  hidePhotographModal() {
    this.photographModalVisible = false
  }
  /** 显示点名弹窗 */
  @action
  showCallModal() {
    this.callModalVisible = true
  }

  /** 隐藏点名弹窗 */
  @action
  hideCallModal() {
    this.callModalVisible = false
  }
  /** 显示区域查车弹窗 */
  @action
  showRegionalCarModal() {
    this.regionalCarModalVisible = true
  }

  /** 隐藏区域查车弹窗 */
  @action
  hideRegionalCarModal() {
    this.regionalCarModalVisible = false
  }

  /** 地图类型切换 */
  @action
  updateMapType(type: string) {
    this.mapType = type
  }
  
  /** 地图类型切换 */
  @action
  updateAllMapType(type: string) {
    this.mapAllType = type
  }

  /** 新增tip Modal */
  @action
  addTips(key: number) {
    if (!includes(this.tipsArr, key)) {
      this.tipsArr.push(key)
    }
  }

  /** 更新tip Modal状态 */
  @action
  updateTips(key: number) {
    if (includes(this.tipsArr, key)) {
      this.deleteTips(key)
    } else {
      this.addTips(key)
    }
  }

  /** 删除tip Modal*/
  @action
  deleteTips(key: number) {
    this.tipsArr = without(this.tipsArr, key)
  }

  /** 更新alarmRef */
  @action
  updateAlarmRef(element: HTMLVideoElement | null) {
    this.alarmRef = element
  }

  /** 播放音频 */
  playAlarm() {
    if (this.alarmRef && userSettingStore.baseSetting.alarmSoundsEnabled) {
      this.alarmRef.play()
    }
  }
}

const positionStory: Position = new Position()

export { Position }
export default positionStory