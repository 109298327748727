import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import Broadcast from 'view/monitor/broadcast/video'
import { urlSearchParams, setLocal } from 'util/comm';
import { queryVehicleInfoByPlateNo } from 'server/web/vehicle';
import { broadcastStory } from 'store';
import './index.less'
import { useIntl } from 'react-intl';
import { message } from 'antd';

interface PositionPageParams {
  token: string
  plateNo: string
}

function Index() {
  const { formatMessage: f } = useIntl()
  useEffect(() => {

    const params = urlSearchParams<PositionPageParams>(decodeURIComponent((window).location.search))

    if (params.token) {
      setLocal('tx_token', params.token)
    }
    queryVehicleDetailByPlateNo(params.plateNo)
  }, [])

  const queryVehicleDetailByPlateNo = async (plateNo: PositionPageParams['plateNo']) => {

    // 通过车牌号 获取车辆ID
    const result = await queryVehicleInfoByPlateNo({ plateNo: plateNo })
    if (result) {
      if (result.data.vehicleId) {
        const info = result.data
        broadcastStory.doubleClickGetVideo({
          plateNo: info.plateNo!,
          simNo: info.simNo!,
          passway: info.videoChannel ? info.videoChannel.split(',').map(item => Number(item)) : [],
          vehicleId: info.vehicleId,
        })
      } else {
        message.warning(f({ id: 'tx0830001', description: '车辆不存在' }))
      }
    }
  }


  return (
    <div style={ { width: '100%', height: '100%', display: 'flex' } }>
      <Broadcast className='tx-broadcast-content' isForeign={ true } />
    </div>
  )
}

export default observer(Index)
