import React, { useEffect, useState, useCallback } from 'react'
import Chunk from './components/chunk'
import { Video } from 'components'
import { videoItem } from 'components/video/interface';
import { bateTimeAjax, closeVideo, getVideoAjax, closeAllVideo } from 'server/video';
import { observer } from 'mobx-react';
import { cloneDeep } from 'lodash-es';
import AlarmDistribution from './alarmDistribution';
import classnames from 'classnames';

interface VideoProps {
  videoList: Array<videoItem>
  className?: string
  onAllEnded?: () => void
  isTx?: boolean
}

function VideoFC(props: VideoProps) {

  const [videoList, setVideoList] = useState<Array<videoItem>>([])
  const [time, setTime] = useState<number>(0)
  const [videos, setVideos] = useState<Array<number>>([])


  useEffect(() => {
    if (props.videoList) {
      // 关闭所有视频
      setTime(new Date().getTime())
      setVideoList(props.videoList)
    }
  }, [props.videoList])

  /** 心跳监听 */
  const onBateTime = (sessionIds: string[]) => {
    bateTimeAjax({ sessionIds })
  }

  /** 单个视频点击销毁 */
  const destroyClick = (passageway: number, sessionId: string) => {
    closeVideo({
      sessionId: sessionId,
      channelNums: [passageway],
      simNo: ''
    })
  }

  const playClick = async (obj: any) => {
    if (obj.simNo) {

      const result = await getVideoAjax({
        simNo: obj.simNo,
        streamType: '0',
        mediaType: '0',
        channelNums: [obj.passageway]
      })
      if (result) {
        const videoList: Array<videoItem> = result.data.videoList.map((item) => {
          return {
            sim: obj.simNo,
            url: item.url + '?t=' + (new Date()).valueOf(),
            passageway: item.channelId,
            vehicleNo: obj.vehicleNo,
            sessionId: result.data.sessionId
          }
        })
        setVideoList(videoList)
      }
    }
  }

  /** 关闭所有视频 */
  const stopAllVideo = (_videoList: Array<videoItem | null>) => {
    console.log('stopAllVideo');
    // const noNullList = videoList.filter(item => !!item)
    // if (noNullList.length) {
    //   const closeVideo: any = {}
    //   noNullList.forEach(item => {
    //     if (item?.sessionId! in closeVideo) {
    //       closeVideo[item?.sessionId!].push(item?.passageway)
    //     } else {
    //       closeVideo[item?.sessionId!] = [item?.passageway]
    //     }
    //   })

    //   closeAllVideo(closeVideo)
    // }
  }

  const handleEnded = useCallback((index: number) => {
    // 遍历videos，查看是否所以视频都播放完了 
    if (!videos.length || !videos.some((item: number) => item === index)) {
      const newArr = videos
      newArr.push(index)
      setVideos(newArr)
    }

    if (videos.length >= 4) {
      setVideos([])
      if (props.onAllEnded) {
        props.onAllEnded()
      }
    } else {
      const cVideo = cloneDeep(videoList)
      const selfVideUrl = cVideo[index].url
      const urlArr = selfVideUrl?.split('t=')
      if (urlArr) {
        console.log(cVideo);
        cVideo[index].url = urlArr[0] + 't=' + new Date().getTime()
        setVideoList(cVideo)
      }
    }
  }, [videos, videoList])

  return (
    <div className={ classnames('tx-large-video', props.className) }>
      <Chunk>
        <div className='large-video-content'>

          <Video
            type="broadcast"
            mediaType='mp4'
            passageway={ videoList[0] ? videoList[0].passageway : '' }
            vehicleNo={ videoList[0] ? videoList[0].vehicleNo : '' }
            url={ videoList[0] ? videoList[0].url : '' }
            onEnded={ () => { handleEnded(0) } }
            showCapture={ false }
            showPlay={ false }
            showStop={ false }
            isTimeoutTip={ false }
          />
          <Video
            type="broadcast"
            mediaType='mp4'
            passageway={ videoList[1] ? videoList[1].passageway : '' }
            vehicleNo={ videoList[1] ? videoList[1].vehicleNo : '' }
            url={ videoList[1] ? videoList[1].url : '' }
            onEnded={ () => { handleEnded(1) } }
            showCapture={ false }
            showPlay={ false }
            showStop={ false }
            isTimeoutTip={ false }
          />
          <Video
            type="broadcast"
            mediaType='mp4'
            passageway={ videoList[2] ? videoList[2].passageway : '' }
            vehicleNo={ videoList[2] ? videoList[2].vehicleNo : '' }
            url={ videoList[2] ? videoList[2].url : '' }
            onEnded={ () => { handleEnded(2) } }
            showCapture={ false }
            showPlay={ false }
            showStop={ false }
            isTimeoutTip={ false }
          />
          <Video
            type="broadcast"
            mediaType='mp4'
            passageway={ videoList[3] ? videoList[3].passageway : '' }
            vehicleNo={ videoList[3] ? videoList[3].vehicleNo : '' }
            url={ videoList[3] ? videoList[3].url : '' }
            onEnded={ () => { handleEnded(3) } }
            showCapture={ false }
            showPlay={ false }
            showStop={ false }
            isTimeoutTip={ false }
          />
          {
            props.isTx ? <AlarmDistribution /> : null
          }
          {/* <Broadcast
            className={ props.className }
            videoList={ videoList }
            passageway={ 4 }
            showHeaderTool={ false }
            onBateTime={ onBateTime }
            destroyClick={ destroyClick }
            playClick={ playClick }
            stopAllVideo={ stopAllVideo }
            closeVideoNumber={ time }
            isTimeoutTip={ false }
            onEnded={ handleEnded }
            mediaType='mp4'
            hasAudio={ false }
          /> */}
        </div>
      </Chunk>
    </div>
  )
}

export default observer(VideoFC)
