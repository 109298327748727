import React, { useState, memo, FC, useCallback } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { useIntl } from 'react-intl'

export interface TiemLineItemProps {
  channelId: number,
  data: Array<{ endDate: number, startDate: number }>,
  onClick?: (endDate: number, startDate: number) => void
  activeCode?: string
}

function areEqual(prevProps: TiemLineItemProps, nextProps: TiemLineItemProps) {
  return prevProps.data[0] === nextProps.data[0] && prevProps.activeCode === nextProps.activeCode
}

const TiemLineItem: FC<TiemLineItemProps> = memo((props: TiemLineItemProps) => {

  const { formatMessage: f } = useIntl()
  const [hover, setHover] = useState<boolean>(false)
  const onClick = (startDate: number, endDate: number) => {
    if (props.onClick) {
      props.onClick(startDate, endDate)
    }
  }
  const handleLine = useCallback((data: any) => {

    return data.filter((item: any) => (item.endDate - item.startDate > 0 ? true : false)).map((item: any, index: number) => {
      const { startDate, endDate } = item
      // 查询当天时间戳
      const _startDate = Number(startDate)
      const _endDate = Number(endDate)

      // 获取开始时间-hour
      const hour: number = moment(_startDate).hour() * 60 * 60 * 1000
      // 获取开始时间-minute
      const minute: number = moment(_startDate).minute() * 60 * 1000
      // 获取开始时间-second
      const second: number = moment(_startDate).second() * 1000
      // 当天时间范围
      const dayTime = 24 * 60 * 60 * 1000



      // 获取
      const left = ((hour + minute + second) / dayTime) * 100
      const leftCalc = left === 0 ? left : `calc(${left}% - 12px)`
      let width = ((_endDate - _startDate) / dayTime) * 100

      if (left + width >= 100) {
        width = 100 - left
      }


      return <div
        key={ index }
        onClick={ () => { onClick(_startDate, _endDate) } }
        className={ classnames('time-chunk', { 'time-chunk-active': props.activeCode === `${props.channelId}${startDate}` }) }
        style={ {
          left: leftCalc,
          width: width + '%'
        } }></div>
    })
  }, [])

  return (
    <div className={ classnames('tx-time-line-item', {
      'time-line-item-hover': hover
    }) }
      onMouseEnter={ () => { setHover(true) } }
      onMouseLeave={ () => { setHover(false) } }
    >
      <div className='time-line-title time-line-title-color'>
        { f({ id: 'tx070005', description: '通道' }) }{ props.channelId }
      </div>
      <div className='time-lines time-line-margin'>
        {
          handleLine(props.data)
        }
      </div>
      <div className='time-line-after-fill'></div>
    </div>
  )
}, areEqual)

export default TiemLineItem
