import React, { useState, useEffect } from 'react'
import Chunk from '../components/chunk'
import { Map, Marker } from 'components'
import { findSiteCarriageRouteBy } from 'server/web/screen'
import { useInterval } from 'react-use';
import { MapDarkStyle } from 'components/echarts/theme';
import { getLocal, getLocalJson } from 'util/comm';

let firstViwepoert: boolean = false

export interface MarkerProps {
  position: [number, number]
  type?: number
  icon?: {
    image: string
    /** 图标所用的图片的大小 */
    imageSize?: [number, number]
    /** 标所用的图片相对于可视区域的偏移值， */
    imageOffset?: [number, number]
    /** 图标可视区域的大小 */
    size?: [number, number]
    /** 图标的定位点相对于图标左上角的偏移值 */
    anchor?: [number, number]
  }
  deliveryStatus?: number
  unloadStatus?: number
  alarmStatus?: number
  key?: string
}

function MapComponts() {
  const [series, setSeries] = useState<Array<MarkerProps>>([])
  const [siteData, setSiteData] = useState<Array<MarkerProps>>([])
  const [viwepoert, setViwepoert] = useState<Array<[number, number]>>([])
  const [center, setCenter] = useState<{ lng: number, lat: number } | undefined>(undefined)
  const [zoom, setZoom] = useState<number>(0)
  useInterval(() => {
    queryData()
  }, 10000)

  useEffect(() => {
    queryData()
  }, [])

  const queryData = async () => {
    const result = await findSiteCarriageRouteBy()
    if (result) {
      const vehicles = result.data.vehiclePosition.filter(item => item.latitude ? true : false).map(item => {
        return {
          deliveryStatus: item.deliveryStatus,
          unloadStatus: item.unloadStatus,
          alarmStatus: item.alarmStatus,
          position: [Number(item.longitude), Number(item.latitude)] as [number, number],
        }
      })
      const siteDatas = result.data.siteList.filter(item => item.centerLat ? true : false).map(item => {
        return {
          key: item.siteId,
          type: item.type,
          position: [Number(item.centerLng), Number(item.centerLat)] as [number, number],

        }
      })
      setSeries(vehicles)
      setSiteData(siteDatas)
      if (!firstViwepoert && vehicles.length && siteDatas.length) {
        setViwepoert([...vehicles, ...siteDatas].map(item => item.position))
        firstViwepoert = true
      } else {
        setViwepoert([])
      }
    }
  }

  const handleVehicleIcon = (deliveryStatus: number, unloadStatus: number, alarmStatus: number): string => {
    let icon = '/images/large_screen/car-left-green.png'
    if (alarmStatus === 1) { // 报警
      icon = '/images/large_screen/car-left-red.png'
    } else {
      if (deliveryStatus === 2) {
        if (unloadStatus === 1) {
          icon = '/images/large_screen/car-left-orange.png'
        } else if (unloadStatus === 2) {
          icon = '/images/large_screen/car-left-end.png'
        } else {
          icon = '/images/large_screen/car-left-grey.png'
        }
      }
    }
    return icon
  }

  return (
    <div className='tx-large-map'>
      <Chunk>
        <div className='large-map-content'>
          <Map
            type='baidu'
            amapUI={ false }
            theme={ MapDarkStyle }
            viwepoert={ viwepoert }
            zoom={ zoom }
            center={ center }
            onInstanceCreated={ () => {
              if (viwepoert.length) return;
              const center = getLocalJson<number[]>('mapCenter')
              if (center) {
                setCenter({ lng: center[0], lat: center[1] })
              }
              const mapZoom = getLocal('mapZoom')
              if (mapZoom) {
                setZoom(Number(mapZoom))
              }
            } }
          >
            {
              series.length ?
                series.map((item, index: number) => {
                  return <Marker
                    key={ `vehicle_${index}` }
                    icon={ {
                      image: handleVehicleIcon(item.deliveryStatus!, item.unloadStatus!, item.alarmStatus!),
                      size: [18, 18],
                      imageSize: [18, 18],
                      imageOffset: [0, 0]
                    } }
                    position={ item.position }
                  />
                }) : null
            }
            {
              siteData.length ?
                siteData.map(item => {
                  return <Marker
                    key={ item.key }
                    icon={ {
                      image: item.type === 1 ? '/images/large_screen/site.png' : '/images/large_screen/company.png',
                      size: [20, 20],
                      imageSize: [20, 20],
                      imageOffset: [0, 0]
                    } }
                    position={ item.position }
                  />
                }) : null
            }
          </Map>
        </div>
      </Chunk>
    </div>
  )
}

export default MapComponts
