import React from 'react'
import Chunk from '../components/chunk'
import Title from '../components/title';
import ContentTotal from '../components/contentTotal'
import { useIntl } from 'react-intl'

interface RealTimeVehicleProps {
  /** 已派车辆数 */
  dispatchingNum: number
  /** 休息车辆数 */
  restNum: number
  /** 维修车辆数 */
  serviceNum: number
  /** 车辆总数 */
  vehicleNum: number
  /** 预排车辆数 */
  walkthroughNum: number
}

function RealTimeVehicle(props: Partial<RealTimeVehicleProps>) {

  const { formatMessage: f } = useIntl()

  return (
    <div className='tx-large-vehicle-total realtime-vehicle'>
      <Chunk>
        <div className='large-vehicle-total'>
          <Title value={ f({ id: 'tx000141', description: '实时车辆数据' }) } />
          <div className='top-title'>
            <div className='top-title-name'>{ f({ id: 'tx010018', description: '车辆总数' }) } </div>
            <Title value={ props.vehicleNum || 0 } />
          </div>
          <div>
            <div className='content-total-data' >
              <div className='content-total-item'>
                <ContentTotal className='content-total-item-top' title='tx000146' value={ props.walkthroughNum || 0 } icon='icon_yu_01' />
                <ContentTotal title='tx000147' value={ props.restNum || 0 } icon='icon_xiuxi' />

              </div>
              <div className='content-total-item'>
                <ContentTotal className='content-total-item-top' title='tx000148' value={ props.dispatchingNum || 0 } icon='icon_yi_01' />
                <ContentTotal title='tx000149' value={ props.serviceNum || 0 } icon='icon_weixiu' />
              </div>
            </div>
          </div>
        </div>
      </Chunk>
    </div>
  )
}

export default RealTimeVehicle
