import React, { useEffect } from 'react'
import Playback from 'view/monitor/playback';
import { urlSearchParams, setLocal } from 'util/comm';
import { queryVehicleInfoByPlateNo } from 'server/web/vehicle';
import './index.less'
import { monitorStore } from 'store';
import { message } from 'antd';
import { useIntl } from 'react-intl';

interface PositionPageParams {
  token: string
  plateNo: string
}

function PlaybackPage() {
  const { formatMessage: f } = useIntl()
  useEffect(() => {

    const params = urlSearchParams<PositionPageParams>(decodeURIComponent((window).location.search))

    if (params.token) {
      setLocal('tx_token', params.token)
    }
    queryVehicleDetailByPlateNo(params.plateNo)
  }, [])

  const queryVehicleDetailByPlateNo = async (plateNo: PositionPageParams['plateNo']) => {

    // 通过车牌号 获取车辆ID
    const result = await queryVehicleInfoByPlateNo({ plateNo: plateNo })
    if (result) {
      if (result.data.vehicleId) {
        const info = result.data
        monitorStore.getVehiclesInfoAjax(result.data.vehicleId, '4')
        monitorStore.updateVechileInfo({
          plateNo: info.plateNo!,
          simNo: info.simNo!,
          passway: info.videoChannel ? info.videoChannel.split(',').map(item => Number(item)) : [],
          videoChannelDesc: info.videoChannelDesc ? info.videoChannelDesc.split(',') : undefined,
          vehicleId: info.vehicleId,
        })
        monitorStore.updateTabsKey('4')
      } else {
        message.warning(f({ id: 'tx0830001', description: '车辆不存在' }))
      }
    }
  }

  return (
    <div style={ { width: '100%', height: '100%', display: 'flex' } }>
      <Playback className='playback-foreignPage' isForeign={ true } />
    </div>
  )
}

export default PlaybackPage
