import React, { FC, useState, useEffect, useMemo } from 'react'
import { Upload as AntdUpload, message, Image } from 'antd'
import { UploadProps } from './interface'
import { useIntl } from 'react-intl'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload/interface'
import { uploadFileUrl } from 'server/web/fileManager'
import { createDownLoadUrl } from 'util/comm'
import { isString } from 'lodash-es'
import fallback from '../image/defaultFail'

const Upload: FC<UploadProps> = props => {
  const {
    listType = 'picture-card',
    children,
    value,
    onChange,
    max,
    onlyJpgOrPng = true,
    isDetail = false,
  } = props

  const { formatMessage: f } = useIntl()
  const [uploadLoading, setUploadLoading] = useState(false)
  const [fileList, setFileList] = useState<UploadProps['fileList']>([])
  const [uploadButton, setUploadButton] = useState(() => createUploadButton(false))
  /** 详情弹窗 */
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string>()

  useEffect(() => {
    if (isString(value)) {
      const valueList = value.split(',').filter(item => !!item)
      setFileList(
        valueList.map(
          (value, index) =>
            ({
              uid: -index,
              url: createDownLoadUrl(value),
              path: value,
              percent: 100,
              status: 'done',
              name: 'image.png',
            } as any),
        ),
      )
    }
  }, [value])

  useEffect(() => {
    setUploadButton(createUploadButton(uploadLoading))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadLoading, max, fileList])

  function createUploadButton(loading: boolean) {
    const isOverMaxLenght = max && fileList?.length && fileList.length >= max ? true : false
    const dom = (!isDetail || loading) && !isOverMaxLenght && (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        {!isDetail && (
          <div style={{ marginTop: 8 }}>
            {f({ id: 'tx000215', description: '点击上传' }, { value: '' })}
          </div>
        )}
      </div>
    )
    return dom
  }

  const beforeUpload = (file: RcFile) => {
    /**
     * 文件大小验证：最大为10M
     * @param isOpen 是否开启验证
     */
    const sizeVerification = (isOpen: boolean) => {
      if (!isOpen) return true
      const maxSize = 10485760
      const flag = file.size < maxSize
      if (!flag) message.warning(f({ id: 'tx08_2', description: '上传文件最大为10M' }))
      return flag
    }

    /**
     * 文件格式验证
     * @param isOpen 是否开启验证
     */
    const formatVerification = (isOpen: boolean) => {
      if (!isOpen) return true
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng)
        message.warning(f({ id: 'tx000216', description: '只能上传jpg或png格式的文件' }))
      return isJpgOrPng
    }

    return sizeVerification(true) && formatVerification(onlyJpgOrPng)
  }

  return (
    <>
      <AntdUpload
        action={uploadFileUrl}
        name="uploadFile"
        listType={listType}
        fileList={fileList}
        disabled={uploadLoading}
        beforeUpload={beforeUpload}
        showUploadList={{
          showDownloadIcon: true,
          showRemoveIcon: !isDetail,
        }}
        onPreview={file => {
          setPreviewVisible(true)
          setPreviewUrl(file.url)
        }}
        {...props}
        onChange={info => {
          const { file, fileList } = info
          const valueList = fileList
            .map((file: any) => {
              if (file.response && file.status !== 'error') {
                const { path } = file.response.data
                file.path = path
                return path
              }
              return file.path
            })
            .filter(item => !!item)
          setUploadLoading(file.status === 'uploading')
          if (file.status !== 'uploading') {
            onChange && onChange(valueList.join())
          }
        }}
      >
        {children || (listType === 'picture-card' ? uploadButton : null)}
      </AntdUpload>
      <Image
        width={0}
        src={previewUrl}
        fallback={fallback}
        preview={useMemo(
          () => ({
            visible: previewVisible,
            onVisibleChange: setPreviewVisible,
          }),
          [previewVisible],
        )}
      />
    </>
  )
}

export default Upload
