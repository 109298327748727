import React, { useEffect, useState } from 'react'
import { Table } from 'components'
import { ColumnsType } from 'components/table/interface'
import { positionStory, tableStore, trajectoryStory } from 'store'
import { observer, useObserver } from 'mobx-react';

function PositionDataTable() {
  const color1 = '#f5222d'
  const color2 = '#000'
  const tableName = 'trajectoryPositionDataTable'

  const [columns] = useState<ColumnsType>([
    {
      intlTitle: 'tx010001',
      dataIndex: 'plateNo',
      description: '车牌号',
      width: 120,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx010002',
      dataIndex: 'depName',
      description: '车组',
      width: 120,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx060001',
      dataIndex: 'simNo',
      description: 'SIM卡号',
      width: 120,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx010003',
      dataIndex: 'plateColorName',
      description: '颜色',
      width: 90,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx000102',
      dataIndex: 'localTime',
      description: '时间',
      width: 160,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    // {
    //   intlTitle: 'tx000110',
    //   dataIndex: 'location',
    //   description: '位置',
    //   width: 200,
    //   render: (value, record: any) => {
    //     return <span style={ { color: record.valid ? color2 : color1 } }>{ value }</span>
    //   }
    // },
    // {
    //   dataIndex: '_',
    //   intlTitle: 'tx000110',
    //   description: '位置',
    //   width: 180,
    //   render: (_value, record: any) => {
    //     return <Lnglats style={{ color: record.valid ? color2 : color1 }} lnglat={[Number(record?.longitude), Number(record?.latitude)]} />
    //   }
    // },
    {
      dataIndex: 'lnglatLocation',
      intlTitle: 'tx000110',
      description: '位置',
      width: 180,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      },
      ellipsis: true
    },
    {
      intlTitle: 'tx060016',
      dataIndex: 'validName',
      description: '有效性',
      width: 120,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx010101',
      dataIndex: 'statusName',
      description: '状态',
      width: 100,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx010004',
      dataIndex: 'velocity',
      description: '速度',
      width: 90,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx060003',
      dataIndex: 'recordVelocity',
      description: '脉冲速度',
      width: 90,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx010006',
      dataIndex: 'directionName',
      description: '方向',
      width: 90,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx060005',
      dataIndex: 'mileage',
      description: '里程',
      width: 120,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx010005',
      dataIndex: 'gas',
      description: '油量',
      width: 90,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },

    {
      intlTitle: 'tx060009',
      dataIndex: 'altitude',
      description: '海拔',
      width: 90,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx010009',
      dataIndex: 'longitude',
      description: '原始经度',
      width: 120,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    },
    {
      intlTitle: 'tx010010',
      dataIndex: 'latitude',
      description: '原始纬度',
      width: 120,
      render: (value, record: any) => {
        return <span style={{ color: record.valid ? color2 : color1 }}>{value}</span>
      }
    }
  ])

  useEffect(() => {
    let isCancelled = false;
    tableStore.queryTable(tableName)
    // trajectoryStory.updatePositionTableData(trajectoryStory.positionTableParam)
    trajectoryStory.updatePositionTablePage(1)
    return () => {
      isCancelled = true;
    }
  }, [positionStory.mapType])

  return useObserver(() => <Table
    tableName={tableName}
    buttonGroup={false}
    initLoading={false}
    columns={columns}
    loading={trajectoryStory.loading}
    size="small"
    dataSource={trajectoryStory.positionTableData}
    rowSelectedValue={trajectoryStory.idx}
    rowSelectedKey='idx'
    pagination={{
      total: trajectoryStory.positionTableTotal,
      current: trajectoryStory.positionTableParam.current,
      pageSize: trajectoryStory.positionTableParam.size
    }}
    onChange={pagination => {
      if (pagination && pagination.current) {
        trajectoryStory.updatePositionTablePage(pagination.current, pagination.pageSize)
      }
    }}
    onRow={(record: any) => {
      return {
        onClick: () => {
          // trajectoryStory.updateInfoWindowType(1)
          trajectoryStory.updateInfoWindow(record)
          trajectoryStory.updateInfoWindowVisible(true)
          trajectoryStory.updateJumpIdx(record.idx)
        }
      }
    }}
  />)
}

export default observer(PositionDataTable)
