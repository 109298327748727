/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-10-14 14:07:59
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 17:53:56
 */
import { observable, action } from 'mobx'
import { webAPIRequest, webAPIResponse } from 'server/web/index.globals'
import { getTrackList } from 'server/web/trackList'
import { monitorStore } from 'store'
import { PathToolType } from 'components/ol/pathSimplifier'

interface PositionTableData extends webAPIResponse.TrackList {
  idx: number
}

class Trajectory {
  /** 轨迹回放-位置数据-查询条件 */
  @observable positionTableParam: webAPIRequest.TrackList = {
    isAbnormal: 0,
    minSpeed: '0',
    plateNo: '',
    current: 1,
    size: 50,
    begTime: '',
    endTime: '',
  }
  /** 轨迹回放-位置数据 */
  @observable positionTableData: PositionTableData[] = []
  /** 总页数 */
  @observable positionTableTotal: number = 0
  /** 轨迹回放-轨迹操作code */
  @observable toolCode: string = PathToolType.none
  /** 轨迹回放-轨迹idx */
  @observable idx: number = 0
  /** 轨迹回放-跳转轨迹idx */
  @observable jumpIdx: number = 0
  @observable loading: boolean = false
  /** 车辆详情 */
  @observable vehicleInfo: Partial<webAPIResponse.vehiclesInfo> = {}
  /** 车辆详情 - 用于infoWindow */
  @observable infoWindow: webAPIResponse.vehiclesInfo | null = null
  /** infoWindow Type
   * @param 1 位置infowindow
   * @param 2 报警infowindow
   */
  @observable infoWindowType: number = 1
  /** infoWindowVisible */
  @observable infoWindowVisible: boolean = false
  /** 报警坐标 */
  @observable policeMarkers: Array<webAPIResponse.monitorTrajectoryAlarmPage> = []
  /** 报警列表参数 */
  @observable policeParams: {} = {}
  /** 绘制轨迹回放loading */
  @observable drawLoading: boolean = false

  @action
  initParam(param: Omit<webAPIRequest.TrackList, 'size' | 'current'>) {
    this.idx = 0
    this.toolCode = PathToolType.none
    this.jumpIdx = 0
    this.updatePositionTableParam({
      ...param,
      current: 1,
      size: 50,
    })
  }

  /** 更新位置请求参数 */
  @action
  updatePositionTableParam(param: webAPIRequest.TrackList) {
    this.updatePositionTableData(param)
  }

  /** 更新分页 */
  @action
  updatePositionTablePage(current: number, size: number = 10) {
    this.updatePositionTableParam({
      ...this.positionTableParam,
      current,
      size,
    })
  }

  /** 查询位置数据 */
  @action
  async updatePositionTableData(param: webAPIRequest.TrackList) {
    this.loading = true

    if (this.toolCode === PathToolType.pause) {
      this.infoWindowVisible = true
    }
    const result = await getTrackList(param)
    this.loading = false
    this.positionTableParam = param
    if (this.toolCode === PathToolType.none) {
      this.updateToolCode(PathToolType.recovery)
    }
    if (result) {
      this.positionTableTotal = +result.data.total
      this.positionTableData = result.data.records.map((item, index: number) => {
        return {
          ...item,
          idx: (param.current - 1) * this.positionTableParam.size + index,
        }
      })
    }
  }

  /** 更新操作
   * @enum 0 无状态
   * @enum 1 播放
   * @enum 2 暂停
   * @enum 3 停止
   * @enum 4 恢复
   * @enum 5 播放中翻页
   */
  @action
  updateToolCode(code: string) {
    this.toolCode = code
    if (code === PathToolType.stop) {
      this.infoWindowVisible = false
      this.updateJumpIdx(0)
    }
  }

  /** 更新idx */
  @action
  updateIdx(idx: number) {
    this.idx = idx
    // 如果idx等于当前页最后条，进行翻页
    if (idx >= this.positionTableParam.current * this.positionTableParam.size) {
      if (this.toolCode === PathToolType.start) {
        this.updateToolCode(PathToolType.none)
      }

      // 计算idx跳转对应分页
      const yu = idx % this.positionTableParam.size
      const current =
        yu === 0
          ? idx / this.positionTableParam.size + 1
          : Math.ceil(idx / this.positionTableParam.size)
      if (current === this.positionTableParam.current) return
      this.positionTableParam.current = current

      this.updatePositionTableParam({
        ...this.positionTableParam,
        current: current,
      })
    }
  }

  /** 更新 */
  @action
  updateJumpIdx(idx: number) {
    this.jumpIdx = idx
  }

  /** 更新infoWindow显示 */
  updateInfoWindowVisible(visible: boolean) {
    console.log(visible)
    this.infoWindowVisible = visible
  }

  /** infoWindow */
  updateInfoWindow(data: any) {
    this.infoWindow = data
  }

  /** 清除轨迹 */
  @action
  cleanPath() {
    this.positionTableTotal = 0
    this.positionTableData = []
  }

  /** 更新请求参数 */
  @action
  updatePoliceParmas(data: {}) {
    this.policeParams = data
  }

  /** 更新报警点位 */
  @action
  updateMarker(data: Array<webAPIResponse.monitorTrajectoryAlarmPage>) {
    this.policeMarkers = data
  }

  /** 更新infoWindowType */
  updateInfoWindowType(type: number) {
    this.infoWindowType = type
  }

  /** 更新车辆详情 */
  @action
  updateVehicleInfo(data: webAPIResponse.vehiclesInfo) {
    this.vehicleInfo = data
  }
  /** 根据报警经纬度获取idx */
  @action
  queryIdx(position: number[]) {
    const index = monitorStore.trajectPlayPositionData.findIndex(item => {
      return item[0] === position[0] && item[1] === position[1];
    })

    if (index >= 0) {
      if (this.toolCode === PathToolType.start) {
        this.updateToolCode(PathToolType.pause)
      }
      this.updateJumpIdx(index)
      // 计算idx跳转对应分页
      const yu = index % this.positionTableParam.size
      const current =
        yu === 0
          ? index / this.positionTableParam.size + 1
          : Math.ceil(index / this.positionTableParam.size)
      if (current === this.positionTableParam.current) return
      this.positionTableParam.current = current

      this.updatePositionTableParam({
        ...this.positionTableParam,
        current: current,
      })
    }
  }

  /** 更新drawLoading */
  updateDrawLoading(data: boolean) {
    this.drawLoading = data
  }
}

const trajectoryStory: Trajectory = new Trajectory()

export { Trajectory }
export default trajectoryStory
