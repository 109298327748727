/*
 * @Description: 车辆相关
 * @Author: shenkaiyao
 * @Date: 2020-08-25 16:42:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-25 10:11:46
 */
import api from "./index";
import axios from "../axios";
import { TableResponseType } from "components/table/interface";
import { webAPIRequest, webAPIResponse, VehicleId } from "./index.globals";

const MODULE = "/vehicle";

export interface VehicleTopsDto {
  /** 报警次数 */
  alarmNum: number;
  /** 车牌号 */
  plateNo: string;
}

export interface VehicleTeamTopsDto {
  /** 报警次数 */
  alarmNum: number;
  /** 车队名称 */
  depName: string;
}

/** 终端数据 */
export interface TerminalDetailVO {
  /** 设备状态 */
  state?: string;
  /** 设备状态名称 */
  stateName?: string;
  /** 终端id */
  termId?: string;
  /** 终端号 */
  termNo?: string;
  /** 终端类型 */
  termType?: string;
  /** 终端类型名称 */
  termTypeName?: string;
}

/** 保险信息 */
export interface VehicleinsuranceDetailVO {
  /** 实际费用 */
  actualCost?: number;
  /** 代理电话 */
  agentPhone?: string;
  /** 保险金额 */
  amount?: number;
  /** 公司id */
  companyId?: string;
  /** 保险折扣率 */
  discountRate?: number;
  /** 结束时间 */
  endDate?: string;
  /** 代理人 */
  insuranceAgent?: string;
  /** 保险单号 */
  insuranceBillNo?: string;
  /** 保险公司 */
  insuranceCompany?: string;
  /** 保险id */
  insuranceId?: string;
  /** 保险类型 */
  insuranceType?: string;
  /** 备注 */
  remark?: string;
  /** 开始时间 */
  startDate?: string;
  /** 车辆id */
  vehicleId?: string;
}

/** 保养信息 */
export interface VehiclemaintrecordDetailVO {
  /** 公司id */
  companyId?: string;
  /** 维修内容 */
  maintContent?: string;
  /** 维修费用 */
  maintCost?: string;
  /** 维修时间 */
  maintDate?: string;
  /** 保养信息id */
  maintRecordId?: string;
  /** 下次保养日期 */
  nextUpKeepDate?: string;
  /** 备注 */
  remark?: string;
  /** 保养日期 */
  upKeepDate?: string;
  /** 车辆id */
  vehicleId?: string;
}

/** 注册信息表 */
export interface VehicleregisterinfoDetailVO {
  /** 平台唯一编码 */
  plateformId?: string;
  /** 车载终端编号 */
  terminalId?: string;
  /** 车载终端型号 */
  terminalModel?: string;
  /** 车载终端厂商唯一编码 */
  terminalVendorId?: string;
}

/** 行驶证信息 */
export interface VehicleruningLicenseDetailVO {
  /** 品牌型号 */
  brandModel?: string;
  /** 公司id */
  companyId?: string;
  /** 发动机号 */
  engineNo?: string;
  /** 行驶证照 */
  imageUrl: string;
  /** 发证日期 */
  openingDate?: string;
  /** 注册日期 */
  registerDate?: string;
  /** 备注 */
  remark?: string;
  /** 行驶证id */
  runingLicenseId?: string;
  /** 有效期 */
  validDate?: string;
  /** 使用性质 */
  vehicleFunction?: string;
  /** 车辆id */
  vehicleId?: string;
  /** 车架号 */
  vehicleIdentifyNo?: string;
}

/** 运输证信息 */
export interface VehicletranslicenseDetailVO {
  /** 地址 */
  address?: string;
  /** 经营许可证号 */
  businessLicenseNo?: string;
  /** 经营范围 */
  businessScore?: string;
  /** 经营范围描述 */
  businessScoreDesc?: string;
  /** 公司id */
  companyId?: string;
  /** 运输证照 */
  imageUrl?: string;
  /** 发证日期 */
  openingDate?: string;
  /** 业户名称 */
  proprietorName?: string;
  /** 备注 */
  remark?: string;
  /** 运输证id */
  transLicenseId?: string;
  /** 运输证号 */
  transLicenseNo?: string;
  /** 发证机构 */
  transLicenseOffice?: string;
  /** 有效期 */
  validDate?: string;
  /** 车辆id */
  vehicleId?: string;
}

export interface BindDriver {
  vehicleBindDriverDTOS: {
    driverId: string;
    mainDriver: boolean;
  }[];
  vehicleId: string;
}

export interface BindTerminal {
  simNo: string;
  termId: string;
  vehicleId: string;
}

export interface VehicleInfoByPlateNo {
  plateNo: string;
}

/** 获取车辆分页筛选数据 */
export const getVehiclePage = (payload: webAPIRequest.VehiclePage) =>
  axios.post<TableResponseType<webAPIResponse.VehiclePage>>(
    `${api}${MODULE}/assembly`,
    payload
  );

/** 管理中心 信息管理 车辆信息 分页查询 */
export const queryVehiclePage = (payload: webAPIRequest.QueryVehiclePage) =>
  axios.post<TableResponseType<webAPIResponse.QueryVehiclePage>>(
    `${api}${MODULE}/list`,
    payload
  );

/** 查询车辆详情 */
export const queryVehicleInfo = (payload: VehicleId) =>
  axios.post<webAPIResponse.QueryVehicleInfo>(
    `${api}${MODULE}/detail`,
    payload
  );

/** 根据车牌号查询车辆详情 */
export const queryVehicleInfoByPlateNo = (payload: VehicleInfoByPlateNo) =>
  axios.post<webAPIResponse.QueryVehicleInfo>(
    `${api}${MODULE}/findVehicleByPlateNo  `,
    payload
  );

/** 添加车辆，参数和查询车辆详情接口的返回值类型差得不多，具体看接口文档 */
export const addVehicle = (payload: webAPIResponse.QueryVehicleInfo) =>
  axios.post<boolean>(`${api}${MODULE}/save`, payload);

/** 快速加车 */
export const fastAddVehicle = (payload: webAPIResponse.QueryVehicleInfo) =>
  axios.post<boolean>(`${api}${MODULE}/fast/save`, payload);

/** 修改车辆 */
export const updateVehicle = (payload: webAPIResponse.QueryVehicleInfo) =>
  axios.post<boolean>(`${api}${MODULE}/update`, payload);

/** 批量修改车辆 */
export const updateVehicleBatch = (
  payload: webAPIResponse.UpdateVehicleBatch
) => axios.post<boolean>(`${api}${MODULE}/batch/update`, payload);

/** 删除车辆 */
export const deleteVehicle = (payload: VehicleId) =>
  axios.post<boolean>(`${api}${MODULE}/del`, payload);

/** 车辆的实时数据 */
export const getVehicleRealTimeInfo = (payload: VehicleId) =>
  axios.post<webAPIResponse.VehicleRealTimeInfo>(
    `${api}${MODULE}/real/time/info`,
    payload
  );

/** 车辆绑定司机 */
export const bindDriver = (payload: BindDriver) =>
  axios.post<boolean>(`${api}${MODULE}/bindDriver`, payload);

/** 车辆解绑司机 */
export const unBindDriver = (payload: VehicleId) =>
  axios.post<boolean>(`${api}${MODULE}/unbindlingDriver`, payload);

/** 车辆绑定终端 */
export const bindTerminal = (payload: BindTerminal) =>
  axios.post<boolean>(`${api}${MODULE}/bindTerminal`, payload);

/** 车辆信息-导出 */
export const exportVehicle = (payload: any) =>
  axios.post<boolean>(`${api}${MODULE}/export`, payload);

/** 车辆信息-导入 */
export const importVehicleUrl = `${api}${MODULE}/import/fast`;
export const importVehicle = (payload: { uploadFile: string }) =>
  axios.post<boolean>(importVehicleUrl, payload);

/** 车辆在线状态查询*/
export const getVehicleOnline = (payload: any) =>
  axios.post(`${api}${MODULE}/online/page`, payload);

/** 车辆上下线记录查询*/
export const getVehicleOnlineRecord = (payload: any) =>
  axios.post(`${api}${MODULE}/online/record/page`, payload);
